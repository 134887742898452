/**
 * @module SalesFlow/model/type
 */
/* tslint:disable:cyclomatic-complexity */

declare var moment: any;

export default class DeviceAttributes {

    [key: string]: any;

    private _topFeatures: string[];

    private _subLevel: number;

    private _deliveryTime: string;

    private _deliveryDate: string;

    private _packageParts: string[];

    private _vendor: string;

    private _primaryColor: string;

    private _dataVolumeIncluded: number;

    private _standbyTime: number;

    private _talkingTime: number;

    private _usageTime: number;

    private _musicPlayback: number;

    private _videoPlayback: number;

    private _processor: string;

    private _os: string;

    private _downloadSpeed: number;

    private _uploadSpeed: number;

    private _fingerprintSensor: boolean;

    private _waterRepellent: boolean;

    private _sarValue: number;

    private _measurements: string;

    private _weight: number;

    private _color: string;

    private _displayInch: number;

    private _displayCm: number;

    private _resolution: string;

    private _colorDepth: number;

    private _megapixel: number;

    private _photoResolution: string;

    private _frontcamera: boolean;

    private _faceTime: boolean;

    private _megapixelFrontcamera: number;

    private _resolutionFrontcamera: string;

    private _multipleLenses: string;

    private _videoResolution: string;

    private _videoResolutionFrontcamera: string;

    private _hdMode: string;

    private _integratedDigitalCamera: boolean;

    private _ram: number;

    private _internalMemory: number;

    private _internalMemoryOwnFiles: number;

    private _internalMemoryWithUnit: string;

    private _internalMemoryOwnFilesWithUnit: string;

    private _memoryExpandable: boolean;

    private _memoryCardsUpTo: number;

    private _lte: boolean;

    private _fiveG: boolean;

    private _voLte: boolean;

    private _wifiCalling: boolean;

    private _umtsBroadband: boolean;

    private _umts: boolean;

    private _edge: boolean;

    private _edgeGprs: boolean;

    private _gprs: boolean;

    private _quadband: boolean;

    private _triband: boolean;

    private _dualband: boolean;

    private _wlan: boolean;

    private _bluetooth: boolean;

    private _bluetoothVersion: number;

    private _infrared: boolean;

    private _dlna: string;

    private _gps: boolean;

    private _nfc: boolean;

    private _nfcWallet: boolean;

    private _hdmi: string;

    private _microHdmi: string;

    private _usb: boolean;

    private _microUsb: boolean;

    private _usbTypC: boolean;

    private _headphones: boolean;

    private _usb2: boolean;

    private _measurementsFold: string;

    private _micIn: boolean;

    private _ethernet: boolean;

    private _cardreader: boolean;

    private _externalAntenna: boolean;

    private _simType: string[] = [];

    private _dect: boolean;

    private _upnp: boolean;

    private _netLock: boolean;

    private _activationRequired: boolean;

    private _windows10: boolean;

    private _windows8: boolean;

    private _windows7: boolean;

    private _macOs: boolean;

    private _mp3Player: boolean;

    private _radio: boolean;

    private _musicDownload: boolean;

    private _mobileTv: boolean;

    private _stereoSpeaker: boolean;

    private _mms: boolean;

    private _touchscreen: boolean;

    private _touchscreenQwertz: boolean;

    private _keyboard: boolean;

    private _qwertzKeyboard: boolean;

    private _voiceCommands: boolean;

    private _voiceDail: boolean;

    private _handsfree: boolean;

    private _seniorFocused: boolean;

    private _calendar: boolean;

    private _dictation: boolean;

    private _mailClient: boolean;

    private _mailConnect: boolean;

    private _nsfImage288_320: string[];

    private _nsfImage288_200: string;

    private _nsfImage170_230: string;

    private _nsfImage90_120: string;

    private _image170_230: string;

    private _image228_309: string;

    private _image170_119: string;

    private _image228_160: string;

    private _productType: string;

    private _simLock: boolean;

    private _technicalSegment: boolean;

    private _descriptionAccessory: string;

    private _descriptionDevicecardFront: string;

    private _descriptionDevicecardBack: string;

    private _productInfoSheet: string;

    private _olsLaunchDate: number;

    private _productCategory: string;

    private _orderType: string;

    private _contractType: string;

    private _contractProlongationRestrictions: string;

    private _bundledWith: string;

    private _dicount5Euro: boolean;

    private _ean: string;

    private _artikelnummer: string;

    private _mpn: string;

    private _btx: string;

    private _salesChannel: string;

    private _kdgJsonTv: boolean;

    private _productCode: string;

    private _qiStandard: boolean;

    private _housingSize: number;

    private _housingColor: string;

    private _bracelet: string;

    private _braceletColor: string;

    // @TODO use moment instead of any
    private _launchDate: any;

    private rawTopFeatures: any = [];

    private rawPackageParts: any = [];

    private rawLargeImages: any = [];

    private _simSlotCount: string;

    private _resolutionUnfolded: string;

    private _displayUnfolded: number;

    private displayUnfoldedInch: number;

    private _dataUsage: number;

    private _faceId: boolean;

    private _series: string;

    private _ecoRating: string;

    /**
     * @TODO create an attributes interface including an index signature
     */
    constructor (attributes: any, parentAttributes?: DeviceAttributes) {

        let pseudoAtomicDevice: Boolean = false;

        if (undefined === attributes[0]) {
            pseudoAtomicDevice = true;
        }

        if (undefined !== parentAttributes) {

            this.rawTopFeatures = parentAttributes.getRawTopFeatures().map((rawAttribute: any) => {
                return rawAttribute;
            });

            this.rawPackageParts = parentAttributes.getRawPackageParts().map((rawAttribute: any) => {
                return rawAttribute;
            });

            /*
            this.rawLargeImages = parentAttributes.getRawLargeImages().map((rawAttribute: any) => {
                return rawAttribute;
            });
            */

            for (const key in parentAttributes) {

                if (false === parentAttributes.hasOwnProperty(key)) {
                    continue;
                }

                /** in case of Pseudo Atomic Device, all atttributes come from parent */
                if (false === pseudoAtomicDevice) {

                    if ('_topFeatures' === key) {
                        continue;
                    }
                    if ('_packageParts' === key) {
                        continue;
                    }
                    if ('_nsfImage288_320' === key) {
                        continue;
                    }
                    if ('rawTopFeatures' === key) {
                        continue;
                    }
                    if ('rawPackageParts' === key) {
                        continue;
                    }
                    if ('rawData' === key) {
                        continue;
                    }

                }

                const publicKey = key.replace('_', '');

                this[key] = parentAttributes[publicKey];

            }

            if (false === pseudoAtomicDevice) {
                this.rawLargeImages = [];
                this._topFeatures = [];
                this._packageParts = [];
                this._nsfImage288_320 = [];
            }

        }

        for (const attribute of attributes) {

            const attributeId = attribute.id;

            if ('143' === attributeId) {
                this.rawTopFeatures.push(attribute);
            }

            if ('9' === attributeId) {
                this._subLevel = parseFloat(attribute.value.replace(',', '.'));
            }

            if ('127' === attributeId) {
                this._deliveryTime = attribute.value;
            }

            if ('168' === attributeId) {
                this._deliveryDate = attribute.value;
            }

            if ('126' === attributeId) {
                this.rawPackageParts.push(attribute);
            }

            if ('118' === attributeId) {
                this._vendor = attribute.value;
            }

            if ('109' === attributeId) {
                this._primaryColor = attribute.value;
            }
            if ('167' === attributeId) {
                this._dataVolumeIncluded = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('16' === attributeId) {
                this._standbyTime = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('17' === attributeId) {
                this._talkingTime = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('18' === attributeId) {
                this._usageTime = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('19' === attributeId) {
                this._musicPlayback = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('226' === attributeId) {
                this._videoPlayback = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('28' === attributeId) {
                this._processor = attribute.value;
            }
            if ('12' === attributeId) {
                this._os = attribute.value;
            }
            if ('22' === attributeId) {
                this._downloadSpeed = attribute.value;
            }
            if ('23' === attributeId) {
                this._uploadSpeed = attribute.value;
            }
            if ('135' === attributeId) {
                this._fingerprintSensor = ('1' === attribute.value) ? true : false;
            }
            if ('157' === attributeId) {
                this._waterRepellent = ('1' === attribute.value) ? true : false;
            }
            if ('33' === attributeId) {
                this._sarValue = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('20' === attributeId) {
                this._measurements = attribute.value;
            }
            if ('15' === attributeId) {
                this._weight = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('13' === attributeId) {
                this._color = attribute.value;
            }
            if ('57' === attributeId) {
                this._displayInch = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('58' === attributeId) {
                this._displayCm = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('59' === attributeId) {
                this._resolution = attribute.value;
            }
            if ('60' === attributeId) {
                this._colorDepth = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('47' === attributeId) {
                this._megapixel = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('48' === attributeId) {
                this._photoResolution = attribute.value;
            }
            if ('49' === attributeId) {
                this._frontcamera = ('1' === attribute.value) ? true : false;
            }
            if ('50' === attributeId) {
                this._faceTime = ('1' === attribute.value) ? true : false;
            }
            if ('51' === attributeId) {
                this._megapixelFrontcamera = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('52' === attributeId) {
                this._resolutionFrontcamera = attribute.value;
            }
            if ('222' === attributeId) {
                this._multipleLenses = attribute.value;
            }
            if ('53' === attributeId) {
                this._videoResolution = attribute.value;
            }
            if ('54' === attributeId) {
                this._videoResolutionFrontcamera = attribute.value;
            }
            if ('55' === attributeId) {
                this._hdMode = attribute.value;
            }
            if ('46' === attributeId) {
                this._integratedDigitalCamera = ('1' === attribute.value) ? true : false;
            }
            if ('27' === attributeId) {
                this._ram = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('14' === attributeId) {
                this._internalMemory = parseFloat(attribute.value.replace(',', '.'));
                this._internalMemoryWithUnit = attribute.value.replace('.', ',');
            }
            if ('24' === attributeId) {
                this._internalMemoryOwnFiles = parseFloat(attribute.value.replace(',', '.'));
                this._internalMemoryOwnFilesWithUnit = attribute.value.replace('.', ',');
            }
            if ('25' === attributeId) {
                this._memoryExpandable = ('1' === attribute.value) ? true : false;
            }
            if ('26' === attributeId) {
                this._memoryCardsUpTo = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('71' === attributeId) {
                this._lte = ('1' === attribute.value) ? true : false;
            }
            if ('146' === attributeId) {
                this._fiveG = ('1' === attribute.value) ? true : false;
            }
            if ('72' === attributeId) {
                this._voLte = ('1' === attribute.value) ? true : false;
            }
            if ('134' === attributeId) {
                this._wifiCalling = ('1' === attribute.value) ? true : false;
            }
            if ('73' === attributeId) {
                this._umtsBroadband = ('1' === attribute.value) ? true : false;
            }
            if ('74' === attributeId) {
                this._umts = ('1' === attribute.value) ? true : false;
            }
            if ('75' === attributeId) {
                this._edge = ('1' === attribute.value) ? true : false;
            }
            if ('76' === attributeId) {
                this._edgeGprs = ('1' === attribute.value) ? true : false;
            }
            if ('77' === attributeId) {
                this._gprs = ('1' === attribute.value) ? true : false;
            }
            if ('78' === attributeId) {
                this._quadband = ('1' === attribute.value) ? true : false;
            }
            if ('79' === attributeId) {
                this._triband = ('1' === attribute.value) ? true : false;
            }
            if ('80' === attributeId) {
                this._dualband = ('1' === attribute.value) ? true : false;
            }
            if ('81' === attributeId) {
                this._wlan = ('1' === attribute.value) ? true : false;
            }
            if ('82' === attributeId) {
                this._bluetooth = ('1' === attribute.value) ? true : false;
            }
            if ('83' === attributeId) {
                this._bluetoothVersion = attribute.value.replace(',', '.');
            }
            if ('84' === attributeId) {
                this._infrared = ('1' === attribute.value) ? true : false;
            }
            if ('85' === attributeId) {
                this._dlna = attribute.value;
            }
            if ('86' === attributeId) {
                this._gps = ('1' === attribute.value) ? true : false;
            }
            if ('87' === attributeId) {
                this._nfc = ('1' === attribute.value) ? true : false;
            }
            if ('88' === attributeId) {
                this._nfcWallet = ('1' === attribute.value) ? true : false;
            }
            if ('89' === attributeId) {
                this._hdmi = attribute.value;
            }
            if ('144' === attributeId) {
                this._microHdmi = attribute.value;
            }
            if ('90' === attributeId) {
                this._usb = ('1' === attribute.value) ? true : false;
            }
            if ('165' === attributeId) {
                this._microUsb = ('1' === attribute.value) ? true : false;
            }
            if ('166' === attributeId) {
                this._usbTypC = ('1' === attribute.value) ? true : false;
            }
            if ('92' === attributeId) {
                this._headphones = ('1' === attribute.value) ? true : false;
            }
            if ('93' === attributeId) {
                this._usb2 = ('1' === attribute.value) ? true : false;
            }
            if ('94' === attributeId) {
                this._measurementsFold = attribute.value;
            }
            if ('95' === attributeId) {
                this._micIn = ('1' === attribute.value) ? true : false;
            }
            if ('96' === attributeId) {
                this._ethernet = ('1' === attribute.value) ? true : false;
            }
            if ('97' === attributeId) {
                this._cardreader = ('1' === attribute.value) ? true : false;
            }
            if ('98' === attributeId) {
                this._externalAntenna = ('1' === attribute.value) ? true : false;
            }
            if ('99' === attributeId) {

                if (0 !== this._simType.length) {
                    attribute.value = ' ' + attribute.value;
                }

                this._simType.push(attribute.value + '');
            }

            if ('128' === attributeId) {
                this._dect = ('1' === attribute.value) ? true : false;
            }
            if ('129' === attributeId) {
                this._upnp = ('1' === attribute.value) ? true : false;
            }
            if ('100' === attributeId) {
                this._netLock = ('1' === attribute.value) ? true : false;
            }
            if ('101' === attributeId) {
                this._activationRequired = ('1' === attribute.value) ? true : false;
            }
            if ('147' === attributeId) {
                this._windows10 = ('1' === attribute.value) ? true : false;
            }
            if ('148' === attributeId) {
                this._windows8 = ('1' === attribute.value) ? true : false;
            }
            if ('149' === attributeId) {
                this._windows7 = ('1' === attribute.value) ? true : false;
            }
            if ('150' === attributeId) {
                this._macOs = ('1' === attribute.value) ? true : false;
            }
            if ('110' === attributeId) {
                this._mp3Player = ('1' === attribute.value) ? true : false;
            }
            if ('111' === attributeId) {
                this._radio = ('1' === attribute.value) ? true : false;
            }
            if ('112' === attributeId) {
                this._musicDownload = ('1' === attribute.value) ? true : false;
            }
            if ('113' === attributeId) {
                this._mobileTv = ('1' === attribute.value) ? true : false;
            }
            if ('115' === attributeId) {
                this._stereoSpeaker = ('1' === attribute.value) ? true : false;
            }
            if ('116' === attributeId) {
                this._mms = ('1' === attribute.value) ? true : false;
            }
            if ('36' === attributeId) {
                this._touchscreen = ('1' === attribute.value) ? true : false;
            }
            if ('37' === attributeId) {
                this._touchscreenQwertz = ('1' === attribute.value) ? true : false;
            }
            if ('38' === attributeId) {
                this._keyboard = ('1' === attribute.value) ? true : false;
            }
            if ('39' === attributeId) {
                this._qwertzKeyboard = ('1' === attribute.value) ? true : false;
            }
            if ('40' === attributeId) {
                this._voiceCommands = ('1' === attribute.value) ? true : false;
            }
            if ('41' === attributeId) {
                this._voiceDail = ('1' === attribute.value) ? true : false;
            }
            if ('42' === attributeId) {
                this._handsfree = ('1' === attribute.value) ? true : false;
            }
            if ('158' === attributeId) {
                this._seniorFocused = ('1' === attribute.value) ? true : false;
            }
            if ('103' === attributeId) {
                this._calendar = ('1' === attribute.value) ? true : false;
            }
            if ('104' === attributeId) {
                this._dictation = ('1' === attribute.value) ? true : false;
            }
            if ('105' === attributeId) {
                this._mailClient = ('1' === attribute.value) ? true : false;
            }
            if ('106' === attributeId) {
                this._mailConnect = attribute.value;
            }
            if ('159' === attributeId) {
                this.rawLargeImages.push(attribute);
            }
            if ('160' === attributeId) {
                this._nsfImage288_200 = attribute.value;
            }
            if ('162' === attributeId) {
                this._nsfImage170_230 = attribute.value;
            }
            if ('161' === attributeId) {
                this._nsfImage90_120 = attribute.value;
            }

            if ('122' === attributeId) {
                this._image170_230 = attribute.value;
            }
            if ('123' === attributeId) {
                this._image228_309 = attribute.value;
            }
            if ('125' === attributeId) {
                this._image170_119 = attribute.value;
            }
            if ('130' === attributeId) {
                this._image228_160 = attribute.value;
            }
            if ('43' === attributeId) {
                this._productType = attribute.value;
            }

            if ('45' === attributeId) {
                this._simLock = ('1' === attribute.value) ? true : false;
            }
            if ('35' === attributeId) {
                this._technicalSegment = ('1' === attribute.value) ? true : false;
            }
            if ('137' === attributeId) {
                this._descriptionAccessory = attribute.value;
            }
            if ('131' === attributeId) {
                this._descriptionDevicecardFront = attribute.value;
            }
            if ('132' === attributeId) {
                this._descriptionDevicecardBack = attribute.value;
            }
            if ('132' === attributeId) {
                this._productInfoSheet = attribute.value;
            }
            if ('164' === attributeId) {
                this._olsLaunchDate = attribute.value;
            }
            if ('170' === attributeId) {
                this._productCategory = attribute.value;
            }
            if ('138' === attributeId) {
                this._orderType = attribute.value;
            }
            if ('139' === attributeId) {
                this._contractType = attribute.value;
            }
            if ('140' === attributeId) {
                this._contractProlongationRestrictions = attribute.value;
            }
            if ('142' === attributeId) {
                this._bundledWith = attribute.value;
            }
            if ('141' === attributeId) {
                this._dicount5Euro = ('1' === attribute.value) ? true : false;
            }
            if ('155' === attributeId) {
                this._ean = attribute.value;
            }
            if ('163' === attributeId) {
                this._artikelnummer = attribute.value;
            }
            if ('156' === attributeId) {
                this._mpn = attribute.value;
            }
            if ('119' === attributeId) {
                this._btx = attribute.value;
            }
            if ('136' === attributeId) {
                this._salesChannel = attribute.value;
            }
            if ('124' === attributeId) {
                this._kdgJsonTv = ('1' === attribute.value) ? true : false;
            }
            if ('133' === attributeId) {
                this._productCode = attribute.value;
            }
            if ('164' === attributeId) {
                this._launchDate = moment(attribute.value).format();
            }
            if ('195' === attributeId) {
                this._qiStandard = ('1' === attribute.value) ? true : false;
            }
            if ('201' === attributeId) {
                this._housingSize = parseFloat(attribute.value.replace(',', '.'));
            }
            if ('203' === attributeId) {
                this._bracelet = attribute.value;
            }
            if ('204' === attributeId) {
                this._braceletColor = attribute.value;
            }
            if ('205' === attributeId) {
                this._housingColor = attribute.value;
            }

            if ('217' === attributeId) {
                this._simSlotCount = '' + attribute.value;
            }

            if ('224' === attributeId) {
                this._resolutionUnfolded = attribute.value;
            }

            if ('101' === attributeId) {
                this._displayUnfolded = parseFloat(attribute.value.replace(',', '.'));
            }

            if ('225' === attributeId) {
                this._displayUnfoldedInch = parseFloat(attribute.value.replace(',', '.'));
            }

            if ('207' === attributeId) {
                this._faceId = ('1' === attribute.value) ? true : false;
            }

            if ('171' === attributeId) {
                this._dataUsage = parseFloat(attribute.value.replace(',', '.'));
            }

            if ('219' === attributeId) {
                this._series = '3';
            }

            if ('220' === attributeId) {
                this._series = '4';
            }

            if ('218' === attributeId) {
                this._series = '5';
            }

            if ('257' === attributeId) {
                this._ecoRating = attribute.value;
            }

        }

        if (0 < this.rawTopFeatures.length) {

            this.rawTopFeatures.sort(function (a: any, b: any) {
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }
                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }

                // a muss gleich b sein
                return 0;
            });

            this._topFeatures = this.rawTopFeatures.map((part: any): string => {
                return part.value;
            });
        }

        if (0 < this.rawPackageParts.length) {
            this.rawPackageParts.sort(function (a: any, b: any) {

                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }

                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }

                // a muss gleich b sein
                return 0;
            });

            this._packageParts = this.rawPackageParts.map((part: any): string => {
                return part.value;
            });

        }

        if (0 < this.rawLargeImages.length) {

            this.rawLargeImages.sort(function (a: any, b: any) {

                const lokA = isNaN(parseInt(a.sortOrder, 10)) ? a.sortOrder : parseInt(a.sortOrder, 10) ;
                const lokB = isNaN(parseInt(b.sortOrder, 10)) ? b.sortOrder : parseInt(b.sortOrder, 10) ;

                if (lokA > lokB) {
                    return 1;
                }

                if (lokA < lokB) {
                    return -1;
                }

                // a muss gleich b sein
                return 0;
            });

            this._nsfImage288_320 = this.rawLargeImages.map((part: any): string => {
                return part.value;
            });
        }

        this.setFallbackPrimaryColor();

    }

    private setFallbackPrimaryColor (): void {
        if (undefined === this._primaryColor && undefined !== this._color) {

            if ('schwarz' === this._color.toLowerCase()) {
                this._primaryColor = '0,0,0';
            }
        }

    }

    public getRawTopFeatures (): any {
        return this.rawTopFeatures;
    }

    public getRawPackageParts (): any {
        return this.rawPackageParts;
    }

    public getRawLargeImages (): any {
        return this.rawLargeImages;
    }

    get topFeatures (): string[] {
        return this._topFeatures;
    }

    get subLevel (): number {
        return this._subLevel;
    }
    get deliveryTime (): string {
        return this._deliveryTime;
    }
    get deliveryDate (): string {
        return this._deliveryDate;
    }
    get packageParts (): string[] {
        return this._packageParts;
    }
    get vendor (): string {
        return this._vendor;
    }
    get primaryColor (): string {
        return this._primaryColor;
    }
    get dataVolumeIncluded (): number {
        return this._dataVolumeIncluded;
    }
    get talkingTime (): number {
        return this._talkingTime;
    }
    get usageTime (): number {
        return this._usageTime;
    }
    get musicPlayback (): number {
        return this._musicPlayback;
    }
    get videoPlayback (): number {
        return this._videoPlayback;
    }
    get processor (): string {
        return this._processor;
    }
    get os (): string {
        return this._os;
    }
    get downloadSpeed (): number {
        return this._downloadSpeed;
    }
    get uploadSpeed (): number {
        return this._uploadSpeed;
    }
    get fingerprintSensor (): boolean {
        return this._fingerprintSensor;
    }
    get waterRepellent (): boolean {
        return this._waterRepellent;
    }
    get sarValue (): number {
        return this._sarValue;
    }
    get measurements (): string {
        return this._measurements;
    }
    get weight (): number {
        return this._weight;
    }
    get color (): string {
        return this._color;
    }
    get displayInch (): number {
        return this._displayInch;
    }
    get displayCm (): number {
        return this._displayCm;
    }
    get resolution (): string {
        return this._resolution;
    }
    get colorDepth (): number {
        return this._colorDepth;
    }
    get megapixel (): number {
        return this._megapixel;
    }
    get photoResolution (): string {
        return this._photoResolution;
    }
    get frontcamera (): boolean {
        return this._frontcamera;
    }
    get faceTime (): boolean {
        return this._faceTime;
    }
    get megapixelFrontcamera (): number {
        return this._megapixelFrontcamera;
    }
    get resolutionFrontcamera (): string {
        return this._resolutionFrontcamera;
    }
    get multipleLenses (): string {
        return this._multipleLenses;
    }
    get videoResolution (): string {
        return this._videoResolution;
    }
    get videoResolutionFrontcamera (): string {
        return this._videoResolutionFrontcamera;
    }
    get hdMode (): string {
        return this._hdMode;
    }
    get integratedDigitalCamera (): boolean {
        return this._integratedDigitalCamera;
    }
    get ram (): number {
        return this._ram;
    }
    get internalMemory (): number {
        return this._internalMemory;
    }
    get internalMemoryOwnFiles (): number {
        return this._internalMemoryOwnFiles;
    }
    get internalMemoryWithUnit (): string {
        return this._internalMemoryWithUnit;
    }
    get internalMemoryOwnFilesWithUnit (): string {
        return this._internalMemoryOwnFilesWithUnit;
    }
    get memoryExpandable (): boolean {
        return this._memoryExpandable;
    }
    get memoryCardsUpTo (): number {
        return this._memoryCardsUpTo;
    }
    get lte (): boolean {
        return this._lte;
    }
    get fiveG (): boolean {
        return this._fiveG;
    }
    get voLte (): boolean {
        return this._voLte;
    }
    get wifiCalling (): boolean {
        return this._wifiCalling;
    }
    get umtsBroadband (): boolean {
        return this._umtsBroadband;
    }
    get umts (): boolean {
        return this._umts;
    }
    get edge (): boolean {
        return this._edge;
    }
    get edgeGprs (): boolean {
        return this._edgeGprs;
    }
    get gprs (): boolean {
        return this._gprs;
    }
    get quadband (): boolean {
        return this._quadband;
    }
    get triband (): boolean {
        return this._triband;
    }
    get dualband (): boolean {
        return this._dualband;
    }
    get wlan (): boolean {
        return this._wlan;
    }
    get bluetooth (): boolean {
        return this._bluetooth;
    }
    get bluetoothVersion (): number {
        return this._bluetoothVersion;
    }
    get infrared (): boolean {
        return this._infrared;
    }
    get DLNA (): string {
        return this._dlna;
    }
    get gps (): boolean {
        return this._gps;
    }
    get nfc (): boolean {
        return this._nfc;
    }
    get nfcWallet (): boolean {
        return this._nfcWallet;
    }
    get hdmi (): string {
        return this._hdmi;
    }
    get microHdmi (): string {
        return this._microHdmi;
    }
    get usb (): boolean {
        return this._usb;
    }
    get microUsb (): boolean {
        return this._microUsb;
    }
    get usbTypC (): boolean {
        return this._usbTypC;
    }
    get usb2 (): boolean {
        return this._usb2;
    }
    get measurementsFold (): string {
        return this._measurementsFold;
    }
    get headphones (): boolean {
        return this._headphones;
    }
    get micIn (): boolean {
        return this._micIn;
    }
    get ethernet (): boolean {
        return this._ethernet;
    }
    get cardreader (): boolean {
        return this._cardreader;
    }
    get externalAntenna (): boolean {
        return this._externalAntenna;
    }
    get simType (): string[] {
        return this._simType;
    }
    get dect (): boolean {
        return this._dect;
    }
    get upnp (): boolean {
        return this._upnp;
    }
    get netLock (): boolean {
        return this._netLock;
    }
    get activationRequired (): boolean {
        return this._activationRequired;
    }
    get windows10 (): boolean {
        return this._windows10;
    }
    get windows8 (): boolean {
        return this._windows8;
    }
    get windows7 (): boolean {
        return this._windows7;
    }
    get macOS (): boolean {
        return this._macOs;
    }
    get mp3Player (): boolean {
        return this._mp3Player;
    }
    get radio (): boolean {
        return this._radio;
    }
    get musicDownload (): boolean {
        return this._musicDownload;
    }
    get mobileTv (): boolean {
        return this._mobileTv;
    }
    get stereoSpeaker (): boolean {
        return this._stereoSpeaker;
    }
    get mms (): boolean {
        return this._mms;
    }
    get touchscreen (): boolean {
        return this._touchscreen;
    }
    get touchscreenQwertz (): boolean {
        return this._touchscreenQwertz;
    }
    get kayboard (): boolean {
        return this._keyboard;
    }
    get qwertzKeyboard (): boolean {
        return this._qwertzKeyboard;
    }
    get voiceCommands (): boolean {
        return this._voiceCommands;
    }
    get voiceDail (): boolean {
        return this._voiceDail;
    }
    get handsfree (): boolean {
        return this._handsfree;
    }
    get seniorFocused (): boolean {
        return this._seniorFocused;
    }
    get calendar (): boolean {
        return this._calendar;
    }
    get dictation (): boolean {
        return this._dictation;
    }
    get mailClient (): boolean {
        return this._mailClient;
    }
    get mailConnect (): boolean {
        return this._mailConnect;
    }
    get nsfImage288_320 (): string[] {
        return this._nsfImage288_320;
    }
    get nsfImage288_200 (): string {
        return this._nsfImage288_200;
    }
    get nsfImage170_230 (): string {
        return this._nsfImage170_230;
    }
    get nsfImage90_120 (): string {
        return this._nsfImage90_120;
    }
    get image170_230 (): string {
        return this._image170_230;
    }
    get image228_309 (): string {
        return this._image228_309;
    }
    get image170_119 (): string {
        return this._image170_119;
    }
    get image228_160 (): string {
        return this._image228_160;
    }
    get productType (): string {
        return this._productType;
    }
    get simLock (): boolean {
        return this._simLock;
    }
    get technicalSegment (): boolean {
        return this._technicalSegment;
    }
    get descriptionAccessory (): string {
        return this._descriptionAccessory;
    }
    get descriptionDevicecardFront (): string {
        return this._descriptionDevicecardFront;
    }
    get descriptionDevicecardBack (): string {
        return this._descriptionDevicecardBack;
    }
    get productInfoSheet (): string {
        return this._productInfoSheet;
    }
    get orderType (): string {
        return this._orderType;
    }
    get olsLaunchDate (): number {
        return this._olsLaunchDate;
    }
    get productCategory (): string {
        return this._productCategory;
    }
    get contractType (): string {
        return this._contractType;
    }
    get contractProlongationRestrictions (): string {
        return this._contractProlongationRestrictions;
    }
    get bundledWith (): string {
        return this._bundledWith;
    }
    get dicount5Euro (): boolean {
        return this._dicount5Euro;
    }
    get ean (): string {
        return this._ean;
    }
    get artikelnummer (): string {
        return this._artikelnummer;
    }
    get mpn (): string {
        return this._mpn;
    }
    get btx (): string {
        return this._btx;
    }
    get salesChannel (): string {
        return this._salesChannel;
    }
    get kdgJsonTv (): boolean {
        return this._kdgJsonTv;
    }
    get productCode (): string {
        return this._productCode;
    }
    get launchDate (): string {
        return this._launchDate;
    }
    get standbyTime (): number {
        return this._standbyTime;
    }
    get qiStandard (): boolean {
        return this._qiStandard;
    }
    get housingSize (): number {
        return this._housingSize;
    }
    get housingColor (): string {
        return this._housingColor;
    }
    get bracelet (): string {
        return this._bracelet;
    }
    get braceletColor (): string {
        return this._braceletColor;
    }

    get simSlotCount (): string {
        return this._simSlotCount;
    }

    get resolutionUnfolded (): string {
        return this._resolutionUnfolded;
    }

    get displayUnfolded (): number {
        return this._displayUnfolded;
    }

    get dataUsage (): number {
        return this._dataUsage;
    }
    get faceId (): boolean {
        return this._faceId;
    }

    get series (): string {
        return this._series;
    }

    get ecoRating (): string {
        return this._ecoRating;
    }

}

declare var $: JQueryStatic;
declare var vf: any;

import {Renderable} from '../../../view/renderable';

import Injector from 'core/injector';
import ViewOverlay from '../../../view/view/shared/overlay';

export class ViewEvolvedElementSharedExitIntent extends Renderable<any> {
    protected _headline: string;
    protected _elementHtml: string;
    protected _isOverlayOpen = false;
    protected _pixelTriggerTop = 5; // Pixel height from menu
    protected _pixelTriggerBottom = 50; // Not used
    protected _wasAlreadyClosed = false;
    protected _timerCheckEveryMinutes = 1;
    protected _lastActivity = 0;
    protected _exitIntentOverlayId = '#nsf-exit-intent';
    // on mobile 2minutes desktop 10
    protected _openOverlayAfterMinutesOfInactivty = 'mobile' !== vf.util.layout(true) ? 10 : 2;
    protected _isOverlayActivated = false;

    constructor (injector: Injector) {
        super(injector);

        // Getting overlay content
        this._elementHtml = $(this._exitIntentOverlayId).html();
        // Removing the content to prevent double ids
        $(this._exitIntentOverlayId).remove();
    }
    /**
     * Nothing to do here
     */
    public render (): void {
    }

    /**
     * binding all events
     */
    public events (): void {
        $(document).on('mousemove', (evt: JQueryEventObject) => {
            this._lastActivity = $.now();

            // Es darf nicht nochmal geöffnet werden
            // es darf nicht schon offen sein
            // Es wird geöffnet wenn die Maus in den ober obendefinierten Bereich pixelTrigger Top kommt
            // Es wird geöffnet wenn die maus unten in den pixel definierten bereich kommt

            if ((( ( evt.pageY - $(window).scrollTop() ) < this._pixelTriggerTop ))) {
            // Bottom
            // || ( evt.pageY - $(window).scrollTop() ) > ( $(window).height() - this._pixelTriggerBottom ))) {

                this.openOverlay();
            }
        });

        // Track click on cta
        $(document).on(' click', '.exit-intent .btn', (evt: JQueryEventObject) => {

            this.getInjector().getEvent().trigger('tracking@LayoverCtaClick', {
                name:  $(evt.currentTarget).text()
            });
        });

        // Set last activity to now on scroll or click.
        $(document).on('scroll click', (evt: JQueryEventObject) => {
            this._lastActivity = $.now();
            // console.log(this._lastActivity);
            this._isOverlayActivated = true;
        });

        // Activate after 10 Sec automatically
        setTimeout( () => {
            this._isOverlayActivated = true;
        }, 10000);

        // Open window on tab change
        $(window).on('blur', (evt: JQueryEventObject) => {
            this.openOverlay();
        });

        /**
         * Close the overlay and set OverlayAlreadyClosed = true.
         * Track the close...
         */
        $('#nsf-overlay').on('click', '.close', (evt: JQueryEventObject) => {
            // Set to false to allow opening the overlay again
            this._isOverlayOpen = false;

            // Set to true to prevent openeing the overlay again
            this.setOverlayAlreadyClosed(true);

            // Track the close
            this.getInjector().getEvent().trigger('tracking@LayoverClosed', {
                name: 'exit intent overlay'
            });
        });

        // Inactivity timeout check
        setInterval( () => {
            // minutes of inactivity klein als aktuelle zeit in millisekunden / 1000 / 60 sekunden
            if ( this._openOverlayAfterMinutesOfInactivty < ( $.now() / 1000 - this._lastActivity / 1000) / 60 ) {
                console.log('open overlay');
                // this._timerCounter = 0;
                // this.openOverlay();
            }
        }, this._timerCheckEveryMinutes * 60 * 1000);
    }

    /**
     * Just a function to define own Rules if the overlay is allowed to open
     */
    protected isAllowedToOpen (): boolean {
        let allowedToOpen = false;
        if ( !this._wasAlreadyClosed
            && !this._isOverlayOpen
            && this._isOverlayActivated
            && (this._elementHtml && 0 < this._elementHtml.length)
            && 0 == $('#exitIntendDisallow').length ) {
            allowedToOpen = true;
        }

        return allowedToOpen;
    }

    /**
     * Check if overlay is allowed to open and then open it!
     */
    protected openOverlay (): void {
        // prevent opening again.
        if ( this.isAllowedToOpen() ) {
            this._isOverlayOpen = true;
            this.getInjector().getEvent().trigger('tracking@Layover', {
                name: 'exit intent overlay'
            });
            const viewOverlay = new ViewOverlay(this._headline, '', this._elementHtml);
            this.getInjector().getOverlay().open(viewOverlay, 'overlay_exit_intent');
        }
    }

    // Save to storage and local variable
    protected setOverlayAlreadyClosed ( closed: boolean): void {
        this._wasAlreadyClosed = closed;
        window.sessionStorage.setItem( 'exitIntentOverlayWasClosed', closed ? 'true' : 'false' );
    }

    /**
     * Init Exit intent.
     */
    public bind (): void {
        /**
         * Read in the session if the Exit intentoverlay was already closed. If yes it would not open gain. See isAllowedtoopen()
         */
        if ( ('true' === window.sessionStorage.getItem('exitIntentOverlayWasClosed'))) {
            this.setOverlayAlreadyClosed(true);
        }
        this._lastActivity = $.now();
        this.render();
        this.events();
    }
}

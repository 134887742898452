/**
 * @module SalesFlow/evolved/view
 */

import {ViewEvolvedCtasBntDataGoBase} from 'view-evolved/ctas/bnt-datago/view-evolved--ctas--bnt-datago-flow-ctas';
import Offer from 'view/view/shared/offer/offer';
import { TariffGroupName } from 'core/ids';

/**
 * Two buttons in pricebox and the very same in costoverview
 * Left: Link to vvl (As always in bnt)
 * Right: Link to connect more page
 *
 * @export
 * @class DeviceDetailBntCtas
 * @extends {FlowCtas}
 */
export class ViewEvolvedCtasBntDataGoDeviceDetailCtas extends ViewEvolvedCtasBntDataGoBase {

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        this._right.setHref(
            this.getInjector().getOptions().get('connectmore_url')
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
        );

    }

}

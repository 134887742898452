/**
 * @module SalesFlow/view
 */

/**
 * @TODO strg+x form device.ts make it a seperate file
 */
import AtomicDevice from 'model/type/atomic-device';

interface AttributeQuery {
    [index: string]: any;
    color: string;
    housingSize: number;
}

class WatchColor {

    private _rgb: string;
    private _name: string;

    constructor (atomicDevice: AtomicDevice) {
        this._rgb = atomicDevice.attr.primaryColor;
        if (undefined === atomicDevice.attr.housingColor) {
            this._name = 'Spacegrau';
        } else {
            this._name = atomicDevice.attr.housingColor;
        }
    }

    get rgb (): string {
        return this._rgb;
    }
    get name (): string {
        return this._name;
    }

}

class ViewWatchColor {

    private _color: WatchColor;
    private _atomicDeviceId: number = undefined;
    private _isSelected: boolean = false;

    constructor (color: WatchColor) {
        this._color = color;
    }

    public setSelected (selected: boolean): void {
        this._isSelected = selected;
    }

    public setAtomicDeviceId (id: number): void {
        this._atomicDeviceId = id;
    }
    public isSelected (): boolean {
        return this._isSelected;
    }

    public isAvailable (): boolean {
        if (undefined !== this._atomicDeviceId) {
            return true;
        }

        return false;

    }

    get rgb (): string {
        return this._color.rgb;
    }
    get name (): string {
        return this._color.name;
    }

    get atomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    get cssClasses (): string {

        const cssClasses: string[] = [];

        // Colors are always selectable and automaticall chooses the next available size
        // if (false === this.isAvailable()) {
        //     cssClasses.push('disabled');
        // }

        if (true === this.isSelected()) {
            cssClasses.push('selected');
        }

        // add en emmpty element to array to create leading space after reverse and join
        if (0 !== cssClasses.length) {
            cssClasses.push('');
        }

        return cssClasses.reverse().join(' ');

    }

}

export default class ViewWatchColors {

    private _colors: ViewWatchColor[] = [];

    constructor (atomicDevice: AtomicDevice) {
        const colors: WatchColor[] = this.setColors(atomicDevice);
        this._colors = this.setViewColors(atomicDevice, colors);

    }

    private uniqueColors (colors: WatchColor[]): WatchColor[] {
        const uniqueColors: WatchColor[] = [];

        const unique: any = {};

        for (const color of colors) {
            if (true === unique[color.name]) {
                continue;
            }

            uniqueColors.push(color);
            unique[color.name] = true;

        }

        return uniqueColors;
    }

    private sortColors (colors: WatchColor[]): void {
        colors.sort((a: WatchColor, b: WatchColor) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    }

    /**
     * Map colors with atomicDevices with the very same size
     */
    private setViewColors (atomicDevice: AtomicDevice, colors: WatchColor[]): ViewWatchColor[] {

        const viewColors: ViewWatchColor[] = colors.map((color) => {
            return new ViewWatchColor(color);
        });

        const attributeQuery: AttributeQuery = {
            color: atomicDevice.attr.housingColor,
            housingSize: atomicDevice.attr.housingSize
        };

        const atomicDevicesWithSameAttr = atomicDevice.device.getAtomicDevicesByAttr('housingSize', atomicDevice.attr.housingSize);

        for (const atomicDeviceWithSameAttr of atomicDevicesWithSameAttr) {
            for (const viewColor of viewColors) {

                if (viewColor.name !== atomicDeviceWithSameAttr.attr.housingColor) {
                    continue;
                }

                if (undefined !== viewColor.atomicDeviceId) {
                    continue;
                }

                viewColor.setAtomicDeviceId(atomicDeviceWithSameAttr.id);

            }
        }

        for (const viewColor of viewColors) {
            if (viewColor.name !== atomicDevice.attr.housingColor) {
                continue;
            }

            viewColor.setSelected(true);
            break;

        }

        return viewColors;

    }

    /**
     * Get colors from all atomicDevices
     */
    private setColors (atomicDevice: AtomicDevice): WatchColor[] {
        let colors: WatchColor[] = [];

        colors = atomicDevice.getDevice().getAtomicDevices().map((atomicDevice) => {
            return new WatchColor(
                atomicDevice
            );

        });

        colors = this.uniqueColors(colors);

        this.sortColors(colors);

        return colors;

    }

    get all (): ViewWatchColor[] {
        return this._colors;
    }

    get selected (): ViewWatchColor {

        for (const color of this._colors) {
            if (color.isSelected()) {
                return color;
            }
        }

        return undefined;
    }

}

/**
 * @module SalesFlow/router
 */

import {SalesChannelName, SubscriptionGroupName} from 'core/ids';
import {Constants} from 'core/constants';

/**
 * Reading get parameters
 */
export default class GetParameter {

    public getLocationSearchParameter (parameterName: string, url: string = undefined): any  {
        const allParams = this.getLocationSearchParameters(url);

        return allParams[parameterName];
    }

    public getLocationSearchParameters (url: string = undefined) {
        const params: any = {};
        let searchString: string;
        let keyValueArr: any;

        if (undefined === url) {
            searchString = location.search.substr(1);
        } else if (-1 !== url.indexOf('?')) {
            searchString = url.split('?')[1] || '';
        }

        if (undefined === searchString || '' === searchString) {
            return params;
        }

        const searchArray: string[] = searchString.split('&');

        searchArray.forEach(str => {
            keyValueArr = str.split('=');
            if (keyValueArr[1] ) {
                params[keyValueArr[0]] = decodeURIComponent(keyValueArr[1]);
            } else {
                params[keyValueArr[0]] = undefined;
            }
        });

        return params;

    }

    public getLocationSearchStringFromObject (query: any) {

        let search: string = '';
        let key: string;
        const keysToEncode = [
            'tariffId',
            'deviceId'
        ];

        // Instead of .includes which is not supported a simple in array function
        const isInArray = (value: string, array: string[]): boolean => {
            return array.indexOf(value) > -1;
        };

        for (key in query) {
            if (query.hasOwnProperty(key)) {
                const value = query[key];
                search += ( isInArray(key, keysToEncode) ? encodeURIComponent('&') : '&' ) + key + '=' + encodeURIComponent(value);
            }
        }

        return '?' + search.substr(3);

    }

    public getSalesChannelGetParam (): SalesChannelName {

        const salesChannel: any = this.getLocationSearchParameter('salesChannel');

        if (undefined === salesChannel) {
            return undefined;
        }

        if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel && Constants.SALESCHANNEL_SOHO !== salesChannel) {
            return undefined;
        }

        return salesChannel;

    }

    public getSubscriptionGroupGetParam (): SubscriptionGroupName {

        let tariffGroup: any = this.getLocationSearchParameter('subscriptionGroup');

        if (undefined === tariffGroup) {
            return undefined;
        }

        // @TODO this alias should be the real name
        if ('red' === tariffGroup) {
            tariffGroup = 'consumer';
        }

        if ('consumer' !== tariffGroup && 'young' !== tariffGroup && 'soho' !== tariffGroup && 'easy' !== tariffGroup) {
            return undefined;
        }

        return tariffGroup;

    }

    public getSubscriptionIdGetParam (checkAllowedSubscriptionId: boolean = false): number {

        let subscription: any = this.getLocationSearchParameter('subscriptionId');

        if (undefined === subscription) {

            // @TODO this is legacy parameter
            subscription = this.getLocationSearchParameter('tariffId');

        }

        if (undefined === subscription) {

            return undefined;
        }

        const subscriptionId = parseInt(subscription, 10);

        if (true === isNaN(subscriptionId)) {
            return undefined;
        }

        if (checkAllowedSubscriptionId && -1 === Constants.AvailableMainSubscriptionIDs.indexOf(subscriptionId)) {

            if (history.pushState) {
                const newUrl = 'https://' + window.location.host + window.location.pathname;
                window.history.pushState({path: newUrl}, '', newUrl);
            }

            return undefined;

        }

        return subscriptionId;

    }

    public getDeviceIdGetParam (): number {

        const device: any = this.getLocationSearchParameter('deviceId');

        if (undefined === device) {
            return undefined;
        }

        const deviceId = parseInt(device, 10);

        if (true === isNaN(deviceId)) {
            return undefined;
        }

        return deviceId;

    }

    public getDeviceFilterGetParam (): string {

        let filter: any = this.getLocationSearchParameter('filter');

        if (undefined === filter) {
            filter = this.getLocationSearchParameter('deviceId');
        }

        if (undefined === filter) {
            return undefined;
        }

        return filter;

    }

    public getVfPassIdParam (): number {
        const pass: any = this.getLocationSearchParameter('passId');

        if (undefined === pass) {
            return undefined;
        }

        const passId = parseInt(pass, 10);

        if (true === isNaN(passId)) {
            return undefined;
        }

        return passId;
    }

    public getForAppGetParam (): boolean {

        const forApp: any = this.getLocationSearchParameter('forApp');

        if (undefined === forApp) {
            return false;
        }

        return 'true' === forApp;

    }
}

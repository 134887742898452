/**
 * @module SalesFlow/view
 */

import {BntFlowCtas} from '../bnt/flow-ctas';
import {FlowCta} from '../shared/flow-cta';
import Offer from '../../view/shared/offer/offer';

/**
 * One button in pricebox and the same (different wording) in costoverview
 * Right: Link to basket
 *
 * @export
 * @class ProductDetailMultisimCtas
 * @extends {FlowCtas}
 */
export default class ProductDetailMultisimCtas extends BntFlowCtas {

    /**
     * In multims ther is no left button
     */
    protected getLeftButton (): FlowCta {

        return undefined;

    }

    /**
     * Overwriting right button while different text
     */
    protected getRightButton (): FlowCta {

        return new FlowCta(
            ['nfs-pricebox-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.basket')
        );

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        this._right.setHref(
            '#proceedToBasket'
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
        );

    }

    protected events (): void {

        super.events();

        /**
         * From connect more the next step "forward" is the basket
         */
        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            evt.preventDefault();

            this.createBasketAndProceed();

        });
    }

}

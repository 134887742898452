/**
 * @module SalesFlow/view
 */

import ViewWatchColors from 'view/view/shared/picker/watch-colors';
import ViewHousingSizes from 'view/view/shared/picker/housing-sizes';
import ViewAccessoryOffer from '../accessory-offer';

import DeviceOffer from './device-offer';
import VluxOffer from 'model/type/offer';
import AtomicDevice from 'model/type/atomic-device';

export default class WatchOffer extends DeviceOffer {

    public type: string = 'device';

    protected _accessorieOffers: ViewAccessoryOffer[];

    protected _offer: VluxOffer;

    private _deviceBaseName: string = 'Apple Watch';

    constructor (
        atomicDevice: AtomicDevice,
        offer: VluxOffer,
        optionalServices: number[] = [],
        accessoryOffers: ViewAccessoryOffer[] = []
    ) {

        super(atomicDevice, undefined, offer, [], [], accessoryOffers);

        this._offer = offer;

    }

    /**
     *  Device reduced onetime price
     */
    get onetimeDevicePrice (): number {
        return this._offer.devicePrice[0].value;
    }

    get monthlyDiscountPrice (): number {
        return 0;
    }

    get watchColors (): ViewWatchColors {
        return new ViewWatchColors(this._atomicDevice);
    }

    get housingSizes (): ViewHousingSizes {
        return new ViewHousingSizes(this._atomicDevice);
    }

    get deviceShortDescription (): string {
        return (this._atomicDevice.attr.bracelet || '') + ' ' + (this._atomicDevice.attr.braceletColor || '');
    }

}

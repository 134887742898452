import {Constants} from 'core/constants';

/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import Injector from 'core/injector';
import Offer from 'view/view/shared/offer/offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import SubscriptionDetailOverlay from 'view/element/bnt/subscription-detail-overlay';
import {Renderable} from 'view/renderable';
import {ViewEvolvedElementVvlSubscriptionDetail} from 'view-evolved/element/vvl/view-evolved--element-vvl--subscription-detail';
import {ViewEvolvedElementVvlVfPassOverlay} from 'view-evolved/element/vvl/view-evolved--element-vvl--vf-pass-overlay';
import {BusinessTransactionContext} from 'core/ids';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

export class ViewEvolvedElementVvlSubscriptionRecommendation extends Renderable<SimOnlyOffer[]> {

    private _element: JQuery;

    private _tariffTilesSelector: string = '.tariff-module-tile[data-subscription-id]';

    private _activeSubscriptionId: number;

    private _offers: SimOnlyOffer[];

    private _subscriptionDetail: ViewEvolvedElementVvlSubscriptionDetail;

    private _subscriptionDetailOverlay: SubscriptionDetailOverlay;

    private _vfPassOverlayVvl: ViewEvolvedElementVvlVfPassOverlay;

    protected _btx: BusinessTransactionContext;

    protected _gigakombiDeviceDetailService: GigakombiDeviceDetailService;

    constructor (subscriptionId: number, injector: Injector) {

        super(injector);

        this._activeSubscriptionId = subscriptionId;

        this._element = $('#nsf-subscription-list-slide');

        this._subscriptionDetailOverlay = new SubscriptionDetailOverlay(injector);

        this._subscriptionDetail = new ViewEvolvedElementVvlSubscriptionDetail(this._element, injector);

        this._vfPassOverlayVvl = new ViewEvolvedElementVvlVfPassOverlay(injector);

        this._btx = injector.getBtx();

        this._gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());
    }

    /**
     * @TODO super function ;)
     * complicted way to determin that subId is within subscription group
     *
     * @param {number} subId
     * @returns {number[]}
     * @memberof SubscriptionRecommendation
     */
    public getSubscriptionIds (subId: number): number[] {

        const subscriptionIds: number[] = [];

        this._element.find(this._tariffTilesSelector).each((index, tariffTile) => {
            if (this.getInjector().getFlow().getSubscriptionGroup() === $(tariffTile).data('tariff-group')
                && $(tariffTile).data('subscription-id') === subId) {
                const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);

                subscriptionIds.push(subscriptionId);

            } else {
                $(tariffTile).addClass('hide');
            }

        });

        return subscriptionIds;
    }

    public setActiveSubscriptionId (subscriptionId: number) {
        this._activeSubscriptionId = subscriptionId;
    }

    public setTariffName (name: string) {
        $('#nsf-tariff-name').html(name);
    }

    public update (offers: Offer[]): void {
        this._offers = offers;
        this._element.find(this._tariffTilesSelector).parent().addClass('noOffer');
        const customer = this.getInjector().getFlow().customer;
        for (const offer of offers) {

            const tile: JQuery = this._element.find('[data-subscription-id="' + offer.subscriptionId + '"]');

            tile.parent().removeClass('noOffer');

            tile.find('.priceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyDiscountPrice, 'partials'));

            if (offer.monthlyRegularPrice !== offer.monthlyDiscountPrice) {

                tile.find('.strikePriceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyRegularPrice, 'partials'));

            }
            else {

                tile.find('.strikePriceVlux').parents('.additional.standard-price').html('pro Monat').addClass('no-strike');

                // Workaround to remove asterisk if tariff has no strike price but asterisk is data-attribute to runtime and no price [CO-14319]
                tile.find('.asterisk-text').addClass('with-sub');
                tile.find('.asterisk').remove();
            }

            if (offer.offerTariffCosts.discountPrices.length) {

                if (undefined !== offer.offerTariffCosts) {
                    if (0 < offer.offerTariffCosts.discountPrices.length) {

                        let undiscountedPrice: string = '' + (Math.round(offer.offerTariffCosts.priceArray[offer.offerTariffCosts.shortestDiscount] * 100) / 100).toFixed(2);
                        undiscountedPrice = undiscountedPrice.replace('.', ',');

                        tile.find('.asterisk-text').html(
                            'Ab dem ' + (offer.offerTariffCosts.discountPrices[0].runtime) +
                            '. Monat ' + this.getInjector().getTemplates().render('price-element', undiscountedPrice, 'partials') + '<br> 24 Monate Mindestlaufzeit inklusive SpeedGo <sup>2</sup>');
                    }

                    // Init footnote.js to handle race conditions and initialize sub scrolling
                    vf.footnotes.init();
                }

            }

            tile.find('.price-info.onetime').parent().remove();
            /*if (offer.isDevice()) {
                tile.find('.price-info.monthly').parent().before(this.getInjector().getTemplates().render('subscription_device_price', offer));
            }*/

            if (undefined === offer.subscription.pib) {
                tile.find('.productinformationVlux').remove();
            }
            else {
                tile.find('.productinformationVlux').attr('href', offer.subscription.pib);
            }

            if (customer.hasGigakombiCase) {
                this._gigakombiDeviceDetailService.updateDataVolumeOnTile(tile, offer);
            }

            if (this.getInjector().getFlow().getSubscriptionGroup() === tile.data('tariff-group')) {
                tile.removeClass('hide');
            } else {
                tile.addClass('hide');
            }
        }

        if (customer.hasGigakombiCase) {
            this._gigakombiDeviceDetailService.updateTariffTileWithGigaKombiPromoBadge(offers);
        }

        $('.noOffer').hide();

    }

    public render (offers: Offer[]): void {

        this._element.find(this._tariffTilesSelector).removeClass('selected');
        this._element.find(this._tariffTilesSelector).show();

        this._element.find(this._tariffTilesSelector).each((index, tariffTile) => {
            const discountText = $(tariffTile).data('discount-text');
            if (undefined !== discountText && undefined === $(tariffTile).find('.price-detail .discount').html()) {
                $(tariffTile).find('.price-info.monthly').parent('.price-detail').prepend(
                    this.getInjector().getTemplates().render('subscription_discount_text', discountText)
                );
            }

            /**
             * It is necessary to hide the text 'jetzt nur online' and 'inkl. Smartphonezuzahlung' from the subscription tiles in subscription change (inlife).
             * The reason we do this with .js is because we are re-using vvl's tiles so the editorial team does not have to make brand new tariff tiles just for inlife.
             */
            if (Constants.BTX_INLIFE === this._btx) {
                $(tariffTile).find('.interval').addClass('inlife_hide');
                $(tariffTile).find('.asterisk-text.with-sub').addClass('inlife_hide');
            }

        });

        this.update(offers);

        if (false === this.getInjector().getFlow().getHardwareOnly()) {
            if (undefined !== this._activeSubscriptionId) {

                this._element.find('[data-subscription-id="' + this._activeSubscriptionId + '"]').addClass('selected');

            }
        }

    }

    public events (): void {

    }

    public bind (offers: Offer[]): void {

        this._offers = offers;

        this.render(offers);

        this._subscriptionDetail.bind(offers);

        this.events();

        // The subscription detail overlay is already binded in the SubscriptionDetail Class
        // this._subscriptionDetailOverlay.bind();

        this._vfPassOverlayVvl.bind();

    }

}

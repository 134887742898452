/**
 * @module SalesFlow/evolved/model/vlux
 */

import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import {Constants} from 'core/constants';

/**
 * This class creates a map between what the string is in VLUX to what we call it in our system
 */
export class ModelEvolvedVluxMapStringsBetweenSalesAndVlux {

    /**
     * Key is how we name it, value is how vlux names it
     */
    private readonly map: any = {
        bnt: 'newContract',
        vvl: 'contractProlongation',
        hardware: 'hardware',
        inlife: 'tariffChange',
        consumer: 'Online.Consumer',
        young: 'Online.Young',
        soho: 'Online.SoHo',
        familyfriends: 'Online.FamilyFriends',
        gigakombi: 'gigakombi',
        multicard: 'multisim'
    };

    public salesToVlux (salesString: string): string {

        for (const sales in this.map) {

            if (salesString === sales) {
                return this.map[sales];
            }

        }

        return undefined;

    }

    public vluxToSales (vluxString: string): string {
        for (const sales in this.map) {

            if (vluxString === this.map[sales]) {
                return sales ;
            }
        }

        return undefined;
    }

    public vluxToSalesSalesChannel (vluxString: string): SalesChannelName {

        for (const sales in this.map) {

            if (vluxString === this.map[sales]) {

                if (
                    Constants.SALESCHANNEL_CONSUMER === sales
                    || Constants.SALESCHANNEL_YOUNG  === sales
                    || 'easy' === sales
                    || Constants.SALESCHANNEL_SOHO === sales
                    || Constants.SALESCHANNEL_FAMILY_FRIENDS === sales
                ) {
                    return sales as SalesChannelName;
                }

            }

        }

        return undefined;

    }

    public vluxToSalesBtx (vluxString: string): BusinessTransactionContext {

        for (const sales in this.map) {

            if (vluxString === this.map[sales]) {

                if ( Constants.BTX_BNT === sales
                    || Constants.BTX_VVL  === sales
                    || Constants.BTX_MULTISIM === sales
                    || Constants.BTX_HARDWARE === sales
                    || Constants.BTX_INLIFE === sales
                    || Constants.BTX_GIGAKOMBI === sales
                ) {
                    return sales as  BusinessTransactionContext;
                }

            }

        }

        return undefined;

    }

}

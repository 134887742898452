/**
 * @module SalesFlow/controller-evolved
 */

declare var $: JQueryStatic;
declare var vf: any;

import { InlifeSharedController } from 'controller-evolved/inlife/controller-evolved--inlife-shared';

import VluxOffer from 'model/type/offer';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';

import { ViewEvolvedElementInlifeContractDataAccordion } from 'view-evolved/element/inlife/view-evolved--element-inlife--contract-data-accordion';
import { ViewEvolvedInlifeCtasInlifeTariffSelection } from 'view-evolved/ctas/inlife/view-evolved--ctas--inlife-tariff-selection';

import {EvolvedInlifeSubscriptionSelection} from 'view-evolved/element/inlife/view-evolved--element-inlife--subscription-selection';

import { SalesChannelName, ContractPeriodType, SubscriptionIdPerSalesChannel } from 'core/ids';

import Customer from 'shopbackend/customer';
import Subscription from 'model/type/subscription';
import Pricebox from 'view/element/shared/pricebox';
import Injector from 'core/injector';
import { Constants } from 'core/constants';
import InlifeOffer from 'view/view/shared/offer/inlife-offer';
import { NotificationContractPeriodType } from 'view-evolved/notifications/inlife/notification-contract-period-type';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

/**
 * @internal
 */
export class InlifeTariffSelectionController extends InlifeSharedController {

    protected _customer: Customer;

    protected _mainSubscriptionGroups: Subscription[];
    protected _subscriptionId: number;
    protected _allowedTariffIds: number[];
    protected _focusSubscriptionId: SubscriptionIdPerSalesChannel;
    protected _subscriptionSelection: EvolvedInlifeSubscriptionSelection;
    protected _subscription: Subscription;

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    protected _subLevelAmount: number;
    protected _viewCustomersData: ViewEvolvedElementInlifeContractDataAccordion;
    protected _notificationContractPeriodType: NotificationContractPeriodType;
    protected _offers: VluxOffer[];

    private _pricebox: Pricebox;
    private _element: JQuery;

    constructor (
        salesChannel: SalesChannelName,
        mainSubscriptionGroups: Subscription[],
        inlifeOffers: VluxOffer[],
        allowedTariffIds: number[],
        subLevelAmount: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super (
            reposSupervisor,
            injector
        );

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('Tariff Selection Controller Inlife ....');
        }

        /**
         * In inlife we need the main subscription group id (for example: All Red M subscriptions have an id = Constans.RedM_Id) and the sub level subscription id. Following the Red M example, a Red M subscription with 10 euros sub level has an id of 516.
         */

        this._customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        this._mainSubscriptionGroups = mainSubscriptionGroups;
        this._subLevelAmount = subLevelAmount;
        this._viewCustomersData = new ViewEvolvedElementInlifeContractDataAccordion(this._customer, injector);
        this._offers = inlifeOffers;
        this._allowedTariffIds = allowedTariffIds;
        this._salesChannel = salesChannel;
        this._pricebox = new Pricebox(injector);
        this._element = $('#nsf-subscription-list-slide');
        this._subscriptionId = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();
        this._subscriptionSelection = this.createSubscriptionList();
        this._ctas = this.createCtas();

    }

    protected createSubscriptionList (): EvolvedInlifeSubscriptionSelection {

        return new EvolvedInlifeSubscriptionSelection(
            this._subscriptionId,
            this.getInjector()
        );

    }

    protected createCtas (): ViewEvolvedInlifeCtasInlifeTariffSelection {
        let showRedXLNotification: boolean = false;

        const selectedOffer = this.getActiveOffer();
        if (undefined !== selectedOffer) {
            if (true === this.isRedXLRecommendedTariff(selectedOffer.offer.subscriptionId) && undefined !== this._customer.hasExistingRedPlus && true === this._customer.hasExistingRedPlus) {
                showRedXLNotification = true;
            }
        }

        return new ViewEvolvedInlifeCtasInlifeTariffSelection (this._injector, showRedXLNotification);
    }

    protected render (data?: any): void {

        const selectedOffer = this.getActiveOffer();
        if (undefined !== selectedOffer) {
            this.setContractPeriodType(this._customer, selectedOffer.offer.subscriptionId);
            this.createWarningNotificationForContractPeriodType();
            this.showYoungNotification(this._element, selectedOffer.subscriptionId);
            this.handleGigakombiNotification(selectedOffer);
        }

    }

    protected createWarningNotificationForContractPeriodType () {

        this._notificationContractPeriodType = new NotificationContractPeriodType(
            this.getInjector(),
            this._contractPeriodType,
            this._customer.isSohoCustomer,
            '#nsf-subscription-list-slide'
        );

    }

    public setSubscriptionName (subscriptionName: string): void {
        $('#nsf-tariff-name').html(subscriptionName);
    }

    public events () {

        this.getInjector().getEvent().listen('costoverview@updated', this.handleContractPeriodTypeFootnotes);

        this.getInjector().getEvent().listen('subscriptionId@changed', (eventObject: JQueryEventObject, data: any) => {

            this._subscriptionId = data.subscriptionId;

            this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this._subscriptionId);

            this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this._subscription.id);

            const selectedOffer = this.getActiveOffer();

            this.getInjector().getOfferCollection().setActiveOffer(selectedOffer);

            this.setContractPeriodType(this._customer, selectedOffer.offer.subscriptionId);

            this.createWarningNotificationForContractPeriodType();

            this.showYoungNotification(this._element, selectedOffer.subscriptionId);

            this.handleGigakombiNotification(selectedOffer);

            this.createCtas();

            this._notificationContractPeriodType.bind();

            this.setSubscriptionName(this._subscription.name);

            this._subscriptionSelection.setActiveSubscriptionId(selectedOffer.subscriptionId);

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: selectedOffer
            });

        });

    }

    /**
     * This function gets the simonly-offer to the active subscriptionId that is stored in flow
     */
    protected getActiveOffer (): InlifeOffer {

        const subscriptionId = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        if (undefined !== subscriptionId && undefined !== this._allowedTariffIds) {
            for (let i = 0; i < this._allowedTariffIds.length; i++) {
                if (subscriptionId === this._allowedTariffIds[i]) {

                    const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

                    return new InlifeOffer(
                        subscription,
                        this._offers[i],
                        [],
                        [],
                        this._subLevelAmount
                    );
                }
            }
        }

        return undefined;

    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                offer: this.getActiveOffer(),
                pageName: 'personal offer',
                pageType: 'product detail',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this._injector.getFlowStateWithSalesChannel().customer
            }
        );
    }

    protected getInlifeViewOffers (): InlifeOffer[] {

        const inlifeOfferViews = [];

        for (let i = 0; i < this._offers.length; i++) {
            const inlifeOfferView = new InlifeOffer(
                this._mainSubscriptionGroups[i],
                this._offers[i],
                [],
                [],
                this._subLevelAmount);
            inlifeOfferViews.push(inlifeOfferView);
        }

        return inlifeOfferViews;
    }

    public bind (): void {

        this._viewCustomersData.bind();

        if (undefined !== this._offers) {
            this.getInjector().getLoadingIndicator().hide();
        }

        this._subscriptionSelection.setSubscriptionIds(this._allowedTariffIds);

        const inlifeViewOffers = this.getInlifeViewOffers();

        this.getInjector().getOfferCollection().setActiveOffer(
            this.getActiveOffer()
        );

        this.getInjector().getOfferCollection().setSubscriptions(
            inlifeViewOffers
        );

        this._subscriptionSelection.bind(
            inlifeViewOffers
        );

        this._subscriptionSelection.update(
            inlifeViewOffers
        );

        this.render();
        this.events();

        this._pricebox.bind(this.getActiveOffer());

        if (undefined !== this._ctas) {
            this._ctas.bind(this.getActiveOffer());
        }

        super.bind(this._element);

        this.tracking();
    }

}

/**
 * @module SalesFlow/router
 */

import Router from 'router/shared/router';
import SubscriptionRepo from 'model/repo/subscription-repo';

import BntSubscriptionDetailSeoController from 'controller//bnt/subscription-detail-seo-controller';

import {FlowController} from 'controller/flow-controller';
import { VluxJsonData } from '@vfde-fe/general-sales-objects';
import DependencyRepo from 'model/repo/dependency-repo';
import ServiceRepo from 'model/repo/service-repo';

/**
 * @TODO don't use injector as dependency
 */
/**
 * @internal
 */
export default class SubscriptionDetailSeoBntRouter extends Router {

    private _serviceRepo: ServiceRepo;
    private _discountRepo: ServiceRepo;
    private _dependencyRepo: DependencyRepo;

    public getRepos (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getInjector().getRepos().setSubscriptionGroup(
            this.getInjector().getFlow().getSubscriptionGroup()
        );

        $.when(
            this.getInjector().getRepos().getSubscriptionRepo(),
            this.getInjector().getRepos().getServiceRepo(),
            this.getInjector().getRepos().getDiscountRepo(),
            this.getInjector().getRepos().getDependencyRepo()
        ).done((
            subscriptionRepo: SubscriptionRepo,
            serviceRepo: ServiceRepo,
            discountRepo: ServiceRepo,
            dependencyRepo: DependencyRepo
        ) => {
            this._subscriptionRepo = subscriptionRepo;
            this._serviceRepo = serviceRepo;
            this._discountRepo = discountRepo;
            this._dependencyRepo = dependencyRepo;

            const vluxData: VluxJsonData = {
                subscriptionJSON: this._subscriptionRepo.rawData,
                discountJSON: this._discountRepo.rawData,
                serviceJSON: this._serviceRepo.rawData,
                dependenciesJSON: this._dependencyRepo.rawData
            };

            /**
             * on this page we don't need Red+ or Hardware
             * so we kan skip the two optional paramerers
             */
            this.prepareGeneralSalesObject(vluxData);

            deferred.resolve();

        }).fail(() => {

            deferred.reject();

        });

        return deferred.promise();

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionTarifIds()
        );

    }

    public createController (): JQueryPromise<FlowController> {

        const deferred = $.Deferred<any>();

        this.getRepos().then(() => {
            this.validateIncoming();

            deferred.resolve(
                new BntSubscriptionDetailSeoController(
                    this._subscriptionRepo,
                    this.getInjector()
                )
            );

        });

        return deferred.promise();

    }

}

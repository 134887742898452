/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';
import Customer from 'shopbackend/customer';
import {Renderable} from 'view/renderable';

declare var $: any;

export default class ContractData extends Renderable<Customer> {

    protected _element: JQuery;

    constructor (injector: Injector) {
        super(injector);

        this._element = $('#nsf-contract-data-recommendation');
    }

    public render (customer: Customer): void {

        this._element.html(this.getInjector().getTemplates().render('contract-data-recommendation-multisim', customer));
    }

    public events (): void {

    }

    public bind (customer: Customer): void {
        this.render(customer);
        this.events();
    }
}

/**
 * @module SalesFlow/controller-evolved
 */

declare var $: JQueryStatic;
declare var vf: any;

import { ControllerEvolvedFlowBase } from 'controller-evolved/controller-evolved--flow-base';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';
import { SalesChannelName, ContractPeriodType } from 'core/ids';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import { Constants } from 'core/constants';
import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import { NotificationContractPeriodType } from 'view-evolved/notifications/inlife/notification-contract-period-type';
import Offer from 'view/view/shared/offer/offer';
import ViewAlertWarning from 'view/view/shared/alert-warning';
import ViewAlertInfo from 'view/view/shared/alert-info';

/**
 * @internal
 */
export class InlifeSharedController extends ControllerEvolvedFlowBase {

    protected _customer: Customer;

    protected _notificationContractPeriodType: NotificationContractPeriodType;
    protected _offer: Offer;

    protected _salesChannel: SalesChannelName;

    constructor (
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super (
            reposSupervisor,
            injector
        );

    }

    protected getSalesChannel (): SalesChannelName {

        return this._salesChannel;

    }

    protected createCtas (): FlowCtas {
        return undefined;

    }

    protected render (): void {

    }

    public events () {

    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this._offer,
                pageName: 'personal offer',
                pageType: 'product detail',
                btx: this._btx,
                currentPage: this._injector.getRouting().getCurrentPage(),
                customer: this._injector.getFlowStateWithSalesChannel().customer
            }
        );
    }

    /**
     * Show GigaKombi Notification
     * @param element
     */
    protected showGigaKombiNotification (element: JQuery) {
        const headline = this.getInjector().getStrings().get('inlife.gigakombi.notification.headline')
            .replace(/\[salutation\]/, ('Herr' === this._customer.salutation ? 'r' : ''))
            .replace(/\[firstname\]/, this._customer.firstname)
            .replace(/\[lastname\]/, this._customer.lastname);

        const alert = new ViewAlertWarning(
            this.getInjector().getStrings().get('inlife.gigakombi.notification.text'),
            headline,
            undefined,
            'gigakombi-notification'
        );
        if (0 === $('#gigakombi-notification').length) {
            element.first().after(
                this.getInjector().getTemplates().render('alert',
                    alert
                )
            );
        }
    }

    /**
     * Show Ultracard Notification
     * @param element
     */
    protected showUltracardNotification (element: JQuery) {

        const alert = new ViewAlertWarning(
            this.getInjector().getStrings().get('inlife.ultracard.notification'),
            '<br>',
            undefined,
            'ultracard-notification'
        );
        if (0 === $('#ultracard-notification').length) {
            element.first().after(
                this.getInjector().getTemplates().render('alert',
                    alert
                )
            );
        }
    }

    /**
     * [CO-13330] Logic for Gigakombi customers selecting a Young 2019 Xs or S tariff,
     * Showing the notifications that they will loose their Gigakombi discount.
     * @param element
     * @param selectedSubscriptionId
     */
    protected showYoungNotification (element: JQuery, selectedSubscriptionId: number) {

        if (true === this._customer.isGigaKombiCustomer) {

            if (selectedSubscriptionId === Constants.YoungS_Id || selectedSubscriptionId === Constants.YoungXS_Id) {

                const alert = new ViewAlertWarning(
                    this.getInjector().getStrings().get('vvl.switch-to-youngXSorS-notification.content'),
                    this.getInjector().getStrings().get('vvl.switch-youngXSorS-notification.headline'),
                    undefined,
                    'switch-to-youngXSorS-notification'
                );

                if (0 === $('#switch-to-youngXSorS-notification').length) {
                    element.first().after(
                        this.getInjector().getTemplates().render('alert',
                            alert
                        )
                    );
                }

                $('#red6-inlife-gigakombi-notification').remove();

            } else {
                $('#switch-to-youngXSorS-notification').remove();
            }
        }
    }

    protected showGigakombiNotificationBox (notificationContent: string, notificationHeadline: string, position: string, HTMLId?: string, additionalClass?: string, subscriptionName?: string) {
        const content = this.getInjector().getStrings().get(notificationContent)
            .replace(/\[tariffname\]/, subscriptionName);

        const alert = new ViewAlertInfo(
            content,
            this.getInjector().getStrings().get(notificationHeadline),
            undefined,
            HTMLId,
            additionalClass
        );

        /**
         * Only show the notifications if they are not present
         */
        if (0 === $('#' + HTMLId).length) {

            if ('top' === position) {
                $('#nsf-subscription-list-slide').first().before(
                    this.getInjector().getTemplates().render('alert',
                        alert
                    )
                );
            }

            if ('bottom' === position) {
                $('#nsf-hardware-only-toggle').first().after(
                    this.getInjector().getTemplates().render('alert',
                        alert
                    )
                );
            }
        }
    }

    /**
     * @description
     * Function to select specific gigakombi notification within red 6 2020 portfolio
     *
     * @param selectedOffer Current offer to get all necessary information
     * @protected
     */
    protected handleGigakombiNotification (selectedOffer: Offer) {
        const selectedSubscriptionId = selectedOffer.subscriptionId;
        const selectedSubscriptionName = selectedOffer.subscriptionName;

        if (this._customer.isGigaKombiCustomer && -1 !== Constants.AvailableMainSubscriptionIDs.indexOf(selectedSubscriptionId)) {

            $('#switch-to-youngXSorS-notification,#red6-inlife-gigakombi-notification').remove();

            const nsfStringGigakombiNotificationHeadline = 'vvl-inlife.gigakombi-notification.headline';
            let nsfStringGigakombiNotificationContent = this._customer.isSohoCustomer ? 'soho.vvl-inlife.red6-' : 'vvl-inlife.red6-';
            let redTariffName = '';

            switch (selectedSubscriptionName) {
                case 'Red XS':
                    redTariffName = 'xs';
                    break;
                case 'Red S':
                    redTariffName = 's';
                    break;
                case 'Red M':
                case 'Red L':
                    redTariffName = 'ml';
                    break;
                case 'Red XL':
                    redTariffName = 'xl';
                    break;
                default:
                    redTariffName = '';
                    break;
            }

            // Combine partial to specific notification content text
            nsfStringGigakombiNotificationContent = nsfStringGigakombiNotificationContent + redTariffName + '-gigakombi-notification.content';

            this.showGigakombiNotificationBox(nsfStringGigakombiNotificationContent, nsfStringGigakombiNotificationHeadline, 'bottom', 'red6-inlife-gigakombi-notification', 'subscription-bottom-info', selectedSubscriptionName);
        }
    }

    protected isRedXLRecommendedTariff (subscriptionId: number): boolean {
        let isRedXLRecommendedTariff = false;

        if (-1 < Constants.TariffchangeRedXLSubLevelTariffIds.lastIndexOf(subscriptionId)) {
            isRedXLRecommendedTariff = true;
        }

        return isRedXLRecommendedTariff;
    }

    public bind (element: JQuery): void {

        this.handleContractPeriodTypeFootnotes();

        if (undefined !== this._notificationContractPeriodType) {
            this._notificationContractPeriodType.bind();
        }

      /** [INC: 29164] The notification box should not be there for all gigakombi customer, just for the young
       *  and for that case showYoungNotification() takes care. Read ticket comment from Product Manager.
       */
 /*         if (true === this._customer.isGigaKombiCustomer) {
            this.showGigaKombiNotification(element);
        }  */

        if (true === this._customer.hasUltraCard) {
            this.showUltracardNotification(element);
        }

    }

    /**
     * Arrow function to reduce anonymous function in the event listener
     */
    protected handleContractPeriodTypeFootnotes = () => {

        if ('R' === this._contractPeriodType) {
            const tileAsterisks = document.querySelectorAll('.tariff-module-tile-inlife .asterisk-text');

            Array.from(tileAsterisks).forEach((tileAsterisk: HTMLElement) => {
                tileAsterisk.innerHTML = tileAsterisk.innerHTML
                    .replace(/Mindestlaufzeit 24 Monate(\.?)|initialized/g, '')
                    .trim();

                if (tileAsterisk.innerHTML.length === 0) {
                    const asterisk: HTMLElement = tileAsterisk.parentElement.querySelector('.asterisk');

                    tileAsterisk.style.display = 'none';
                    asterisk.style.display = 'none';
                } else {
                    vf.footnotes.init();
                }
            });

            $('.footnote-min-term, .asterisk-min-term').hide();
        }
    }
}

/**
 * @module SalesFlow/model/type
 */
import NsfError from 'core/error';

import DeviceAttributes from './attributes-device';

import Device from './device';
// import { ConsoleReporter } from 'jasmine';

/**
 * @TODO If this can be (de-)serialized, on productDetailPage less repo calls are needed
 */
export default class AtomicDevice {

    private _id: number;

    private _name: string;

    private _backendId: number;

    private _device: Device;

    private _attributes: DeviceAttributes;

    /**
     * @TODO Better false insted of undefined
     */
    private _purchasable: boolean;

    constructor (data: any, parentDevice: Device) {

        this._device = parentDevice;

        this.parseData(data);
    }

    private parseData (data: any) {

        if (undefined === data.id) {
            new NsfError('id is mandatory when create a AtomicDevice.');
        }

        if (undefined === data.label) {
            new NsfError('label is mandatory when create a AtomicDevice.');
        }

        if (undefined === data.attributes) {
            new NsfError('attributes is mandatory when create a AtomicDevice.');
        }

        if (undefined === data.attributes.attribute) {
            new NsfError('attributes.attribute is mandatory when create a AtomicDevice.');
        }

        if (undefined === data.attributes.attribute.length) {
            new NsfError('attributes.attribute must be an array when create a AtomicDevice.');
        }

        this._id = parseInt(data.id, 10);

        this._name = data.label;

        this._backendId = parseInt(data.backendId[0].value, 10);

        if (this._id !== this._device.id) {
            /**
             * Real Atomic Device
             */
            this._attributes = new DeviceAttributes(data.attributes.attribute, this._device.getAttributes());
        } else {
            /**
             * Pseudo Atomic Device
             */
            const dummyAttributes = {};
            this._attributes = new DeviceAttributes(dummyAttributes, this._device.getAttributes());
        }

    }

    public getDevice (): Device {
        return this._device;
    }

    public setPurchasable (purchasable: boolean) {
        this._purchasable = purchasable;
    }

    get device (): Device {
        return this._device;
    }

    get id (): number {
        return this._id;
    }

    get name (): string {
        return this._name;
    }

    get deliveryDate (): string {
        return this._attributes.deliveryDate;
    }

    get backendId (): number {
        return this._backendId;
    }

    get attributes (): DeviceAttributes {
        return this._attributes;
    }

    get attr (): DeviceAttributes {
        return this._attributes;
    }

    get topDevice (): string {
        if (undefined !== this._device) {
            return this._device.topDevice;
        }

        return undefined;
    }

    get badgeText (): string {
        if (undefined !== this._device) {
            return this._device.badge_text;
        }

        return undefined;
    }

    get purchasable (): boolean {
        return this._purchasable;
    }

    get deliveryTimeInDays (): number {

        let days: number = -1;

        if ('string' !== typeof this._attributes.deliveryDate) {
            return days;
        }

        try {

            const jahr = parseInt(this._attributes.deliveryDate.substr(0, 4), 10);
            const monat = parseInt(this._attributes.deliveryDate.substr(4, 2), 10) - 1;
            const tag = parseInt(this._attributes.deliveryDate.substr(6, 2), 10);

            const gesetzt: any = new Date(jahr, monat, tag);
            const heute: any = new Date();

            days = (gesetzt - heute) / 1000 / 60 / 60 / 24;

        } catch (e) {
            return days;
        }

        return days;

    }

    get alternativeDevices (): AtomicDevice[] {

        const ret: AtomicDevice[] = [];

        if ('string' !== typeof this._attributes.deliveryDate) {
            return ret;
        }

        try {

            const myId = this._id;
            const myDeliveryDateStr = this._attributes.deliveryDate;
            const myInternalMemory = this._attributes.internalMemory;
            const myColor = this._attributes.color;

            return this.extractAlternativeDevices(this.device, myId, myDeliveryDateStr, myInternalMemory, myColor);

        } catch (e) {
            return ret;
        }

    }

    /**
     *
     * @param device - the device with all atomics
     * @param deliveryDate - delivery date of current atomic
     * @param internalMemory - internal memory of current
     * @param id - internal memory of current
     *
     * the last 3 parameters are passed standalone as device and current atomic can be different
     *
     * Finds alternative atomics with better delivery-time withing given device
     */

    public extractAlternativeDevices (device: Device, id: number, deliveryDate: string, internalMemory: number, color: string): AtomicDevice[] {

        const alternatives = [];
        const alternativeIds = [];

        /**
         * check alternative atomics in current device
         */

        const atomicDevices: AtomicDevice[] = device.getAtomicDevices();
        const deviceArray = [];
        for (let i = 0; i < atomicDevices.length; i++) {
            if (atomicDevices[i].id !== id && 'undefined' === typeof atomicDevices[i]._attributes.bundledWith) {
                deviceArray.push(atomicDevices[i]);
            }
        }

        deviceArray.sort((a, b) => {

            const valA = a.deliveryDate;
            const valB = b.deliveryDate;

            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }

            if (valA === valB) {

                const valA = a.attributes.internalMemory;
                const valB = b.attributes.internalMemory;

                if (valA < valB) {
                    return -1;
                }
                if (valA > valB) {
                    return 1;
                }

                return 0;
            }

            return 0;
        });

        /**
         * Prio 1: same size + same color
         */
        const sameMemoryColor = deviceArray.filter((a) => {
            return (color === a.attributes.color &&
                internalMemory === a.attributes.internalMemory &&
                deliveryDate > a.deliveryDate);
        });
        if (0 < sameMemoryColor.length) {
            alternatives.push(sameMemoryColor[0]);
            alternativeIds.push(sameMemoryColor[0].id);
        }

        /**
         * Prio 2: same size
         */
        const sameMemory = deviceArray.filter((a) => {
            return (internalMemory === a.attributes.internalMemory &&
                deliveryDate > a.deliveryDate);
        });
        for (let i = 0; i < sameMemory.length; i++) {
            if (-1 === $.inArray(sameMemory[i].id, alternativeIds)) {
                alternatives.push(sameMemory[i]);
                alternativeIds.push(sameMemory[i].id);
            }
            if (3 === alternativeIds.length) {
                return alternatives;
            }
        }

        /**
         * Prio 3: same size
         */
        const allOther = deviceArray.filter((a) => {
            return (deliveryDate > a.deliveryDate);
        });

        for (let i = 0; i < allOther.length; i++) {
            if (-1 === $.inArray(allOther[i].id, alternativeIds)) {
                alternatives.push(allOther[i]);
                alternativeIds.push(allOther[i].id);
            }
            if (3 === alternativeIds.length) {
                return alternatives;
            }
        }

        return [];

    }

}

/**
 * @module SalesFlow/controller
 */

import {FlowController} from 'controller/flow-controller';
import Pricebox from 'view/element/shared/pricebox';
import Customer from 'shopbackend/customer';
import ContractData from 'view/element/multisim/contract-data';
import SimCardRepo from 'model/repo/simcard-repo';
import PurchasableDeviceRepo from 'model/repo/purchasable-device-repo';
import DeviceListMultiSim from 'view/element/multisim/device-list';
import WatchOffer from 'view/view/shared/offer/watch-offer';
import SimCardOffer from 'view/view/shared/offer/simcard-offer';
import AttributeRepo from 'model/repo/attribute-repo';
import Injector from 'core/injector';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import ViewOverlayDeviceDetails from 'view/view/shared/overlay-device-details';
import AtomicDevice from 'model/type/atomic-device';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import ProductDetailMultisimCtas from 'view/ctas/multisim/product-detail-multisim-ctas';
import SimCardList from 'view/element/multisim/simcard-list';
import GetParameter from 'router/get-parameter';
import DeviceFilter from 'view/element/multisim/device-filter';
import { DeviceFilterValue } from 'core/ids';
import {Constants} from 'core/constants';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

declare var $: JQueryStatic;
declare var vf: any;

export default class MultisimProductDetailController extends FlowController {

    private _pricebox: Pricebox;
    private _getParameter = new GetParameter();
    protected _customer: Customer;
    protected _contractData: ContractData;
    protected _simCardList: SimCardList;
    protected _simCardRepo: SimCardRepo;
    protected _purchasableDeviceRepo: PurchasableDeviceRepo;

    protected _deviceList: DeviceListMultiSim;

    protected _watchOffers: WatchOffer[] = [];
    protected _simCardOffer: SimCardOffer;
    protected _watchOffer: WatchOffer;

    protected _deviceFilter: DeviceFilter;

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    private _attributeRepo: AttributeRepo;

    private _focusAtomicDeviceId: number = 0;

    private _isWatchOnlySelected: boolean = true;

    private _maxPhysicalSimVacantSlots: number = 2;
    private _maxEsimVacantSlots: number = 2;

    private _deviceManufacturer: string;

    private _deviceRequirementsAlertMapping: { manufacturer: string, series: string, className: string }[] = [
        {
            manufacturer: 'Apple',
            series: '3',
            className: '.alert__apple--gen3'
        },
        {
            manufacturer: 'Apple',
            series: '4',
            className: '.alert__apple--gen4'
        },
        {
            manufacturer: 'Apple',
            series: '5',
            className: '.alert__apple--gen5'
        },
        {
            manufacturer: 'Samsung',
            series: '',
            className: '.alert__samsung--galaxy'
        }
    ];

    public deviceFilter: string;

    constructor (
        injector: Injector,
        simCardRepo: SimCardRepo,
        attributeRepo: AttributeRepo,
        purchasableDeviceRepo: PurchasableDeviceRepo
    ) {
        super(injector);

        if (true === this.getInjector().getOptions().get('debug')) {
            console.log('Multisim Page VVL constructor....');
        }

        this._attributeRepo = attributeRepo;
        this._purchasableDeviceRepo = purchasableDeviceRepo;

        this._customer = this.getInjector().getFlow().customer;
        this._contractData = new ContractData(injector);
        this._simCardList = new SimCardList(
            $('.tariff-module-tile[data-simcard-id]'),
            injector
        );

        this._simCardRepo = simCardRepo;
        this._pricebox = new Pricebox(injector);
        this._deviceList = new DeviceListMultiSim(injector);
        this._deviceFilter = this.createDeviceFilter();
        this._generalSalesObjectInterface = injector.getGeneralSalesObjectInterface();

    }

    private setWatchOnly (isWatchOnlySelected: boolean): void {
        this._isWatchOnlySelected = isWatchOnlySelected;

    }

    protected render (data?: any): void {

        if (true === this.getInjector().getOptions().get('debug')) {
            console.log('Multisim Page VVL render....');
        }

        if ('mobile' !== vf.util.layout(true)) {
            $('.vll-list-container').toggleClass('vll-accordion-is-open');
            $('.vll-accordion-toggle').css('display', 'none');
        }

        // Check not eligable cases
        if (!this._customer.isTariffMultisimEligible && !this._customer.isMultisimEligible) {
            this.handleNotEligable('.wrong-tarif');
        } else if (!this._customer.isSocMultisimEligible && !this._customer.isMultisimEligible) {
            this.handleNotEligable('.wrong-tarif');
        } else if ( this._maxEsimVacantSlots <= this._customer.esimSlotsInUse) {
            this.handleNotEligable('.max-esims-reached');
        } else if ( this._maxPhysicalSimVacantSlots <= this._customer.physicalSimSlotsInUse) {
            this.handleNotEligable('.max-physical-sims-reached');
        } else if ( 1 === this._customer.esimSlotsInUse && 1 === this._customer.physicalSimSlotsInUse) {
            this.handleNotEligable('.max-esims-and-physical-sims-reached');
        } else if (!this._customer.isMultisimEligible) {
            this.handleNotEligable('.multisim-not-eligable');
        } else {
            $('.multisimallowed').removeClass('access');
        }

        $('body').addClass('multisim-product-page');
    }

    protected createDeviceFilter (): DeviceFilter {
        return new DeviceFilter(
            this.getInjector()
        );
    }

    private handleNotEligable (className: string): void {
        $(className).removeClass('access');
        $('#nsf-subscription-list-slide').addClass('access');
        $('#nsf-contract-data-recommendation').addClass('access');
        $('#nsf-product-list-slide, .hide-on-physical-sim').addClass('access');
        $('#nsf-cost-overview-wrap, #nsf-pricebox').addClass('displayNone');
        $('#nsf-product-list-slide, .alert-on-esim').addClass('access');
    }

    protected getWatchOffers (): WatchOffer[] {

        const devices = this._purchasableDeviceRepo.getDevices();

        return devices.map(device => {

            const atomicDevice: AtomicDevice = device.getAtomicDeviceByIndex(0);
            const offer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
                device.getAtomicDeviceByIndex(0).id,
                this.getInjector().getFlowState().getSalesChannel());

            return new WatchOffer(atomicDevice, offer, this.getInjector().getFlow().optionalServiceIds.elements);

        });

    }

    private _filterWatchOffers (items: DeviceFilterValue[]): WatchOffer[] {
        let filteredWatchOffers: WatchOffer[] = this._watchOffers;

        if (items.length > 0) {
            filteredWatchOffers = this._watchOffers.filter((watchOffer: WatchOffer) => {
                if (items.indexOf(watchOffer.vendor.toLowerCase() as DeviceFilterValue) > -1) {
                    return true;
                }

                return false;
            });
        }
        if (0 === filteredWatchOffers.length) {
            filteredWatchOffers = this._watchOffers;
        }

        return filteredWatchOffers;
    }

    private _updateFilterCounter (countValue: number): void {
        const counterBox = $('.buttons-filter-counter');
        let counterWording = '';

        if (1 === countValue) {
            counterWording = counterBox.data('wording-singular');
        } else {
            counterWording = counterBox.data('wording-plural');
        }
        counterBox.find('.counter-value').text(countValue);
        counterBox.find('.counter-wording').text(counterWording);
    }

    private _hideAllDeviceRequirementsAlerts (): void {
        this._deviceRequirementsAlertMapping.forEach( element => {
            $(element.className).addClass('access');
        });
    }

    public events () {

        if (true === this.getInjector().getOptions().get('debug')) {
            console.log('Multisim Page VVL events....');
        }

        this.getInjector().getEvent().listen('device-filter@clicked', (eventObject: JQueryEventObject, filterItem: any) => {
            this._deviceFilter.handleFilterClickEvent(filterItem.filterItem);
        });

        this.getInjector().getEvent().listen('deviceFilter@changed', (eventObject: JQueryEventObject, selectedFilterItems: any) => {
            let filteredWatchOffers: WatchOffer[] = this._watchOffers;

            filteredWatchOffers = this._filterWatchOffers(selectedFilterItems.items);

            this._deviceList.update(filteredWatchOffers);
            this._updateFilterCounter(filteredWatchOffers.length);
        });

        this.getInjector().getEvent().listen('deviceRequirements@changed', (eventObject: JQueryEventObject, data: any) => {

            if (data.isDeviceDeselect) {
                this._hideAllDeviceRequirementsAlerts();

                return;
            }

            // Find which device requirement warning notification should be shown
            const _deviceRequirementsAlertMapped = this._deviceRequirementsAlertMapping.find( element => {
                const manufacturerMatched = element.manufacturer === data.manufacturer;
                let seriesMatched = element.series === data.series;

                seriesMatched = data.manufacturer !== 'Apple' ? true : seriesMatched;

                return manufacturerMatched && seriesMatched;
            });

            this._hideAllDeviceRequirementsAlerts();

            if (undefined !== _deviceRequirementsAlertMapped) {
                $(_deviceRequirementsAlertMapped.className).removeClass('access');
            }
        });

        this.getInjector().getEvent().listen('watchonly@selected', (eventObject: JQueryEventObject, data: any) => {

            this.setWatchOnly(true);
            let selectedDeviceOffer: WatchOffer;

            const preselectedAtomicDeviceId = this.getInjector().getFlow().getAtomicDeviceId();

            if (undefined !== preselectedAtomicDeviceId) {
                selectedDeviceOffer = this.getActiveWatchOffer();
                if (undefined !== selectedDeviceOffer) {
                    selectedDeviceOffer.type = 'hardwareonly';
                }
            } else {
                selectedDeviceOffer = undefined;
            }

            this.getInjector().getEvent().trigger('multisim@changed', {
                simcardId: undefined
            });

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: selectedDeviceOffer
            });

        });

        this.getInjector().getEvent().listen('multisim@changed', (eventObject: JQueryEventObject, data: any) => {

            const simcardId = data.simcardId;

            if (undefined === simcardId) {
                return;
            }

            this.setWatchOnly(false);

            const simCard = this._simCardRepo.getSimCard(simcardId);
            const offer = this._generalSalesObjectInterface.getSimCardOfferBySimCardId(
                simcardId,
                this._btx,
                this.getInjector().getFlowState().getSalesChannel());

            let atomicDeviceId = this.getInjector().getFlow().getAtomicDeviceId();

            /* unselect device and hide device requirement warning notification on triple sim */
            if (3360 === simcardId) {
                this._hideAllDeviceRequirementsAlerts();
                atomicDeviceId = undefined;
                this.getInjector().getFlow().setAtomicDeviceId(undefined);
            }

            if (atomicDeviceId) {
                const deviceOffer = this.getActiveWatchOffer();
                this._simCardOffer = new SimCardOffer(simCard, offer, [], [], [], deviceOffer);
            } else {
                this._simCardOffer = new SimCardOffer(simCard, offer);
            }

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this._simCardOffer
            });
        });

        this.getInjector().getEvent().listen('multisim-product-detail@openDetailOverlay', (eventObject: JQueryEventObject, data: any) => {
            const deviceOffer: DeviceOffer = data.deviceOffer;

            const viewOverlay = new ViewOverlayDeviceDetails(this._injector, deviceOffer, this._attributeRepo);
            this.getInjector().getOverlay().open(viewOverlay, 'overlay_device_details');
            vf.tabs.init();

            vf['responsive-table'].init();
            // @Todo: Implement when tracking is implemented
            this.getInjector().getEvent().trigger('tracking@Layover', {
                name: 'product details ' + deviceOffer.atomicDevice.name
            });

        });

        this.getInjector().getEvent().listen('multisim-device-tile@atomicIdChanged', (eventObject: JQueryEventObject, data: any) => {

            const atomicDevice: AtomicDevice = data.atomicDevice;

            this.handleDeviceTileAtomicIdChanged(atomicDevice, data.eventType);
        });
    }

    private handleDeviceTileAtomicIdChanged (atomicDevice: AtomicDevice, eventType: string = ''): void {
        let simCard = undefined;
        let offer = undefined;
        let deviceOffer = undefined;
        const preselectedAtomicDeviceId = this.getInjector().getFlow().getAtomicDeviceId();

        if (this._simCardList.isSimCardSelected() && this._isWatchOnlySelected) {
            this.setWatchOnly(false);
        }

        // No device selected
        if (undefined === atomicDevice) {
            // Only set offer if not watch only
            if (!this._isWatchOnlySelected) {
                simCard = this._simCardRepo.getSimCard(this.getInjector().getFlow().getMultiSimId());
                offer = this._generalSalesObjectInterface.getSimCardOfferBySimCardId(
                    simCard.id,
                    this._btx,
                    this.getInjector().getFlowState().getSalesChannel());
                this._simCardOffer = new SimCardOffer(simCard, offer, [], [], [], undefined);

            }
            else {
                this._simCardOffer = undefined;
            }

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this._simCardOffer
            });
            this.getInjector().getFlow().setAtomicDeviceId(undefined);

            return;
        }

        deviceOffer = this.getWatchOffer(atomicDevice);

        if (!this._isWatchOnlySelected) {
            simCard = this._simCardRepo.getSimCard(this.getInjector().getFlow().getMultiSimId());
            offer = this._generalSalesObjectInterface.getSimCardOfferBySimCardId(
                simCard.id,
                this._btx,
                this.getInjector().getFlowState().getSalesChannel());
        }

        this.getInjector().getEvent().trigger('device-tile@offerChanged:' + deviceOffer.atomicDevice.device.id, {
            deviceOffer: deviceOffer
        });

        let _isWatchSelected = this._deviceList.getElement().find('[data-device-id="' + deviceOffer.atomicDevice.device.id + '"]').hasClass('selected');
        if (!_isWatchSelected && 'watch-selected' === eventType) {
            _isWatchSelected = true;
        }

        if (_isWatchSelected) {
            if (undefined !== atomicDevice && atomicDevice.id !== preselectedAtomicDeviceId) {
                this.getInjector().getFlow().setAtomicDeviceId(atomicDevice.id, true);
                this.getInjector().getFlow().lockDevice();
            }
        }

        // replace matching this._deviceOffer
        for (let i = 0; i < this._watchOffers.length; i++) {

            if (this._watchOffers[i].atomicDevice.device.id === deviceOffer.atomicDevice.device.id) {
                // Change watch offer if filters are changed
                this._watchOffers[i] = deviceOffer;
                this.getInjector().getFlow().setWatchOffers(this._watchOffers);
                if (_isWatchSelected) {
                    if (!this._isWatchOnlySelected) {
                            this._simCardOffer = new SimCardOffer(simCard, offer, [], [], [], deviceOffer);

                            this.getInjector().getEvent().trigger('offer@changed', {
                                offer: this._simCardOffer
                            });
                    } else {
                        const watchonlyOffer = this.getActiveWatchOffer();

                        if (undefined !== watchonlyOffer) {
                            watchonlyOffer.type = 'hardwareonly';
                        }
                        this.getInjector().getEvent().trigger('offer@changed', {
                            offer: watchonlyOffer
                        });
                    }
                }

                // no need to loop up to the end while we had  a match
                break;
            }
        }
    }

    protected getWatchOffer (atomicDevice: AtomicDevice): WatchOffer {

        const vluxOffer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
            atomicDevice.id,
            this.getInjector().getFlowState().getSalesChannel());

        /**
         * Get selected optional services
         */
        const optionalServiceIds = this.getInjector().getFlow().optionalServiceIds.elements;

        return new WatchOffer(
            atomicDevice.getDevice().getAtomicDeviceById(vluxOffer.deviceId),
            vluxOffer,
            optionalServiceIds
        );
    }

    protected getActiveWatchOffer (): WatchOffer {

        let activeOffer: WatchOffer = undefined;

        for (const deviceOffer of this._watchOffers) {

            for (const atomicDevice of deviceOffer.atomicDevice.device.getAtomicDevices()) {
                if (this.getInjector().getFlow().getAtomicDeviceId() === atomicDevice.id) {
                    activeOffer = deviceOffer;
                    if (activeOffer.isDevice()) {
                        activeOffer.setIsDeviceSelected(true);
                    }
                    break;
                }
                else {
                    deviceOffer.setIsDeviceSelected(false);
                }
            }
        }

        return activeOffer;
    }

    protected tracking () {

        if (true === this.getInjector().getOptions().get('debug')) {
            console.log('Multisim Page VVL tracking....');
        }
        // Track page onload
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                simCardOffer: this._simCardOffer,
                deviceOffer: this._simCardOffer,
                pageName: 'produktdetails',
                pageType: 'product listing',
//                flowType: 'multisim:sim card overview',
                customerBaseTariff: this._customer.tariffKiasCode,
                btx: Constants.BTX_MULTISIM
            }
        );
        // if offer changed, run tracking
        this._injector.getEvent().listen('offer@changed', (evt: JQueryEventObject, data: any) => {
           this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                simCardOffer: data.offer,
                deviceOffer: data.offer, // Used because of old code in tracking
                pageName: 'produktdetails',
                pageType: 'product listing',
//                flowType: 'multisim:sim card overview',
                customerBaseTariff: 'muiltsim:' + this._customer.tariffKiasCode,
                btx: Constants.BTX_MULTISIM
            }
        );
        });

    }

    private setVendorFilter ( vendor: string ): void {

        const filterItems = $('.buttons-filter').find('a');
        if (0 < vendor.length) {
            filterItems.each( function ( index ) {
                if (vendor === $(this).data('item')) {
                    $(this).addClass('active');
                    $('.buttons-filter').find('a.btn-filter-item-default').removeClass('active');
                }
            });
        }
    }

    public bind (): void {

        if (true === this.getInjector().getOptions().get('debug')) {
            console.log('Multisim Page VVL bind....');
        }

        this._contractData.bind(this._customer);
        this._simCardList.bind();

        this._watchOffers = this.getWatchOffers();
        this._watchOffer = this.getActiveWatchOffer();

        if (this._watchOffer) {

            const _deviceRequirementsAlertMapped = this._deviceRequirementsAlertMapping.find( element => {
                const manufacturerMatched = element.manufacturer === this._watchOffer.atomicDevice.attr.vendor;
                let seriesMatched = element.series === this._watchOffer.atomicDevice.attr.series;

                seriesMatched = this._watchOffer.atomicDevice.attr.vendor !== 'Apple' ? true : seriesMatched;

                return manufacturerMatched && seriesMatched;
            });

            if (undefined !== _deviceRequirementsAlertMapped) {
                $(_deviceRequirementsAlertMapped.className).removeClass('access');
            }
        }

        this._deviceManufacturer = this._getParameter.getLocationSearchParameter('deviceManufacturer');

        if (undefined !== this._deviceManufacturer) {
            this.setVendorFilter(this._deviceManufacturer);
        }

        if (undefined !== this.getInjector().getFlow().getMultiSimId() || null !==  window.location.href.match(/esim+/)) {
            let simCard = undefined;

            if (null !==  window.location.href.match(/esim+/)) {
                this.getInjector().getFlow().setMultiSimId(3362);
                simCard = this._simCardRepo.getSimCard(3362);
            } else {
                simCard = this._simCardRepo.getSimCard(this.getInjector().getFlow().getMultiSimId());
            }

            const offer = this._generalSalesObjectInterface.getSimCardOfferBySimCardId(
                simCard.id,
                this._btx,
                this.getInjector().getFlowState().getSalesChannel());
            this._simCardOffer = new SimCardOffer(simCard, offer, [], [], [], this._watchOffer);

            this.getInjector().getEvent().trigger('multisim@changed', {
                simcardId: this._simCardOffer.simCardId
            });
        }

        this._pricebox.bind(this._simCardOffer);

        if (undefined !== this._ctas) {
            this._ctas.bind(this._simCardOffer);
        }

        this._deviceList.bind(this._watchOffers);

        let filterCounter: number = 0;
        if (this._deviceManufacturer) {
            const filteredWatchOffers = this._filterWatchOffers([this._deviceManufacturer as DeviceFilterValue]);
            this._deviceFilter.setDefaultDeviceManufacturer(this._deviceManufacturer as DeviceFilterValue);
            this._deviceList.update(filteredWatchOffers);
            filterCounter = filteredWatchOffers.length;
        } else {
            filterCounter = this._watchOffers.length;
        }

        this._updateFilterCounter(filterCounter);

        this._deviceFilter.bind();

        this.render();
        this.events();

        this.getInjector().getLoadingIndicator().hide();
        this.tracking();
    }

    protected createCtas (): FlowCtas {
        return new ProductDetailMultisimCtas(this._injector);
    }

}

/**
 * @module SalesFlow/core
 */

import Routing from 'router/shared/routing';
import Storage from 'core/storage';
import Options from 'core/options';
import Event from 'core/event';

import {SubscriptionGroupName, SalesChannelName} from 'core/ids';
import {CoreFlowState} from 'core/core-flow-state';
import Injector from 'core/injector';
import {Constants} from 'core/constants';

/**
 * Get and set flow wide things (e.g. selected subscription and device ids) and persit flow state to sessionstorage
 *
 * @TODO Split vvl and bnt flow
 */
export class CoreEvolvedFlowState extends CoreFlowState {

    protected _flowStateType = 'Curiosity';

    protected _storageName = 'curiosity_flow';

    private _salesChannel: SalesChannelName;

    constructor (injector: Injector) {

        super(injector);

        // @TODO Why does this make sense?
        // When the sales channel is soho then if it is undefined get it from default sales channel because
        // for consumer and young the default is the same => 'consumer'
        // if sales channel is young or consumer then we are setting the sales channel through subscription group
        if (undefined === this._salesChannel && (Constants.SALESCHANNEL_SOHO === this.getOptions().get('default_sales_channel') || Constants.SALESCHANNEL_FAMILY_FRIENDS === this.getOptions().get('default_sales_channel'))) {
            this._salesChannel = this.getOptions().get('default_sales_channel');
        }

        this.loadFromStorage();

        if (undefined === this._subscriptionGroup) {
            this._subscriptionGroup = this.getOptions().get('default_subscription_group');
        }

        this.setFirstFlow();

        /**
         * Data has it's own element in storage
         */
        if (Constants.OrderType_Data === this.getOrderType()) {
            this._storageName = 'curiosity_flow_data';
        }

        this.log();

    }

    protected mapFlowToStringableObject (): any {

        // @TODO Should not be any
        const data: any = super.mapFlowToStringableObject();

        data.salesChannel = this._salesChannel;

        return data;

    }

    protected mapJsonFromStringToFlowState (stored: any): void {

        super.mapJsonFromStringToFlowState(stored);
        // TODO: in stored and in super.mapJsonFromStringToFlowState() the salesChannel is not included!!!!!
        // if nothing is stored, this._salesChannel will be overwritten with undefined and the flow will break !!!!
        this._salesChannel = stored.salesChannel;
        this._subscriptionGroup = stored.salesChannel;

    }

    public getSubscriptionGroup (): SubscriptionGroupName {

        return this._salesChannel;

    }

    public setSalesChannel (salesChannel: SalesChannelName, suppressEvent = false, resetFlowData = true): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (salesChannel === this._salesChannel) {
            return;
        }

        this._salesChannel = salesChannel;

        if (true === resetFlowData) {
            this.resetDedicatedFlowData(true); // INC-14843
        }

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        // @TODO Trigger events here is very implicit
        this.getEvent().trigger('SalesChannelName@changed', salesChannel);

    }

    public getSalesChannel (): SalesChannelName {

        return this._salesChannel;

    }

    public setSubscriptionGroup (subscriptionGroup: SubscriptionGroupName, suppressEvent = false, resetFlowData = true): void {

        console.trace();

    }

}

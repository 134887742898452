/**
 * @module SalesFlow/router
 */

import Routing from '../shared/routing';

import {RouterEvolvedVvlXSell} from 'router-evolved/vvl/router-evolved--vvl--x-sell';

import Customer from 'shopbackend/customer';
import Nba from 'shopbackend/nba';
import NbaFlagCheck from 'helper/nba-helper';

import {RouterEvolvedVvlRecommendation} from 'router-evolved/vvl/router-evolved--vvl--recommendation';
import {RouterEvolvedVvlDeviceOverview} from 'router-evolved/vvl/router-evolved--vvl--device-overview';
import {Constants} from 'core/constants';
import { RouterEvolvedVvlWinback } from 'router-evolved/vvl/router-evolved--vvl-winback-landing-page';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class VvlRouting extends Routing {

    protected shouldRedirect: boolean = false;
    protected redirectUrl: string = '';
    protected salesChannel = this.getInjector().getOptions().get('default_sales_channel');

    protected _validPages: string[] = [

        'vvl_recommendation',
        'vvl_tariff_device',
        'vvl_x_sell',
        'vvl_winback_landing_page'
    ];

    protected doSomethingBeforeRouterAreCreated (): void {}

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();

        const currentStep = this.getCurrentPage();

        const forApp: boolean = this._injector.getGetParameter().getForAppGetParam();

        const nba: Nba = new Nba();

        nba.getData(forApp, 'contractprolongation', 'all').then((customer: Customer) => {

          /**
           * [CO-9456] Show the personalized text depending on the loged in user
           * Only relevant for VVL gigakombi or winback customers (non Family and Friends)!
           */
            const nbacheckflag: NbaFlagCheck = new NbaFlagCheck(customer, this.salesChannel);

            nbacheckflag.updateViewForIdentifiedUser();

            /**
             * Check if the customer is allowed to use the new VVL flow and redirects to the correct page
             */
            this.redirectTo(customer);

            if (true === this.shouldRedirect) {
                const subscriptionIdGetParam: number = this._injector.getGetParameter().getSubscriptionIdGetParam();

                if (undefined !== subscriptionIdGetParam) {
                    this.redirectUrl += (0 >   this.redirectUrl.indexOf('?') ? '?' : '&') + 'tariffId=' + subscriptionIdGetParam;
                }

                const deviceIdGetParam: number = this._injector.getGetParameter().getDeviceIdGetParam();

                if (undefined !== deviceIdGetParam) {
                    this.redirectUrl += (0 >   this.redirectUrl.indexOf('?') ? '?' : '&') + 'deviceId=' + deviceIdGetParam;
                }

                window.location.href = this.redirectUrl;

                // @TODO find a solution that make mor obvoius that the following code will not be executed when shouldRedirect

            }
            // @TODO needs to be refactored
            if (false === this.shouldRedirect) {
                this.getInjector().getFlow().setCustomer(customer);

                if (customer.hasGigakombiCase) {
                    $('.tariff-module-tile[data-gigakombi="false"]').remove();
                } else {
                    $('.tariff-module-tile[data-gigakombi="true"]').remove();
                }

                this.resolveRouter(deferred, currentStep);
            }

        }, (error) => {
            if (401 === error.status) {
                const redirectUrl = document.location.pathname + decodeURIComponent(document.location.search);
                window.location.href = '/shop/authentifizierung.html?btype=vvl&goto=' + this.getRedirectUrlWithAffiliateParameters(redirectUrl);
            } else {
                deferred.reject();
            }
        });

        return deferred.promise();

    }

    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {

        if ('vvl_recommendation' === currentStep) {

            deferred.resolve(new RouterEvolvedVvlRecommendation(this._injector, this._skeletonService));

        } else if ('vvl_tariff_device' === currentStep) {

            deferred.resolve(new RouterEvolvedVvlDeviceOverview(this._injector, this._skeletonService));

        } else if ('vvl_x_sell' === currentStep) {

            deferred.resolve(new RouterEvolvedVvlXSell(this._injector));

        }

    }

    /**
     * Check if the customer is allowed to use the new VVL flow
     * If not, check if the customer is eligible for inlife
     * If not redirect to related slotmachine or authentication page
     * Created separately after [DIPO-1282], complexity of getrouter was to high.
     */
    protected redirectTo (customer: Customer) {
        const currentStep = this.getCurrentPage();
        if (!customer.isVVLEligible) {
            this.shouldRedirect = true;
            if (customer.isInlifeEligible) {
                this.redirectUrl = this._injector.getOptions().get('consumer_default_inlife_recommendation_url');
            } else {
                this.redirectUrl = '/shop/authentifizierung.html?btype=vvl&goto=' + window.location.pathname;

            }
        } // [DIPO-1282] If the current page is the landing page, renders the winback landing page functionallity
         else
            if (Constants.WINBACK_LANDING_PAGE === currentStep)  {
                 this.routeToWinbackLandingPage(customer);
                 this.shouldRedirect = false;
        }
        else {
            // Redirect to RecommendationPage on first visit, if Customer has Private Pricing
            if (true === this.getInjector().getFlow().getVvlFirstVisit()) {
                this.getInjector().getFlow().setVvlFirstVisit(false);
                if (customer.hasPrivatePricing) {
                    this.shouldRedirect = true;
                    this.redirectUrl = this._injector.getOptions().get('vvl_recommendation_url');
                }
            }

            // Redirect to Content Page with Hoteline, if Customer has a Black Tariff or is a Platin Customer (CO-2284)
            if (customer.hasBlackTariff || 'P' === customer.customerSegment) {
                this.shouldRedirect = true;
                this.redirectUrl = this._injector.getOptions().get('consumer_black_and_platin_path');
            }

            // If customer is faf customer
            // Add condition unequals salesChannel [VGOF-5342|INC-21781]
            if (customer.hasFF && Constants.SALESCHANNEL_FAMILY_FRIENDS !=  this.salesChannel && Constants.SALESCHANNEL_SOHO !=  this.salesChannel) {
                this.shouldRedirect = true;
                this.redirectUrl = this._injector.getOptions().get('faf_vvl_tariff_device_url');
                window.sessionStorage.clear();
            }

            /**
             * When a customer with salesChannel => consumer goes into the soho flow redirect him to default consumer vvl page - recommendation (nsf-options)
             * When a customer with salesChannel => soho goes into the consumer flow redirect him to default soho vvl page = recommendation (nsf-options)
             */
            if (!customer.isSohoCustomer && Constants.SALESCHANNEL_SOHO ===  this.salesChannel) {
                this.shouldRedirect = true;
                this.redirectUrl = this._injector.getOptions().get('consumer_default_vvl_recommendation_url');
            } else if (customer.isSohoCustomer && Constants.SALESCHANNEL_CONSUMER ===  this.salesChannel) {
                this.shouldRedirect = true;
                this.redirectUrl = this._injector.getOptions().get('soho_default_vvl_recommendation_url');
            }

            if (false ===  this.shouldRedirect) {
                // If the customer has F&F and he is not a Soho then go to slotmachine
                // This is applied since the VGOF-4833 / INC-17642
            if (customer.isSohoCustomer) {
                    if (customer.hasDataTariff) {
                        // If the customer is SOHO but has a DataTariff then we still redirect him
                        this.shouldRedirect = true;
                        this.redirectUrl = this._injector.getOptions().get('soho_data_slotmachine_path');
                    } else if (customer.hasFF) {
                        // If the customer is SOHO and also has F&F SOC then because of
                        // incident VGOF-4833 / INC-17642 he should stay in NSF Soho flow , no redirection
                        this.shouldRedirect = false;
                    }
                } else if (customer.hasDataTariff) {
                    this.shouldRedirect = true;
                    this. redirectUrl = this._injector.getOptions().get('consumer_data_slotmachine_path');
                } else if ('STD' !== customer.prolongationType && 'ART' !== customer.prolongationType) {
                    this.shouldRedirect = true;
                    this.redirectUrl = this._injector.getOptions().get('consumer_voice_slotmachine_path');
                }
            }

        }
    }

    /**
     * Render the routing of winback landing page if the customer is vvl winback customer
     */
    protected routeToWinbackLandingPage (customer: Customer) {

           const winbackLandingPage = new RouterEvolvedVvlWinback(customer, this.salesChannel);
           winbackLandingPage.createController();
        }

}

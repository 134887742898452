/**
 * @module SalesFlow/core
 */

/**
 * @TODO Don't any for Handlebars, use namespace from @types/handlebars and extend it by partials
 */
declare var Handlebars: any;

import Strings from 'core/strings';

/**
 * Minimal wrapper for Handlebars
 */
export default class Templates {

    constructor (strings: Strings) {

        const allStrings: any = strings.getAll();

        // Add all texts to Handlebar.nsfText to fetch later by seperate text helper
        Handlebars.nsfStrings = {};

        for (const name in allStrings) {
            Handlebars.nsfStrings[name] = allStrings[name];
        }

    }

    /**
     * Returns a string by rendering context object into an hbs (partial or template from app/bundles/sales-flow/handlebars)
     *
     * type: @string partials | templates
     */
    public render (name: string, context: any, type = 'templates'): string {

        if (undefined === Handlebars[type] || undefined === Handlebars[type][name]) {
            const cnsl = console;
            cnsl.log('handlebar missing:' + name);

            return '';
        }

        return Handlebars[type][name](context);

    }

}

/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;

import {ViewEvolvedCtasBase} from 'view-evolved/ctas/vvl/view-evolved--ctas--base';
import {FlowCta} from 'view/ctas/shared/flow-cta';
import Offer from 'view/view/shared/offer/offer';

/**
 * Two buttons in the pricebox module and the very same in costoverview
 * Left: Link to vvl device overview
 * Right: Link to x-sellpage with the recommanded device/subscription
 *
 * @export
 * @class RecommendationVvlCtas
 * @extends {VvlFlowCtas}
 */
export class ViewEvolvedCtasRecommendation extends ViewEvolvedCtasBase {

    /**
     * Default is taking care of buttons in pricebox and costoverview
     */
    protected getElement (): JQuery {

        return $('#nsf-pricebox-buttons-wrap, #nsf-cost-overview-buttons-wrap');

    }

    protected getLeftButton (): FlowCta {

        return new FlowCta(
            ['btn-alt', 'nfs-recommendation-skip', 'nsf-small-button-width'],
            '<span class="recommendation-cta-text-desktop">' + this.getInjector().getStrings().get('vvl.pricebox.reject.recommendation.text1') + '</span><span class="recommendation-cta-text-mobile">' + this.getInjector().getStrings().get('vvl.pricebox.reject.recommendation.text2') + '</span>'
        );
    }

    protected getRightButton (): FlowCta {

        return new FlowCta(
            ['nfs-recommendation-confirm'],
            this.getInjector().getStrings().get('vvl.pricebox.confirm.recommendation.text1') + '<span class="recommendation-cta-confirm-text-handler">' + this.getInjector().getStrings().get('vvl.pricebox.confirm.recommendation.text2') + '</span>'
        );

    }

    /*
    * Functions to return the urls for the CTAs based on a string coming from nsf-options
    * We created these functions in order to override them inside the different sales-chnanel i.e. soho-vvl
    * and deliver different left and right buttons with custom urls, without implementing again the update method
    * when extending the class i.e. for SOHO VVL
    */
    protected getRightCtaUrl (): string {

        return this.getInjector().getOptions().get('vvl_x_sell_url');

    }

    protected getLeftCtaUrl (): string {

        return this.getInjector().getOptions().get('vvl_tariff_device_url');

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        this._left.setHref(
            this.getLeftCtaUrl()
        );

        let rightCtaUrl: string = this.getRightCtaUrl();

        /**
         * Customer already has used all red plus slots, so go to basket
         * skip x-sell page for winback customers
         */
        if (this.getInjector().getFlow().customer.vacantRedPlusSlots < 1
            || this.getInjector().getFlow().customer.isWinbackCustomer) {
            rightCtaUrl = '#proceedToBasket';
        }

        this._right.setHref(
            rightCtaUrl
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._left, this._right], 'partials')
        );

    }

    protected events (): void {

        super.events();

        this._element.on('click', this._left.getSelector(), (evt: JQueryEventObject) => {

            /**
             * Remove recommendation, but keep insurance
             */
            this.getInjector().getFlow().clearSubscriptionId(true);
            this.getInjector().getFlow().setAtomicDeviceId(undefined, true);
            this.getInjector().getEvent().trigger('check@InsuranceSelection');
            this.getInjector().getEvent().trigger('check@coming-from-recommendation');

            // remove "cameFromRecommendationPage" flag
            if (true === this.getInjector().getStorage().hasItem('cameFromRecommendationPage')) {
                this.getInjector().getStorage().removeItem('cameFromRecommendationPage');
            }

            return true;

        });

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            this._injector.getStorage().setItem('cameFromRecommendationPage', 'true');

            if ('#proceedToBasket' === this._right.href) {

                evt.preventDefault();

                this.createBasketAndProceed();

                return false;
            }

        });

    }

}

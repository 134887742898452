/**
 * @module SalesFlow/evolved/router
 */

import { Constants } from 'core/constants';

import Injector from 'core/injector';

import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import {SkeletonService} from '../../service/render/skeleton-service';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import { GigakombiMarker } from '@vfde-fe/general-sales-objects';

/**
 * @TODO Route focus id
 */
export abstract class RouterEvolvedSharedBase {

    private _injector: Injector;

    protected _reposSupervisor: ModelEvolvedRepoSupervisor;

    protected _salesChannel: SalesChannelName;

    protected _subscriptionId: number;

    protected _atomicDeviceId: number;

    protected _btx: BusinessTransactionContext;

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        this._injector = injector;

        // @TODO: why here again?
        // this.setSubscriptionGroup();

        if (undefined === skeletonService) {
            skeletonService = new SkeletonService(injector);
        }

        this._skeletonService = skeletonService;

        this._btx = injector.getBtx();

        this._reposSupervisor = this.getInjector().getReposSupervisor();

        // salesChanel [consumer, young] must be determined so early while repos depend on it
        this.setSalesChannel();

        this.getInjector().getFlowStateWithSalesChannel().setSalesChannel(this.getSalesChannel(), true);
    }

    public getInjector (): Injector {
        return this._injector;
    }

    public getReposSupervisor () {
        return this._reposSupervisor;
    }

    protected setSalesChannel (): void {

        let salesChannel: SalesChannelName = Constants.SALESCHANNEL_CONSUMER;

        const salesChannelFromOption: string = this.getInjector().getOptions().get('default_sales_channel');

        if (undefined !== salesChannelFromOption ) {
            if (
                Constants.SALESCHANNEL_CONSUMER === salesChannelFromOption
                || Constants.SALESCHANNEL_YOUNG === salesChannelFromOption
                || Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannelFromOption
            ) {
                salesChannel = salesChannelFromOption;
            }
        }

        const salesChannelFromStorage: string = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();

        if (undefined !== salesChannelFromStorage) {

            if (
                Constants.SALESCHANNEL_CONSUMER === salesChannelFromStorage
                || Constants.SALESCHANNEL_YOUNG === salesChannelFromStorage
                || Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannelFromStorage
            ) {
                salesChannel = salesChannelFromStorage;
            }

        }

        const salesChannelFromGet: string = this.getInjector().getGetParameter().getSalesChannelGetParam();

        if (undefined !== salesChannelFromGet) {

            if (Constants.SALESCHANNEL_CONSUMER === salesChannelFromGet || Constants.SALESCHANNEL_YOUNG === salesChannelFromGet) {
                salesChannel = salesChannelFromGet;
            }

        } else {
            // if subscriptionGroup not set, but subscriptionId we can get salesChannel implicit
            const subscriptionIdFromGet: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
            if (undefined !== subscriptionIdFromGet) {
                const isYoungSubscriptionId = [Constants.YoungXS_Id, Constants.YoungS_Id, Constants.YoungM_Id, Constants.YoungL_Id, Constants.YoungXL_Id];
                if (-1 !== isYoungSubscriptionId.indexOf(subscriptionIdFromGet)) {
                    salesChannel = Constants.SALESCHANNEL_YOUNG;
                } else {
                    salesChannel = Constants.SALESCHANNEL_CONSUMER;
                }
            }
        }
        this._salesChannel = salesChannel;

    }

    // @TODO Maybe protect this method
    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    /**
     * Return incoming unvalidated subscriptionIds in order
     * 1) Per option
     * 2) Per storage
     * 3) Per get parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overrule option
     *
     */
    public getSubscriptionIds (): number[] {

        // [GET, Storage, Option]
        const incomingSubscriptionIds: number[] = [];

        let subscriptionIdFromOption: number;

        if (Constants.BTX_REDPLUS === this.getInjector().getBtx()) {
            subscriptionIdFromOption = this.getInjector().getOptions().get('default_' + this.getSalesChannel() + '_redplus_subscription_id');
        } else {
            subscriptionIdFromOption = this.getInjector().getOptions().get('default_' + this.getSalesChannel() + '_subscription_id');
        }

        if (undefined !== subscriptionIdFromOption) {
            incomingSubscriptionIds.push(subscriptionIdFromOption);
        }

        const subscriptionIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

        if (undefined !== subscriptionIdFromStorage) {
            incomingSubscriptionIds.push(subscriptionIdFromStorage);
        }

        const subscriptionIdGetParam: number = this.getInjector().getGetParameter().getSubscriptionIdGetParam();
        if (undefined !== subscriptionIdGetParam) {
            incomingSubscriptionIds.push(subscriptionIdGetParam);
        }

        return incomingSubscriptionIds.reverse();

    }

    /**
     *
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveSubscriptionId (incomingSubscriptionIds: number[]): number {

        const validatedSubscriptionIds = incomingSubscriptionIds.filter((tariffId) => {
            return undefined !== this.getReposSupervisor().getSubscriptionRepo().getSubscription(tariffId);
        });

        if (0 === validatedSubscriptionIds.length) {
            return undefined;
        }

        /**
         * For inlife we just need to check that the tariffId is actually a part of a subscription and return the sub level tariff id. For VVL and Soho, we need the main tariff id.
         */
        if (Constants.BTX_INLIFE === this._btx) {
            return validatedSubscriptionIds[0];
        } else {
            return this.getReposSupervisor().getSubscriptionRepo().getSubscriptionMainId(validatedSubscriptionIds[0]);
        }
    }

    /**
     * Get subscription id -> This can invoked after supervisor is loaded and validating is called, so
     * @TODO Make this callable only when set.
     */
    protected getSubscriptionId (): number {

        return this._subscriptionId;
    }

    /**
     * Get atomicDeviceId -> This can invoked after supervisor is loaded and validating is called, so
     * @TODO Make this callable only when set.
     */
    public getAtomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    // @TODO The if is death long live inheritance
    public getFocusSubscriptionIds (btx: BusinessTransactionContext = Constants.BTX_BNT): any {

        if (Constants.BTX_VVL === btx) {
            return {
                easy: this.getInjector().getOptions().get('default_easy_subscription_id') || 0,
                consumer: this.getInjector().getOptions().get('default_consumer_subscription_id') || 0,
                young: this.getInjector().getOptions().get('default_young_subscription_id') || 0
            };
        } else {
            return {
                easy: this.getInjector().getOptions().get('center_tariff_tile_easy_tariff_id') || this.getInjector().getOptions().get('default_easy_subscription_id') || 0,
                consumer: this.getInjector().getOptions().get('center_tariff_tile_consumer_tariff_id') || this.getInjector().getOptions().get('default_consumer_subscription_id') || 0,
                young: this.getInjector().getOptions().get('center_tariff_tile_young_tariff_id') || this.getInjector().getOptions().get('default_young_subscription_id') || 0
            };
        }
    }

    /**
     * Vlux files that should be loaded in a inherited router into supervisor after it has been initialLoaded
     * @TODO Find a good way to prevent that is invoked before reposSupervisor.initialLoadingDone
     */
    protected abstract loadVluxDataIntoReposSupervisor (): void;

    /**
     * The only async method of a controller should be getting loadReposSupervisor
     * When all xhr are fetched the router creates the controller and most following can be  synchronous
     */
    protected abstract loadReposSupervisor (): JQueryPromise<ModelEvolvedRepoSupervisor>;

    public abstract validateIncoming (): void;

    public abstract createController (): JQueryPromise<any>;

    /**
     * After reading the VLUX JSON Data it has to be send to the General Offer Service
     */
    protected prepareGeneralSalesObject (): void {

        /**
         * get General Sales Object Interface and add loaded vlux data
         */
        const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        // as this router is user in vvl, inlife and redplus, we always have a customer
        const customer = this.getInjector().getFlowState().getCustomer();

        /**
         * pass private pricing information to general sales object
         */
        if (Constants.BTX_INLIFE === this._btx) {
            if (customer.hasPrivatePricing) {
                generalSalesObjectInterface.isPrivatePricing = true;
                generalSalesObjectInterface.privatePricingOfferService.cellcode = customer.inlifeCellcode;
            }
        }

        /**
         * DIPO-1850: Add gigakombi use cases to GSO if user is GK elegible
         */
        if (Constants.BTX_INLIFE === this._btx || Constants.BTX_VVL === this._btx) {
            if ('' !== customer.hasGigakombiCase) {
                generalSalesObjectInterface.isGigakombi = true;
                generalSalesObjectInterface.gigakombiMarker = customer.hasGigakombiCase as GigakombiMarker;
            }
        }

        generalSalesObjectInterface.initOfferServicesEvolved (
            this.getSalesChannel(),
            this._btx,
            this.getReposSupervisor()
        );

    }

}

/**
 * @module SalesFlow/view
 */

import SimCard from 'model/type/simcard';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import WatchOffer from 'view/view/shared/offer/watch-offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import ViewAccessoryOffer from 'view/view/shared/accessory-offer';
import VluxOffer from 'model/type/offer';
import OfferService from 'model/type/offer-service';

export default class SimCardOffer extends SimOnlyOffer {

    public readonly type: string = 'simcard';

    protected _simCard: SimCard;
    protected _offer: VluxOffer;

    protected _monthlyDiscountPrice: number;
    protected _onetimePrice: number;

    protected _watchOffer: WatchOffer;

    constructor (
        simCard: SimCard,
        offer: VluxOffer,
        redPlusOffers: DeviceOffer[] = [],
        optionalServices: number[] = [],
        accessoryOffers: ViewAccessoryOffer[] = [],
        watchOffer: WatchOffer = undefined
    ) {
        super (undefined, offer, [], []);
        this._simCard = simCard;
        this._offer = offer;
        this._watchOffer = watchOffer;

        this._onetimePrice = offer.simPriceOnetime.value;
        this._connectionFee = offer.simPriceOnetime.value;
        this._monthlyDiscountPrice = offer.simPriceMonthly.value;
    }

    get offer (): VluxOffer {
        return this._offer;
    }

    get watchOffer (): WatchOffer {
        return this._watchOffer;
    }

    get simCard (): SimCard {
        return this._simCard;
    }

    get simCardId (): number {
        return this._simCard.id;
    }

    get simCardLabel (): string {
        return this._simCard.label;
    }

    get monthlyDiscountPrice (): number {
        return this._monthlyDiscountPrice;
    }

    get connectionFee (): number {
        return this._connectionFee;
    }
    /**
     * get list of all included services and discounts, that has a benefit for the customer
     */
    get includedServicesAndDiscounts (): any[] {

        let includesServices: OfferService[] = [];

        for (const serviceGroup of this._offer.getIncludedServices()) {

            for (const service of serviceGroup.services) {

                if (true === service.hidden) {
                    continue;
                }

                /**
                 * display only the services the customer doesn't have to pay for....
                 */
                let display = true;
                try {
                    if (0 < service.prices[0].value) {
                        display = false;
                    }
                } catch (e) { }

                if (display) {
                    includesServices.push(service);
                }

            }

        }

        for (const serviceGroup of this._offer.getIncludedDiscounts()) {

            for (const service of serviceGroup.services) {

                /**
                 * @TODO Use vlux hidden attributes in discounts as well
                 */
                if (-1 !== service.label.lastIndexOf('Doppeltes Datenvolumen: ')) {
                    continue;
                }

                includesServices.push(service);

            }

        }

        includesServices = includesServices.sort(function (serviceA: OfferService, serviceB: OfferService) {

            if (serviceA.id < serviceB.id) {
                return -1;
            }

            if (serviceA.id > serviceB.id) {
                return 1;
            }

            return 0;
        });

        const items: any[] = includesServices.map(function (service: OfferService) {
            return {
                id: service.id,
                label: service.label
            };
        });

        return items;

    }

    get onetimePriceWithoutConnectionFee (): number {

        let cumulatedCosts: number = this._onetimePrice;

        if (undefined !== this._watchOffer) {
            cumulatedCosts = cumulatedCosts + this._watchOffer.onetimeDevicePrice;
        }

        return cumulatedCosts;

    }

    get onetimePrice (): number {

        let cumulatedCosts: number = this._onetimePrice;

        if (undefined !== this._watchOffer) {
            cumulatedCosts = cumulatedCosts + this._watchOffer.onetimeDevicePrice;
        }

        return cumulatedCosts;
    }

}

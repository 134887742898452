/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

declare var $: JQueryStatic;

import {Renderable} from '../../renderable';

import WatchOffer from '../../view/shared/offer/watch-offer';

import SlideMeIfYouCan from '../shared/slide-if-you-can';

import DeviceTileMultiSim from './device-tile';

export default class DeviceListMultiSim extends Renderable<WatchOffer[]> {

    protected _viewOffers: WatchOffer[];

    protected _element: JQuery;

    protected _slider: SlideMeIfYouCan;

    protected _deviceTile: DeviceTileMultiSim[];

    protected _tileHbs = 'multisim_device_tile';

    protected _deviceFilter: JQuery;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-product-list');

        this._deviceFilter = $('.buttons-filter');

        this._slider = new SlideMeIfYouCan(
            $('#nsf-product-list-slide'),
            'device',
            injector
        );
    }

    public getElement (): JQuery {
        return this._element;
    }

    public update (devices: WatchOffer[]): void {

        const currentOffers = this._element.find('.device-module-tile');
        const listDeviceIds = [];
        for (let x = 0; x < currentOffers.length; x++) {
            if (undefined !== parseInt(currentOffers[x].getAttribute('data-atomic-device-id'), 10)) {
                listDeviceIds.push(parseInt(currentOffers[x].getAttribute('data-atomic-device-id'), 10));
            }
        }

        for (const device of devices) {
            if (undefined === device) {
                this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"]').addClass('not-combinable');
                continue;
            } else {
                if (this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"]').hasClass('not-combinable')) {
                    this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"]').removeClass('not-combinable');
                    this._slider.update();
                }
            }

            for (let x = 0; x < listDeviceIds.length; x++) {
                if (listDeviceIds[x] === device.atomicDeviceId) {
                    listDeviceIds.splice(x, 1);
                }
            }

            const subscriptionDepended: JQuery = this._element.find('[data-atomic-device-id="' + device.atomicDeviceId + '"] .subscriptionDepended');

            subscriptionDepended.html(
                this.getInjector().getTemplates().render('device_tile_price', device, 'partials')
            );

        }

        if (0 < listDeviceIds.length) {
            for (let x = 0; x < listDeviceIds.length; x++) {
                this._element.find('[data-atomic-device-id="' + listDeviceIds[x] + '"]').addClass('not-combinable');
            }

            this._slider.update();
            this._slider.scrollToFocus();
        }
    }

    public render (devices: WatchOffer[]): string {

        const html: string[] = [];

        for (const device of devices) {
            if (undefined === device) {
                continue;
            }

            html.push(
                this.getInjector().getTemplates().render(this._tileHbs, device)
            );
        }

        const joined: string = html.join('');

        this._element.html(joined);

        if (undefined !== this.getInjector().getFlow().getAtomicDeviceId()) {
            this._element.find('[data-atomic-device-id="' + this.getInjector().getFlow().getAtomicDeviceId() + '"]').addClass('selected');
        }

        return joined;
    }

    public events (): void {

        this._deviceTile = this._viewOffers.map( (deviceOffer: WatchOffer): DeviceTileMultiSim => {

            return new DeviceTileMultiSim(
                this.getElement().find('.device-module-tile[data-device-id="' + deviceOffer.atomicDevice.device.id + '"]'),
                deviceOffer,
                this.getInjector()
            );

        });

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            const atomicDeviceId = data.atomicDeviceId;

            this._element.find('.device-module-tile.selected').removeClass('selected');

            if (undefined === atomicDeviceId) {
                return;
            }

            this._element.find('.device-module-tile[data-atomic-device-id="' + atomicDeviceId + '"]').addClass('selected');

        });

        this.getInjector().getEvent().listen('multisim-product-detail@unselectAllDevices', (eventObject: JQueryEventObject) => {

            this._element.find('.device-module-tile.selected').removeClass('selected');
            this.getInjector().getFlow().setAtomicDeviceId(undefined);
            this.getInjector().getFlow().lockDevice();

        });

        this._deviceFilter.on('click', '.filter-item .btn-filter', (evt) => {

            const filterbox = $(evt.currentTarget).parent().parent();
            const defaultItem = filterbox.find('a.btn-filter-item-default');
            const items = filterbox.find('a');

            if ($(evt.currentTarget).is(defaultItem)) {

                items.each( function ( index ) {
                    if (!$(this).hasClass('btn-filter-item-default')) {
                        if ($(this).hasClass('active')) {
                            $(this).removeClass('active');
                        }
                    }
                });
                if (!defaultItem.hasClass('active')) {
                    defaultItem.addClass('active');
                }

            } else {
                if (!$(evt.currentTarget).hasClass('active')) {
                    $(evt.currentTarget).addClass('active');
                } else {
                    $(evt.currentTarget).removeClass('active');
                }
                if (defaultItem.hasClass('active')) {
                    defaultItem.removeClass('active');
                }
                const activeItems: any[] = [];
                items.each( function ( index ) {
                    if (!$(this).hasClass('btn-filter-item-default')) {
                        if ($(this).hasClass('active')) {
                            activeItems.push($(this).data('item'));
                        }
                    }
                });
                if (0 === activeItems.length) {
                    defaultItem.addClass('active');
                }
            }

            this.getInjector().getEvent().trigger('device-filter@clicked', {
                filterItem: $(evt.currentTarget).data('item')
            });
        });
    }

    public bind (devices: WatchOffer[]): void {

        this._viewOffers = devices;

        this.render(devices);
        this.events();

        this._slider.bind();

        // we need this, otherwise slider is not initialized
        this._slider.update();
        this._slider.updateHeight();

    }

}

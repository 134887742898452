/**
 * @module SalesFlow/view
 */

import {FlowCta} from '../shared/flow-cta';
import {FlowCtas} from '../shared/flow-ctas';

export class BntFlowCtas extends FlowCtas {

    /**
     * In Bnt the left button is always cross link into prolongation
     */
    protected getLeftButton (): FlowCta {

        return new FlowCta(
            ['btn-alt', 'nfs-pricebox-contract'],
            this.getInjector().getStrings().get('pricebox.contract')
        );

    }

    /**
     * On device overview this is the right button. Overwrite when diff
     */
    protected getRightButton (): FlowCta {

        return new FlowCta(
            ['nfs-pricebox-confirm', 'nsf-small-button-width'],
            this.getInjector().getStrings().get('pricebox.confirm.text1') + '<span class="part-one">' + this.getInjector().getStrings().get('pricebox.confirm.text2') + '</span>'

        );

    }

    /**
     * Using subscription and device from flow. ignoring offer here
     */
    protected getContractProlongationURL (): string {

        const deviceOverviewUrl = this.getInjector().getOptions().get('vvl_tariff_device_url');

        const params: string[] = [];

        if (undefined !== this.getInjector().getFlow().getSubscriptionId()) {
            params.push(
                'tariffId=' + this.getInjector().getFlow().getSubscriptionId()
            );
        }

        if (undefined !== this.getInjector().getFlow().getAtomicDeviceId()) {
            params.push('deviceId=' + this.getInjector().getFlow().getAtomicDeviceId());
        }

        return deviceOverviewUrl + '?' + params.join('&');
    }

}

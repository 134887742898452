/**
 * @module SalesFlow/view
 */

import ViewAlert from './alert';
import AlertButton from './alert-button';

export default class ViewAlertInfo extends ViewAlert {

    constructor (alertMessage: string, headline?: string, alertButton?: AlertButton[], HTMLId?: string, additionalClass?: string) {

        super(alertMessage, headline, alertButton, HTMLId, additionalClass);

        this._alertType = 'notification';
        this._alertColor = 'deep-blue';

    }

}

/**
 * @module SalesFlow/evolved/router
 */

import VvlRouting from 'router/vvl/routing';

import Injector from 'core/injector';
import {RouterEvolvedSohoVvlRecommendation} from 'router-evolved/soho-vvl/router-evolved--soho-vvl--recommendation';
import {RouterEvolvedSohoVvlDeviceOverview} from 'router-evolved/soho-vvl/router-evolved--soho-vvl--device-overview';
import {RouterEvolvedSohoVvlXSell} from 'router-evolved/soho-vvl/router-evolved--soho-vvl--x-sell';

export class SohoVvlRouting extends VvlRouting {

    constructor (injector: Injector) {

        super(injector);

    }

    /**
     *
     * @TODO getRouter for every page as overwritable methode instead of this one
     * and the deferred.resolve(this.getRecommendationRouter());
     */
    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {

        if ('vvl_recommendation' === currentStep) {
            deferred.resolve(new RouterEvolvedSohoVvlRecommendation(this._injector));
        }

        if ('vvl_tariff_device' === currentStep) {
            deferred.resolve(new RouterEvolvedSohoVvlDeviceOverview(this._injector));
        }

       if ('vvl_x_sell' === currentStep) {
            deferred.resolve(new RouterEvolvedSohoVvlXSell(this._injector));
       }

    }

}

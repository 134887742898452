/**
 * @module SalesFlow/core
 */
import DeviceOffer from 'view/view/shared/offer/device-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import Offer from 'view/view/shared/offer/offer';

interface DeviceOfferLog {
    atomicDeviceId: number;
    deviceName: string;
    subscriptionId: number;
    subscription: string;
}

export class CoreEvolvedOfferCollection {

    private _activeOffer: Offer;

    private _subscriptionOffers: SimOnlyOffer[] = [];

    private _deviceOffers: DeviceOffer[] = [];

    public setActiveOffer (activeOffer: Offer) {
        this._activeOffer = activeOffer;
    }

    public getActiveOffer (): Offer {
        return this._activeOffer;
    }

    public setSubscriptions (subscriptionOffers: SimOnlyOffer[]) {
        this._subscriptionOffers = subscriptionOffers;

    }

    public getSubscriptions (): SimOnlyOffer[] {
        return this._subscriptionOffers;
    }

    public setDevices (deviceOffers: DeviceOffer[]) {
        this._deviceOffers = deviceOffers;

    }

    public replaceDevice (index: number, deviceOffers: DeviceOffer) {
        this._deviceOffers[index] = deviceOffers;
    }

    public getDevices (): DeviceOffer[] {
        return this._deviceOffers;
    }

    public log () {

        console.log('Ausgewählt: ');

        /*
        if (true !== this.getOptions().get('debug')) {
            return;
        }
        */

        const cnsl = console;

        cnsl.group('Offer collection!');

        cnsl.log('%c' + 'activeOffer', 'font-weight:bold;');

        if (undefined === this._activeOffer) {
            cnsl.log('nichts');
        }
        else if (true === this._activeOffer.isSimOnly()) {
            cnsl.log('Simonly', this._activeOffer.subscriptionName);
        }
        else if (true === this._activeOffer.isDevice()) {
            cnsl.log('DeviceOffer', this._activeOffer.subscriptionName, this._activeOffer.offer.deviceId);
        }

        cnsl.log('%c' + 'subscriptions', 'font-weight:bold;');

        for (const subscription of this._subscriptionOffers) {
            cnsl.log(subscription.subscriptionName);

            if (true === subscription.isDevice()) {
                cnsl.log('with', subscription.offer.deviceId);
            }

        }

        cnsl.log('%c' + 'devices', 'font-weight:bold;');

        const deviceData: DeviceOfferLog[] = [];

        for (const device of this._deviceOffers) {

            // cnsl.log(device.name + ' with ' + device.subscriptionName);

            deviceData.push({
                atomicDeviceId: device.atomicDeviceId,
                deviceName: device.atomicDevice.name,
                subscriptionId: device.subscriptionId,
                subscription: device.subscriptionName
            });

        }

        // cnsl.table(deviceData);

        cnsl.groupEnd();

    }

}


/**
 * @module SalesFlow/evolved/view
 */

declare var $: any;

import {Renderable} from 'view/renderable';
import Customer from 'shopbackend/customer';
import Injector from 'core/injector';
import FoldUpAndDown from 'view/element/shared/fold-up-and-down';

export class ViewEvolvedElementVvlContractDataAccordion extends Renderable<any> {

    protected _customer: Customer;

    protected _element: JQuery;

    constructor (
        customer: Customer,
        injector: Injector
    ) {

        super(injector);

        this._customer = customer;

        this._element = $('#nsf-contract-data');
    }

    public render (): void {
        const foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-contract-data'), this._injector);
        foldUpAndDown.bind();

        const contractData = {
            customer: this._customer,
            introtext: '',
            headline: ''
        };

        if (this._customer.isWinbackCustomer) {
            contractData.introtext = this.getInjector().getStrings().get('vvl.winback.contractdata.introtext');
            contractData.headline = this.getInjector().getStrings().get('vvl.winback.contractdata.headline');
            if (this._customer.isSohoCustomer) {
                contractData.introtext = this.getInjector().getStrings().get('vvl.soho.winback.contractdata.introtext');
                contractData.headline = this.getInjector().getStrings().get('vvl.soho.winback.contractdata.headline');
            }
        }

        else if (this._customer.isGigaKombiCustomer) {
            contractData.introtext = this.getInjector().getStrings().get('vvl.gigakombi.contractdata.introtext');
            contractData.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            if (this._customer.isSohoCustomer) {
                contractData.introtext = this.getInjector().getStrings().get('vvl.soho.gigakombi.contractdata.introtext');
                contractData.headline = this.getInjector().getStrings().get('vvl.soho-contractdata.headline');

            }
        }
        else {
            if ('STD' === this._customer.prolongationType) {
                contractData.introtext = this.getInjector().getStrings().get('vvl.std.contractdata.introtext');
                contractData.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            }
            else if ('ART' === this._customer.prolongationType) {
                contractData.introtext = this.getInjector().getStrings().get('vvl.art.contractdata.introtext');
                contractData.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            }
            if (this._customer.isYoungCustomer) {
                contractData.introtext = this.getInjector().getStrings().get('vvl.young.contractdata.introtext');
                contractData.headline = this.getInjector().getStrings().get('vvl-inlife.contractdata.headline');
            }
            else if (this._customer.isSohoCustomer) {
                contractData.introtext = this.getInjector().getStrings().get('vvl.soho.contractdata.introtext');
                contractData.headline = this.getInjector().getStrings().get('vvl.soho-contractdata.headline');
            }
         }

        this._element.html(this.getInjector().getTemplates().render('contract-data-accordion', contractData));
    }

    public events (): void {

    }

    public bind (): void {
        this.render();
        this.events();
    }
}

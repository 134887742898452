/**
 * @module SalesFlow/evolved/view
 */

import {FlowCta} from 'view/ctas/shared/flow-cta';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';

export class ViewEvolvedCtasBase extends FlowCtas {

    /**
     * In Bnt the left button is always cross link into prolongation
     */
    protected getLeftButton (): FlowCta {

        return new FlowCta(
            ['btn-alt', 'nfs-pricebox-contract'],
            this.getInjector().getStrings().get('pricebox.contract')
        );

    }

    /**
     * On device overview this is the right button. Overwrite when diff
     */
    protected getRightButton (): FlowCta {

        return new FlowCta(
            ['nfs-pricebox-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.text1') + '<span class="part-one">' + this.getInjector().getStrings().get('pricebox.confirm.text2') + '</span>'

        );

    }

}

/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import { Constants } from 'core/constants';
import Injector from 'core/injector';
import {Renderable} from '../../renderable';

import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

import ViewOverlay from '../../view/shared/overlay';

export default class SubscriptionDetailOverlay extends Renderable<any> {

    protected _headline: string;
    protected _elementHtml: string;
    protected _gigakombiDeviceDetailService: GigakombiDeviceDetailService;

    constructor (injector: Injector) {

        super(injector);

        this._headline = '';

        this._elementHtml = '';

    }

    public render (): void {

    }

    protected getSubscriptionDetails (detailUrl: string): JQueryPromise<String> {

        const deferred = $.Deferred<String>();

        $.get(detailUrl)

            .done(function (data) {

                deferred.resolve(data);

            })
            .fail(function () {

                deferred.reject();

            });

        return deferred.promise();

    }

    public handleClickDetailCTA = (evt: JQueryEventObject) => {
        /*
            this.getInjector().getEvent().trigger('pageviewTracking@onload', {
                pageName: 'tarife:tarife details',
                deviceOffer: {},
                overwriteRule: true
            });
            */

        this.getInjector().getEvent().trigger('tracking@Layover', {
            name: 'tariff details ' + this.getTariffTileTitle(evt)
        });

        evt.preventDefault();

        const btn: HTMLLinkElement = evt.currentTarget as HTMLLinkElement;
        const detailUrl = btn.dataset.url || btn.href;

        if ('' !== detailUrl) {

            this.getSubscriptionDetails(detailUrl)
                .done((data: any) => {

                    if (Constants.BTX_GIGAKOMBI === this.getInjector().getBtx()
                        || this.getInjector().getFlow().customer.hasGigakombiCase) {

                        const selSubscriptionId = $(evt.currentTarget).closest('.tariff-module-tile').data('subscription-id');
                        let selectedOfferFromTile = undefined;

                        for (const offer of this.getInjector().getOfferCollection().getSubscriptions()) {
                            if (offer.subscriptionId === selSubscriptionId) {
                                selectedOfferFromTile = offer;
                            }
                        }

                        const gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());
                        data = gigakombiDeviceDetailService.updateDataVolumeInOverlay(data, selectedOfferFromTile);

                    }

                    this._elementHtml = data;

                    const viewOverlay = new ViewOverlay(this._headline, '', this._elementHtml);

                    this.getInjector().getOverlay().open(viewOverlay);

                    /**
                     * tab, table and tooltip modules has to be initialized
                     */
                    vf.tabs.init();
                    vf['responsive-table'].init();
                    vf.tooltip2.init();
                });
        }
    }

    public events (): void {

        $('.tariff-module-tile .tariffDetailLink').on('click', this.handleClickDetailCTA);

    }

    public bind (): void {

        this.render();
        this.events();

    }

    protected getTariffTileTitle (evt: JQueryEventObject): string {
        return $(evt.currentTarget).closest('.tariff-module-tile').find('.title').text().trim();
    }

}

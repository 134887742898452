/**
 * @module SalesFlow/view
 */
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';

declare var $: JQueryStatic;

import Injector from 'core/injector';

import { Renderable } from '../../renderable';

import {SubscriptionGroupName} from 'core/ids';

export interface SubscriptionGroupInterface {
    value: SubscriptionGroupName;
    name: string;
    active: boolean;

}

export default class SubscriptionGroupSwitcher extends AbstractSubscriptionGroupSwitcher {

    private _element: JQuery;

    private _currentSubscriptionGroup: SubscriptionGroupName;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-subscription-group-switcher');

        this._currentSubscriptionGroup = this.getInjector().getFlow().getSubscriptionGroup();

    }

    protected getSubscriptionGroups (): SubscriptionGroupInterface[] {

        return [
            {
                value: 'consumer',
                name: 'Red-Tarife',
                active: false
            },
            {
                value: 'young',
                name: 'Unter 28 Jahren?',
                active: false
            },
            {
                value: 'easy',
                name: 'Flex-Tarif',
                active: false
            }
        ];

    }

    public render (): void {

        let subscriptionGroups = this.getSubscriptionGroups();

        subscriptionGroups = subscriptionGroups.map((subscriptionGroup) => {

            if (this._currentSubscriptionGroup === subscriptionGroup.value) {
                subscriptionGroup.active = true;
            }

            return subscriptionGroup;

        });

        this._element.html(
            this.getInjector().getTemplates().render('subscription_group_switcher', {
                subscriptionGroups: subscriptionGroups

            })
        );
    }

    public events (): void {

        this._element.on('click', '.tariff-group-switcher', (evt: JQueryEventObject) => {

            const $radioButton = $(evt.currentTarget);

            // radio button style: once one star is selected in can not be deselected
            if (true === $radioButton.hasClass('checked')) {
                return;
            }

            this._element.find('.checked').removeClass('checked');

            $radioButton.addClass('checked');

            const label = this._element.find('label[for="' + $radioButton.attr('id') + '"]');

            label.addClass('checked');

            const groupName = $radioButton.attr('value');

            if ('consumer' === groupName || 'young' === groupName || 'easy' === groupName) {

                this.getInjector().getFlow().setSubscriptionGroup(groupName);

            }

        });

    }

}

import {Constants} from 'core/constants';

/**
 * @module SalesFlow/evolved/view
 */
declare var $: JQueryStatic;

import Injector from 'core/injector';
import {SalesChannelName, SubscriptionGroupName} from 'core/ids';
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
import {SwitcherDataInterface} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';

/**
 * @todo refactor this to use the new ViewEvolvedElementSharedTariffgroupSwitcher
 */
export class ViewEvolvedElementVvlSalesChannelSwitcher extends AbstractSubscriptionGroupSwitcher {

    private _element: JQuery;

    private _currentSalesChannel: SalesChannelName;
    private _currentSubscriptionGroup: SubscriptionGroupName;

    constructor (injector: Injector) {

        super(injector);

        console.log('VvlSalesChannelGroupSwitcher');

        this._element = $('#nsf-subscription-group-switcher');

        this._currentSalesChannel = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();
        this._currentSubscriptionGroup = this.getInjector().getFlow().getSubscriptionGroup();

    }

    protected getSwitcherData (): SwitcherDataInterface[] {

        // @TODO  subscriptionGroup:'consumer' should be subscriptionGroup:'red' !

        return [
            {
                salesChannel: Constants.SALESCHANNEL_CONSUMER,
                subscriptionGroup: 'consumer',
                name: 'Red-Tarife',
                active: false
            },
            {
                salesChannel: Constants.SALESCHANNEL_YOUNG,
                subscriptionGroup: 'young',
                name: 'Unter 28 Jahren?',
                active: false
            }
        ];

    }

    public render (): void {

        let switcherData = this.getSwitcherData();

        switcherData = switcherData.map((switcherDataItem) => {

            if (this._currentSalesChannel === switcherDataItem.salesChannel && this._currentSubscriptionGroup === switcherDataItem.subscriptionGroup) {
                switcherDataItem.active = true;
            }

            return switcherDataItem;

        });

        this._element.html(
            this.getInjector().getTemplates().render('sales_channel_switcher', {
                switcherData: switcherData

            })
        );
    }

    private handleSalesChannelNameChanging (salesChannel: SalesChannelName): void {

        this._element.find('.checked').removeClass('checked');

        const activeGroupRadioButton = this._element.find('input[salesChannel="' + salesChannel + '"]');
        activeGroupRadioButton.addClass('checked');

        const label = this._element.find('label[for="' + activeGroupRadioButton.attr('id') + '"]');
        label.addClass('checked');

        const subscriptionGroup = activeGroupRadioButton.attr('subscriptionGroup') as SubscriptionGroupName;

        this.getInjector().getFlowStateWithSalesChannel().setSalesChannel(salesChannel, false, false);

    }

    private handleClick (evt: JQueryEventObject): void {
        const $radioButton = $(evt.currentTarget);

        // radio button style: once one channel is selected in can not be deselected
        if (true === $radioButton.hasClass('checked')) {
            return;
        }

        const salesChannel = $radioButton.attr('salesChannel') as SalesChannelName;
        const subscriptionGroup = $radioButton.attr('subscriptionGroup') as SubscriptionGroupName;

        // filter invalid channels
        if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {

            return undefined;
        }

        // filter out invalid subscriptionGroup
        if ('consumer' !== subscriptionGroup && 'young' !== subscriptionGroup && 'easy' !== subscriptionGroup) {

            return undefined;

        }

        this._injector.getEvent().trigger('SalesChannelName@changing', salesChannel);

    }

    public events (): void {

        this._injector.getEvent().listen('SalesChannelName@changing', (eventObject: JQueryEventObject, salesChannel: SalesChannelName) => {

            if (Constants.SALESCHANNEL_CONSUMER !== salesChannel && Constants.SALESCHANNEL_YOUNG !== salesChannel) {

                return undefined;

            }

            this.handleSalesChannelNameChanging(salesChannel);

        });

        this._element.on('click', '.tariff-group-switcher', (evt: JQueryEventObject) => {

            this.handleClick(evt);

            /**
             * Trigger the listener from vvl/device-overview-controller
             */
            this.getInjector().getEvent().trigger('notification-discounts@toggle');

        });

    }

}

import {Constants} from 'core/constants';

/**
 * @module SalesFlow/evolved/view
 */
import {
    SwitcherDataInterface,
    ViewEvolvedElementSharedTariffgroupSwitcher
} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';

declare var $: JQueryStatic;

/**
 * TariffGroup is a combination of SaleChannel + SubscriptionGroup
 */

export class ViewEvolvedElementBntDataGoTariffGroupSwitcher extends ViewEvolvedElementSharedTariffgroupSwitcher {

    protected getSwitcherData (): SwitcherDataInterface[] {

        return [
            {
                salesChannel: Constants.SALESCHANNEL_CONSUMER,
                subscriptionGroup: Constants.SUBSCRIPTION_GROUP_DATA,
                name: 'Datentarife',
                active: false
            },
            {
                salesChannel: Constants.SALESCHANNEL_SOHO,
                subscriptionGroup: Constants.SUBSCRIPTION_GROUP_DATA,
                name: 'Selbständig?',
                active: false
            }
        ];
    }
}

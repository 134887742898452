/**
 * @module SalesFlow/core
 */

import Routing from 'router/shared/routing';
import Storage from 'core/storage';
import Options from 'core/options';
import Event from 'core/event';
import { Constants } from 'core/constants';

import {SalesChannelName, SubscriptionGroupName, TariffGroupName} from 'core/ids';
import {CoreFlowState} from 'core/core-flow-state';

import {TariffGroupInterface} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import Injector from 'core/injector';
import subscription from 'model/type/subscription';

/**
 * In the beginning, the term "subscriptiongroup" was accidently used for sales channel.
 * But sales channels are: consumer, young, soho and family&friends
 * The subscription-Groups belong to a sales channel
 * consumer: red, easy (and to be precise black, but it's not show separately in slider)
 * young: young
 * soho: red
 */

/**
 * Get and set flow wide things (e.g. selected subscription and device ids) and persit flow state to sessionstorage
 *
 * @TODO Split vvl and bnt flow
 */
export class CoreEvolvedFlowStateSalesChannelSubscriptionGroup extends CoreFlowState {

    protected _flowStateType = 'Curiosity';

    protected _storageName = 'curiosity_flow';

    protected _salesChannel: SalesChannelName;

    protected _isGigakombi: boolean;

    constructor (injector: Injector) {

        super(injector);

        /**
         * set initial Sales Channel. It is overwritten by dedicated controller
         */

        if (undefined === this._salesChannel) {

            this._salesChannel = Constants.SALESCHANNEL_CONSUMER;  /* set consumer as default if it's not set in page */

            const salesChannelFromPage: SalesChannelName = this.getOptions().get('default_sales_channel');

            if (true === this.isValidSalesChannel(salesChannelFromPage)) {
                    this._salesChannel = salesChannelFromPage;
            }

        }

        /**
         * set initial Subscription Group. It is overwritten by dedicated controller
         */

        if (undefined === this._subscriptionGroup) {

            this._subscriptionGroup = 'red';  /* set red as default if it's not set default_sales_channel page */

            const subscriptionGroupFromPage: SubscriptionGroupName = this.getOptions().get('default_subscription_group');

            if (true === this.isValidSubscriptionGroup(subscriptionGroupFromPage)) {
                    this._subscriptionGroup = subscriptionGroupFromPage;
            }

        }

        /**
         * set initial if flow ist gigakombi. It is overwritten by dedicated controller
         */

        if (undefined === this._isGigakombi) {

            this._isGigakombi = false;

        }

        /**
         * Data has it's own element in storage
         */
        if (Constants.OrderType_Data === this.getOrderType()) {
            this._storageName = 'curiosity_flow_data';
        }

        this.loadFromStorage();

        this.setFirstFlow();

        this.log();

    }

    protected mapFlowToStringableObject (): any {

        // @TODO Should not be any
        const data: any = super.mapFlowToStringableObject();

        data.salesChannel = this._salesChannel;
        if (undefined !== this._subscriptionGroup) {
            data.evolvedSubscriptionGroup = this._subscriptionGroup;
        }
        data.isGigakombi = this._isGigakombi;

        return data;

    }

    protected mapJsonFromStringToFlowState (stored: any): void {

        super.mapJsonFromStringToFlowState(stored);

        // TODO: in stored and in super.mapJsonFromStringToFlowState() the salesChannel is not included!!!!!
        // if nothing is stored, this._salesChannel will be overwritten with undefined and the flow will break !!!!
        this._salesChannel = stored.salesChannel;
        this._subscriptionGroup = stored.evolvedSubscriptionGroup;

        this._isGigakombi = stored.isGigakombi;

    }

    /**
     * Overwrites the method from parent as tariffgroup instead of subscription group is used
     */
    public setSubscriptionId (subscriptionId: number, suppressEvent = false): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (subscriptionId === this.getSubscriptionId()) {
            return;
        }

        if (undefined !== subscriptionId) {
            this._hardwareOnly = false;
        }

        if (Constants.Black_Id === subscriptionId) {
            this.resetRedPlusFlowData();
        }

        this._subscriptionIds[this.getTariffGroup()] = subscriptionId;

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        this._event.trigger('subscription@changed', {
            subscriptionId: subscriptionId,
            hardwareOnly: this._hardwareOnly
        });

    }

    /**
     * Overwrites the method from parent as tariffgroup instead of subscription group is used
     */
    public getSubscriptionId (): number {

        if (undefined === this.getTariffGroup()) {
            return undefined;
        }

        return this._subscriptionIds[this.getTariffGroup()];

    }

    public setSalesChannel (salesChannel: SalesChannelName, suppressEvent = false, resetFlowData = true): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (salesChannel === this._salesChannel) {
            return;
        }

        this._salesChannel = salesChannel;

        if (true === resetFlowData) {
            this.resetDedicatedFlowData(true); // INC-14843
        }

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        // @TODO Trigger events here is very implicit
        this.getEvent().trigger('SalesChannelName@changed', salesChannel);

    }

    public getSalesChannel (): SalesChannelName {

        return this._salesChannel;

    }

    public setSubscriptionGroup (subscriptionGroupName: SubscriptionGroupName, suppressEvent = false, resetFlowData = true): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (subscriptionGroupName === this._subscriptionGroup) {

            return;

        }

        this._subscriptionGroup = subscriptionGroupName;

        if (true === resetFlowData) {
            this.resetDedicatedFlowData(true); // INC-14843
        }

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        // @TODO Trigger events here is very implicit
        this.getEvent().trigger('subscriptionGroupName@changed', subscriptionGroupName);

    }

    public getSubscriptionGroup (): SubscriptionGroupName {

        return this._subscriptionGroup;

    }

    public setTariffGroup (salesChannelName: SalesChannelName, subscriptionGroupName: SubscriptionGroupName, suppressEvent = false, resetFlowData = true): void {

        // As a rule of thumb - if nothing changed: Change nothing
        if (subscriptionGroupName === this._subscriptionGroup && salesChannelName === this._salesChannel) {
            return;
        }

        this._salesChannel = salesChannelName;
        this._subscriptionGroup = subscriptionGroupName;

        if (true === resetFlowData) {
            this.resetDedicatedFlowData(true); // INC-14843
        }

        this.saveToStorage();

        if (true === suppressEvent) {
            return;
        }

        // @TODO Trigger events here is very implicit
        const param: TariffGroupInterface = {
            salesChannel: salesChannelName,
            subscriptionGroup: subscriptionGroupName
        };
        this.getEvent().trigger('TariffGroupName@changed', param);

    }

    public getTariffGroup (): TariffGroupName {

        let tariffGroupName: string = this.getSalesChannel();

        if ((Constants.SALESCHANNEL_CONSUMER === this.getSalesChannel() && Constants.SUBSCRIPTION_GROUP_EASY == this.getSubscriptionGroup()) || Constants.SALESCHANNEL_CONSUMER === this.getSalesChannel() && Constants.SUBSCRIPTION_GROUP_IN == this.getSubscriptionGroup()) {
            tariffGroupName = this.getSubscriptionGroup();
        }

        return tariffGroupName as TariffGroupName;

    }

    /**
     * just creates the Object in Store
     */
    public initializeToStore (): void {
        this.saveToStorage();
    }

    public isValidSalesChannel (salesChannel: string): boolean {
        return (Constants.SALESCHANNEL_CONSUMER === salesChannel || Constants.SALESCHANNEL_YOUNG === salesChannel || Constants.SALESCHANNEL_SOHO === salesChannel || Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel);
    }

    public isValidSubscriptionGroup (subscriptionGroup: string): boolean {
        return (Constants.SUBSCRIPTION_GROUP_RED === subscriptionGroup
                || Constants.SUBSCRIPTION_GROUP_EASY === subscriptionGroup
                || Constants.SUBSCRIPTION_GROUP_YOUNG === subscriptionGroup
                || Constants.SUBSCRIPTION_GROUP_SOHO === subscriptionGroup
                || Constants.SUBSCRIPTION_GROUP_IN === subscriptionGroup
                || Constants.SUBSCRIPTION_GROUP_DATA === subscriptionGroup
            );
    }

    public setGigakombi (isGigakombi: boolean): void {
        this._isGigakombi = isGigakombi;
    }

    public getGigakombi (): boolean {
        return this._isGigakombi;
    }

}

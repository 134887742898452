import { API_URLS } from '../constants/api-config';

export interface StrikePriceInput {
    productId: number;
    subscriptionId: number;
}

export class StrikePriceService {

    public randomTransactionId () {
        const arr = '0123456789abcdefghijklmnopqrstuvwxyz';
        const len = 30;
        let transaction_id: string;

        for (let i = len; i > 0; i--) {
            transaction_id +=  arr[Math.floor(Math.random() * arr.length)];
        }

        return transaction_id;
    }

    public getStrikePrice (strikePriceInput: StrikePriceInput[]): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        const apiUrl = API_URLS.STRIKE_PRICE;

        const transactionId = this.randomTransactionId();

        $.ajax({
            url: apiUrl,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Basic VTA3YkZFT0J2UUpmZkR3bDNsV3p5V2ZZdVJOTEFiMlU6WGpyY3Nva2VDSXNVTmN0Ug==',
                'vf-trace-transaction-id': transactionId,
                'vf_ext_bp_id': '1'
            },
            method: 'POST',
            data: JSON.stringify(strikePriceInput)
        }).done(function (success) {

            deferred.resolve(success);

        }).fail(function (error) {

            deferred.reject(error);

        });

        return deferred.promise();
    }

    public getMockStrikePrice (strikePriceInput: StrikePriceInput[]) {

        const deferred = $.Deferred<any>();

        setTimeout(() => {
            deferred.resolve(
                [{productId: 3388, subscriptionId: 271, price: 25.9}, {productId: 3721, subscriptionId: 844, price: null}]
            );
        });

        return deferred.promise();

    }

}

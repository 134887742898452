/**
 * @module SalesFlow/view
 */
import Stepper from '../shared/stepper';

/**
 * Check div#sales-flow-stepper (rendered by middleman or opentext) and resolve current step
 * While sales flow is dispatched based on current step, this class is not part of frontend
 *
 * Animates, too
 */
export default class StepperBntDataGo extends Stepper {

    protected _stepNames: string[] = [
        'device_overview', 'device_detail', 'connectmore'
    ];

    public events (): void {

        this.getInjector().getEvent().listen('stepper@device_detail_url', (eventObject: JQueryEventObject, url: string) => {
            this.setDeviceDetailUrl(url);
        });

        super.events();

    }

}

/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';
import Nba from 'shopbackend/nba';
import Customer from 'shopbackend/customer';
import MultisimProductDetailRouter from 'router/multisim/multisim-product-detail-router';
import {Constants} from 'core/constants';

/**
 * The new sales flow root object
 */
export default class MultisimRouting extends Routing {

    protected _validPages: string[] = [
        'multisim_product_detail',
        'multisim_watch_first'
    ];

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();

        const redirectURL: string =  '/shop/authentifizierung.html?btype=multisim&goto=' + window.location.pathname + encodeURIComponent(window.location.search);

        // check CO-25901 why we do this here
        const value = this.getInjector().getStorage().getItem('bShop_login');
        if ( '1' !== value ) {
            window.location.href = redirectURL;
        }

        const currentStep = this.getCurrentPage();

        const forApp: boolean = this._injector.getGetParameter().getForAppGetParam();

        const nba: Nba = new Nba();

        nba.getData(forApp, Constants.BTX_MULTISIM, 'all').then((customer: Customer) => {
            this.getInjector().getFlow().setCustomer(customer);
            // Go To login is user is not logged in
            if (!customer.isLoggedIn) {
                window.location.href = redirectURL;
            }

            if ('multisim_product_detail' === currentStep || 'multisim_watch_first' === currentStep) {
                deferred.resolve(new MultisimProductDetailRouter(this._injector.getRepos(), this._injector));
            }

        }, function (error) {

            if (401 === error.status) {
                window.location.href = redirectURL;
            } else {
                deferred.reject();
            }
        });

        return deferred.promise();
    }
}

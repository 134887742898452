/**
 * @module SalesFlow/evolved/view
 */

import {TariffGroupName} from 'core/ids';
import {ViewEvolvedCtasSharedFlowCta} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-cta';
import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';

export class ViewEvolvedCtasBntDataGoBase extends ViewEvolvedCtasSharedFlowCtas {

    /**
     * No Contract Prolongation in DataGo anymore
     */
    protected getLeftButton (): ViewEvolvedCtasSharedFlowCta {

        return undefined;

    }

    /**
     * On device overview this is the right button. Overwrite when diff
     */
    protected getRightButton (): ViewEvolvedCtasSharedFlowCta {

        return new ViewEvolvedCtasSharedFlowCta(
            ['nfs-pricebox-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.text1') + '<span class="part-one">' + this.getInjector().getStrings().get('pricebox.confirm.text2') + '</span>'

        );

    }

}

import { SalesChannelName, BusinessTransactionContext } from 'core/ids';

import GeneralBasketServiceInterface from './general-basket-service-interface';

import * as ŃsfOffer from 'view/view/shared/offer/offer';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

import WatchOffer from 'view/view/shared/offer/watch-offer';
import SimCardOffer from 'view/view/shared/offer/simcard-offer';
import { Constants } from 'core/constants';

/**
 * @module SalesFlow/shopbackend
 */

export default class GeneralBasketServiceInterfaceMultiSIM extends GeneralBasketServiceInterface {

    constructor (salesChannel: SalesChannelName, forApp: boolean, forAppConnection: boolean) {

        super (salesChannel, forApp, forAppConnection);

    }

    /**
     * Main offer is the NSF View Offer Type (SimOnlyOffer, DeviceOffer, HardwareOnlyOffer) that
     * includes every information we need. Red+ Items, Accessory Items, optional services ....
     * @param mainOffer
     * @param salesChannel
     * @param generalSalesObjectInterface
     */
    public executeOffer (mainOffer: ŃsfOffer.default, salesChannel: SalesChannelName, generalSalesObjectInterface: GeneralSalesObjectInterface): JQueryPromise<any> {

        this.init();

        this._salesChannel = salesChannel;
        this._generalSalesObjectInterface = generalSalesObjectInterface;

        // First step: empty Basket
        this.clear();

        if (mainOffer instanceof WatchOffer) {

            /**
             * Hardware Only
             */

            this._NSF_Data.mainOffer = mainOffer;
            this.generateMainOfferFromNSFData();

            // SIM plus Hardware
            this._basketFactory.putHardwareOnlyOfferIntoBasket(
                this._GSO_Data.mainOffer
            );

        } else if (mainOffer instanceof SimCardOffer) {

            let ultracardOffer = null;
            let watchOffer = null;

            const salesChannel: SalesChannelName = mainOffer.offer.salesChannel;

            if (mainOffer.watchOffer) {

                const btxWatchoffer: BusinessTransactionContext = mainOffer.watchOffer.offer.btx;
                const salesChannelWatchoffer: SalesChannelName = mainOffer.watchOffer.offer.salesChannel;

                watchOffer = this._generalSalesObjectInterface.getRawOfferById(
                    mainOffer.watchOffer.offer.offerId,
                    Constants.BTX_HARDWARE,
                    salesChannelWatchoffer);

                ultracardOffer = this._generalSalesObjectInterface.getRawOfferById(
                    mainOffer.offer.offerId,
                    Constants.BTX_MULTISIM,
                    salesChannel);

            } else {

                ultracardOffer = this._generalSalesObjectInterface.getRawOfferById(
                    mainOffer.offer.offerId,
                    Constants.BTX_MULTISIM,
                    salesChannel);

            }

            // SIM plus Hardware
            this._basketFactory.putMultiSIMOfferIntoBasket(
                ultracardOffer,
                watchOffer
            );

        }

        return this.sendBasketToShopBackend();

    }

}

/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

import { Renderable } from '../../renderable';

import {DeviceFilterValue} from 'core/ids';

export default class DeviceFilter extends Renderable<any[]> {

    private _filterItems: DeviceFilterValue[] = [];

    constructor (injector: Injector) {

        super(injector);

        this._filterItems = [];
    }

    public setDefaultDeviceManufacturer (defaultDeviceManufacturer: DeviceFilterValue): void {
        if (defaultDeviceManufacturer) {
            const filterItemIndex = this._filterItems.indexOf(defaultDeviceManufacturer);
            if (filterItemIndex === -1) {
                // if it's not in array - add it
                this._filterItems.push(defaultDeviceManufacturer);
            }

        }
    }

    public getFilterItems (): DeviceFilterValue[] {
        return this._filterItems;
    }

    public render (): void {

    }

    public handleFilterClickEvent (filterItem: DeviceFilterValue): void {
        if (filterItem === 'all') {
            if (this._filterItems.length !== 0) {
                // empty array means 'all'
                this._filterItems = [];
                this.getInjector().getEvent().trigger('deviceFilter@changed', {
                    items: this._filterItems
                });
            }
        } else {
            const filterItemIndex = this._filterItems.indexOf(filterItem as DeviceFilterValue);
            if (filterItemIndex !== -1) {
                // if the item is already in array - remove it
                this._filterItems.splice(filterItemIndex, 1);
            } else {
                // if it's not in array - add it
                this._filterItems.push(filterItem);
            }
            this.getInjector().getEvent().trigger('deviceFilter@changed', {
                items: this._filterItems
            });
        }
    }

    public events (): void {

    }

}

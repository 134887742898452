/**
 * @module SalesFlow/router
 */

import Routing from 'router/shared/routing';

import Customer from 'shopbackend/customer';
import Nba from 'shopbackend/nba';
import {Constants} from 'core/constants';

import {RouterEvolvedInlifeRecommendation} from 'router-evolved/inlife/router-evolved--inlife-recommendation';
import {RouterEvolvedInlifeTariffSelection} from 'router-evolved/inlife/router-evolved--inlife-tariff-selection';

/**
 * The new sales flow root object
 */
/**
 * @internal
 */
export default class InlifeRouting extends Routing {

    protected salesChannel = this.getInjector().getOptions().get('default_sales_channel');

    protected _validPages: string[] = [
        'inlife_recommendation',
        'inlife_tariff_selection'
    ];

    /**
     * CO-3209, This function gets the URL that we will redirect the user to if he is not allowed to enter inlife curiosity flow
     * We redirect Soho, Young and FF users to inlife legacy flow.
     * We redirect black users and users with no allowed tariffs to hotline notification.
     * If the customer has private pricing, we redirect him to recommendation page. Else we redirect him to tariff selection page
     * @param _customer
     */
    private getRedirectURL (_customer: Customer): string {
        let redirectUrl: string = '';
        const customer = _customer;

        /**
         * check if Customer is eligible for Inlife
         * If not, redirect to VVL when eligable
         */
        if (!customer.isInlifeEligible) {

            if (customer.isVVLEligible) {
                redirectUrl = this._injector.getOptions().get('consumer_default_vvl_recommendation_url');
            } else {
                redirectUrl = '/shop/authentifizierung.html?btype=tariffChange&goto=' + window.location.pathname;

                // INCM-144 If Young Customer gets offered RED-Tariffs in allowedTariffs
                if ((customer.isYoungCustomer || 0 === customer.tariffKiasCode.indexOf('YOU'))
                    && Constants.SALESCHANNEL_YOUNG === this.salesChannel) {
                    redirectUrl = this._injector.getOptions().get('inlife_recommendation_url');
                }
            }

        } else {

            if (undefined === typeof customer.allowedTariffs || 0 === customer.allowedTariffs.allowedTariffDefinition.length) {

                /**
                 * really impossible case, but ca happen. so better catch it up
                 */

                redirectUrl = this._injector.getOptions().get('vlux_url_404');

            }

            // Customer no longer eligable for Young Tariffs - redirect to consumer
            if (!customer.isYoungCustomer && 0 === customer.tariffKiasCode.indexOf('YOU')
                && Constants.SALESCHANNEL_YOUNG === this.salesChannel) {
                redirectUrl = this._injector.getOptions().get('inlife_recommendation_url');
            }

            if (customer.isSohoCustomer) {

                /**
                 * Family & Friends
                 * If not consumer - redirect to not allowed page
                 */
                if (Constants.SALESCHANNEL_FAMILY_FRIENDS === this.salesChannel) {
                    redirectUrl = this._injector.getOptions().get('faf_vvl_tariff_not_consumer_url');
                }

            }

            if (customer.hasBlackTariff) {
                redirectUrl = this._injector.getOptions().get('inlife_black_tariff');
            }

            /**
             * When a customer with salesChannel => consumer goes into the soho flow redirect him to default consumer inlife page - recommendation (nsf-options)
             * When a customer with salesChannel => soho goes into the consumer flow redirect him to default soho inlife page = recommendation (nsf-options)
             */
            if (!customer.isSohoCustomer && Constants.SALESCHANNEL_SOHO ===  this.salesChannel) {
                redirectUrl = this._injector.getOptions().get('inlife_recommendation_url');
            } else if (customer.isSohoCustomer && Constants.SALESCHANNEL_CONSUMER ===  this.salesChannel) {
                redirectUrl = this._injector.getOptions().get('soho_default_inlife_recommendation_url');
            }
        }

        return redirectUrl;
    }

    protected resolveRouter (deferred: JQueryDeferred<any>, currentStep: string) {

        if ('inlife_tariff_selection' === currentStep) {
            deferred.resolve(new RouterEvolvedInlifeTariffSelection(this._injector));
        } else {
            deferred.resolve(new RouterEvolvedInlifeRecommendation(this._injector));
        }
    }

    protected getRouter (): JQueryPromise<any> {
        const deferred = $.Deferred();
        const currentStep = this.getCurrentPage();
        const forApp: boolean = this._injector.getGetParameter().getForAppGetParam();
        const nba: Nba = new Nba();

        nba.getData(forApp, 'tariffchange', Constants.SalesChannelGeneralOfferObjectMapping[this.salesChannel].toLocaleLowerCase()).then((customer: Customer) => {
            this._injector.getFlow().setCustomer(customer);
            this._injector.getFlowStateWithSalesChannel().setSalesChannel(this.salesChannel);
            const redirectUrl = this.getRedirectURL(customer);

            if ('' === redirectUrl) {

                if (customer.hasGigakombiCase) {
                    $('.tariff-module-tile[data-gigakombi="false"]').remove();
                } else {
                    $('.tariff-module-tile[data-gigakombi="true"]').remove();
                }

                this.resolveRouter(deferred, currentStep);

            } else {
                window.location.href = redirectUrl;
            }
        }, (error) => {
            if (401 === error.status) {
                const redirectUrl = document.location.pathname + decodeURIComponent(document.location.search);
                window.location.href = '/shop/authentifizierung.html?btype=tariffChange&goto=' + this.getRedirectUrlWithAffiliateParameters(redirectUrl);
            } else {
                deferred.reject();
            }
        });

        return deferred.promise();

    }

}

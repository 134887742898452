/**
 * @module SalesFlow/evolved/model/vlux
 */

import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import {VluxDataLoaderInterface} from 'model-evolved/vlux/model-evolved--vlux--loader-interface';
import { Constants } from 'core/constants';

declare var $: JQueryStatic;
declare var vf: any;

interface LoadingData {
    [index: string]: JQueryPromise<any>;
}

/**
 * Load vlux json data
 */
export class VluxDataSupervisor {

    private readonly _vluxPath: string;

    private _loader: VluxDataLoaderInterface;

    private _queue: LoadingData = {};

    constructor (loader: VluxDataLoaderInterface, vluxPath = '/api-static/ng/ecommerce' ) {

        this._loader = loader;

        this._vluxPath = vluxPath;

    }

    public getQueueLength (): number {

        let queueLength = 0;

        for (const url in this._queue) {

            queueLength += 1;

        }

        return queueLength;

    }

    public load (url: string): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        if (undefined !== this._queue[url]) {

            this._queue[url].done((data) => {

                delete this._queue[url];

                deferred.resolve(data);

            });
        }
        else {

            const checkURL = new RegExp('^(?:[a-z]+:)?//', 'i');
            const isAbsolute = checkURL.test(url);

            if (false === isAbsolute && 'object' === typeof vf && 'object' === typeof vf.devhelper && 'function' == typeof vf.devhelper.serviceUrl) {
                url = vf.devhelper.serviceUrl() + url;
            }

            this._queue[url] = this._loader.get(url).done((data: any) => {

                delete this._queue[url];

                deferred.resolve(data);

            });

        }

        return deferred.promise();

    }

    public getOfferUrl (salesChannel: SalesChannelName, btx: BusinessTransactionContext ): string {

        let offerBTX: string;

        switch (btx) {
            case Constants.BTX_BNT:
            case Constants.BTX_GIGAKOMBI:
                offerBTX = 'newcontract';
                break;
            case Constants.BTX_VVL:
                offerBTX = 'contractprolongation';
                break;
            case Constants.BTX_MULTISIM:
                offerBTX = 'multicard';
                break;
            case Constants.BTX_HARDWARE:
                offerBTX = 'hardware';
                break;
            case Constants.BTX_INLIFE:
                offerBTX = 'tariffchange';
                break;
            default:
                console.warn('BTX is not valid!');
                break;
        }

        return this._vluxPath + '/offers/' + offerBTX + '-online.' + salesChannel + '-offersmobile.json';

    }

    public getVluxPath (): string {
        return this._vluxPath;
    }

}

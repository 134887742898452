/**
 * @module SalesFlow/evolved/router
 */

import {Constants} from 'core/constants';
import {RouterEvolvedVvlRouter} from 'router-evolved/vvl/router-evolved--vvl--router';
import {ControllerEvolvedVvlRecommendation} from 'controller-evolved/vvl/controller-evolved--vvl--recommendation';

/**
 * @TODO don't use injector as dependency
 */
export class RouterEvolvedVvlRecommendation extends RouterEvolvedVvlRouter {

    /**
     * Return incoming unvalidated tarifIds in order
     * 1) Per get parameter
     * 2) Per storage
     * 3) option Get Parameter
     *
     * So when validate and resolve this: Get will overrule storage and storage will overule option
     *
     */
    public getIncomingDeviceIds (): number[] {

        // [GET, Storage, Option]
        const incomingDeviceIds: number[] = [];

        const deviceIdFromStorage: number = this.getInjector().getFlowStateWithSalesChannel().getAtomicDeviceId();

        if (undefined !== deviceIdFromStorage) {
            incomingDeviceIds.push(deviceIdFromStorage);
        }

        const deviceIdGetParam: number = this.getInjector().getGetParameter().getDeviceIdGetParam();
        if (undefined !== deviceIdGetParam) {
            incomingDeviceIds.push(deviceIdGetParam);
        }

        return incomingDeviceIds.reverse();

    }

    /**
     * @TODO This should be only callable when repos are loaded
     * @TODO This should resolve not only if tariff exists, it should also validate that tariff is in correct tariff group
     */
    public resolveDeviceId (incomingDeviceIds: number[]): number {

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
            this.resolveSubscriptionId(this.getSubscriptionIds())
        );

        /**
         * First it looks for deviceId and then for atomicDeviceIds
         * @TODO Ask channel for a valid usecase
         */
        incomingDeviceIds = incomingDeviceIds.map((deviceId) => {

            const checkDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            // Id is not an deviceId
            if (undefined === checkDevice) {
                return deviceId;
            }

            return checkDevice.getAtomicDeviceByIndex(0).id;

        });

        const validatedDeviceIds = incomingDeviceIds.filter((deviceId) => {

            const checkAtomicDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkAtomicDeviceId) {
                return true;
            }

            const checkDeviceId = undefined !== this.getReposSupervisor().getPurchasableDeviceRepo().getDevice(
                deviceId,
                this.getSalesChannel(),
                subscription
            );

            if (true === checkDeviceId) {
                return true;
            }

            return false;

        });

        if (0 === validatedDeviceIds.length) {
            return undefined;
        }

        return validatedDeviceIds[0];

    }

    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(
            this.getSubscriptionIds()
        );

        this._atomicDeviceId = this.resolveDeviceId(
            this.getIncomingDeviceIds()
        );

        // check if recommended Offer exists, otherwise redirect to tariff-device-page
        const result = this.getDefaultPersonalOffer();
        if (undefined === result) {
            this.getInjector().getStorage().setItem('wasOnRecommendationPage', 'false');
            window.location.href = this.getInjector().getOptions().get('vvl_tariff_device_url');
        }
        else {
            this.getInjector().getStorage().setItem('wasOnRecommendationPage', 'true');
        }
    }

    public createController (): JQueryPromise<ControllerEvolvedVvlRecommendation> {

        const deferred = $.Deferred<any>();

        this.loadReposSupervisor().then(() => {

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedVvlRecommendation(
                    this.getSalesChannel(),
                    this._atomicDeviceId,
                    this.getSubscriptionId(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        });

        return deferred.promise();

    }

    private getDefaultPersonalOffer () {

        const customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        const myTariff = customer.tariffKiasCode;
        let ret = {tariffId: -1, deviceId: -1};
        let foundOfferFromMatrix = false;
        const poObj = (<any>window).poObj;

        // If customer has Young tarif and he is not anymore young eligible skip the page
        // because in the Matrix we can not distinguish an eligible young customer
        if (-1 < customer.tariffKiasCode.indexOf('YOU') && false === customer.isYoungCustomer) {
            return undefined;
        } else {
            if ('object' === typeof poObj) {
                for (const tariffKias in poObj) {
                    if (tariffKias === myTariff) {
                        ret = poObj[tariffKias];
                        foundOfferFromMatrix = true;
                        break;
                    }
                }
            }
        }
        // If no recommendation is found in the Matrix then skip the page and don't search inside the offer json file
        if (false === foundOfferFromMatrix) {
            return undefined;
        }

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(ret.tariffId);
        if (undefined !== subscription) {
            const atomicDeviceId = ret.deviceId;
            const atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                atomicDeviceId,
                this.getSalesChannel(),
                subscription
            );

            if (undefined === atomicDevice) {
                return undefined;
            } else {
                this._subscriptionId = subscription.id;
                this._atomicDeviceId = atomicDeviceId;
            }

            return atomicDevice;
        }

        return undefined;

    }

}

/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import Injector from 'core/injector';

import {Renderable} from '../../renderable';

export default class SlideMeIfYouCan extends Renderable<JQuery> {

    private _element: JQuery;

    private _snap: boolean = false;

    private _snapping: boolean = false;

    private _wrapperSelector: string;

    private _itemsSelector: string;

    private _itemSelector: string;

    private _watchOnlySelector: string;

    private _pane: any;

    private _scollPaneApi: any;

    private _initalFocus: JQuery;

    private _itemWidth: number;

    private _timer: any = undefined;

    private _scrollToFocus: boolean = true;

    private _itemCountInScrollPaneDesktopView: number = 3;

    constructor (element: JQuery, selectorPrefix: string, injector: Injector) {

        super(injector);

        this._element = element;

        if (true === this._element.hasClass('snap')) {
            this._snap = true;
        }

        this._wrapperSelector = '.' + selectorPrefix + '-module-tiles-wrapper';
        this._itemsSelector = '.' + selectorPrefix + '-module-tiles';
        this._itemSelector = '.' + selectorPrefix + '-module-tile';

    }

    private isTouchDevice (): boolean {
        return (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent));
    }

    private getSliderWidth (): number {
        return this._element.width();
    }

    private getItemsCount = function (): number {
        return this._element.find(this._itemSelector).not('.hide').not('.not-combinable').length;
    };

    public setScrollToFocus (scrollToFocus: boolean) {
        this._scrollToFocus = scrollToFocus;
    }

    private getItemWidth (): void {

        const count = this.getItemsCount();

        if (count === 1) {
            this._element.find(this._itemsSelector).addClass('one-tile');
        }

        if ('desktop' === vf.util.layout(true)) {

            this._itemWidth = (this.getSliderWidth() / 100 * 33.33333333333);

            if (this._element.find(this._wrapperSelector).hasClass('scroll-pane')) {
                this._element.find(this._itemsSelector).css('width', this._itemWidth * count + 'px');
                this._element.find(this._itemSelector).css('width', this._itemWidth + 'px');
            } else {
                this._element.find(this._itemsSelector).css('width', '100%');
                this._element.find(this._itemSelector).css('width', this._itemWidth + 'px');
                if (count === 1) {
                    this._element.find('.selectionWatchOnly').css('width', this._itemWidth + 30 + 'px');
                }
            }
        }

        if ('tablet' === vf.util.layout(true)) {

            this._itemWidth = (this.getSliderWidth() / 100 * 41.66666666666);
            this._element.find(this._itemsSelector).css('width', this._itemWidth * count + 'px');
            this._element.find(this._itemSelector).css('width', this._itemWidth + 'px');

            if (count > 1) {
                this._element.find('.selectionWatchOnly').css('width', ((this._itemWidth * count) - 16) + 'px');
            } else {
                this._element.find('.selectionWatchOnly').css('width', this._itemWidth + 15 + 'px');
            }
        }

        if ('mobile' === vf.util.layout(true)) {

            this._itemWidth = ((this.getSliderWidth() - 3) / 100 * 83.33333333333);
            this._element.find(this._itemsSelector).css('width', this._itemWidth * count + 'px');
            this._element.find(this._itemSelector).css('width', this._itemWidth + 'px');
        }

        vf['tariff-detail-akko'].setDetailHeight(this._element);

    }

    public scrollToIndex (index: number): void {
        if (undefined !== this._scollPaneApi) {
            const scrollTo: number = index * this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(index).getBoundingClientRect().width;
            if (false === this.isTouchDevice()) {
                this._scollPaneApi.scrollToX(scrollTo, true);
            } else {
                this._element.find('.scroll-pane').scrollLeft(scrollTo);
            }
        }
    }

    public scrollToFocus (): void {
        if (false === this.isTouchDevice()) {
            this.scrollToDesktopFocus();
        } else {
            this.scrollToMobileFocus();
        }
    }

    // ScrollTo selected or first device
    public scrollToDesktopFocus (): void {
        if (undefined !== this._scollPaneApi) {

            const scrollToElement: Element = this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0);
            if (undefined !== scrollToElement) {

                // fix for SubscriptionGroupSwitcher, if more than one SubscriptionGroup is available, the initialFocus must be reset
                const selected: any = this._element.find(this._itemSelector + '.selected').not('.hide').not('.not-combinable');
                let initalFocus = this._initalFocus;
                if (undefined !== selected.get(0)) {
                    initalFocus = selected;
                }

                const index = this._element.find(this._itemSelector).not('.hide').not('.not-combinable').index(initalFocus);

                const scrollTo: number = index * scrollToElement.getBoundingClientRect().width;
                this._scollPaneApi.scrollToX(scrollTo, false);
            }
        }
    }

    // ScrollTo selected or first tile on mobile
    public scrollToMobileFocus (): void {
        const nonHiddenTiles = this._element.find(this._itemSelector).not('.hide').not('.not-combinable');

        if ('undefined' !== typeof nonHiddenTiles.get(0)) {

            // fix for SubscriptionGroupSwitcher, if more than one SubscriptionGroup is available, the initialFocus must be reset
            const selected: any = this._element.find(this._itemSelector + '.selected').not('.hide').not('.not-combinable');
            let initalFocus = this._initalFocus;
            if (undefined !== selected.get(0)) {
                initalFocus = selected;
            }

            const index = this._element.find(this._itemSelector).not('.hide').not('.not-combinable').index(initalFocus);
            const scrollTo: number = index * nonHiddenTiles.get(0).getBoundingClientRect().width;
            this._element.find('.scroll-pane').scrollLeft(scrollTo);

        }
    }

    public scrollToSelectedElement (delay: number = 500): void {

        if (undefined !== this._scollPaneApi) {
            const selected: any = this._element.find(this._itemSelector + '.selected').not('.hide').not('.not-combinable');
            let initalFocus = this._initalFocus;
            if (undefined !== selected.get(0)) {
                initalFocus = selected;
            }

            const index = this._element.find(this._itemSelector).not('.hide').not('.not-combinable').index(initalFocus);
            const scrollTo: number = index * this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width;
            if (false === this.isTouchDevice()) {
                this._scollPaneApi.scrollToX(scrollTo, delay);
            } else {
                this._element.find('.scroll-pane').scrollLeft(scrollTo);
            }
        }
    }

    public showSelectedElement (): void {
        if (this.isTouchDevice()) {
            const selected: any = this._element.find(this._itemSelector + '.selected').not('.hide').not('.not-combinable');
            const currentScrollPosition = this._element.find('.scroll-pane').get(0).scrollLeft;
            let scrollAmount = currentScrollPosition;
            const selectedLeftOffset = selected.position().left;
            const selectedWidth = selected.get(0).getBoundingClientRect().width;

            if (selectedLeftOffset < -1) {
                scrollAmount = currentScrollPosition - selectedWidth;
            } else {
                if ('mobile' === vf.util.layout(true) && selectedLeftOffset > 1) {
                    scrollAmount = currentScrollPosition + selectedWidth;
                } else if ('tablet' === vf.util.layout(true) && selectedLeftOffset >= selectedWidth * 2) {
                    scrollAmount = currentScrollPosition + selectedWidth;
                }
            }

            this._element.find('.scroll-pane').animate({scrollLeft: scrollAmount}, 800);
        }
    }

    public updateHeight (): void {
        let heighest: number;
        this._element.find(this._itemSelector).each((index, item) => {

            if (undefined === heighest || heighest < $(item).outerHeight()) {
                heighest = $(item).outerHeight();
            }

        });

        // first render
        if (0 > heighest) {
            return;
        }

        // fix so the scroll bar is exactly 20 px below the tariff tile
        heighest += 4;

        this._element.find('.jspContainer').height(heighest);

    }

    public updateWidth (): void {
        $(this._itemsSelector).width(this._itemWidth * this.getItemsCount());
        // Fix for mobile, scrollPaneApi not exists in mobile
        if (undefined !== this._scollPaneApi) {
            this._scollPaneApi.reinitialise();
        } else {
            this.scrollToSelectedElement();
        }
    }

    private snapToItem (): void {

        if (undefined !== this._scollPaneApi) {
            this._snapping = true;

            const pos: number = Math.round(this._scollPaneApi.getContentPositionX() / this._itemWidth);

            this._scollPaneApi.scrollToX(pos * this._itemWidth, true);

            window.setTimeout(() => {
                this._snapping = false;
            }, 300);
        }

    }

    private snapToItemMobile (): void {

        this._snapping = true;

        const pos: number = Math.round(this._element.find(this._wrapperSelector).scrollLeft() / this._itemWidth);

        this._element.find(this._wrapperSelector).animate(
            {
                scrollLeft: pos * this._itemWidth
            },
            300,
            () => {
                this._snapping = false;
            }
        );

    }

    /**
     * Sady: This should be be just reinit, but this method is not working as expected
     * So destory if already exist and create with all it's events
     */
    private createScollPane (): void {

        if (undefined !== this._scollPaneApi) {
            this._scollPaneApi.destroy();
        }

        this.getItemWidth();

        this._pane = this._element.find('.scroll-pane');

        if (true === this._snap) {

            this._pane.on('jsp-scroll-x', (event: any, scrollPositionX: any) => {

                if (undefined !== this._timer) {
                    window.clearTimeout(this._timer);
                }

                if (true === this._snapping) {
                    return;
                }

                this._timer = window.setTimeout(() => {
                    this.snapToItem();
                }, 200);

            });

        }

        this._pane.jScrollPane();

        this._scollPaneApi = this._pane.data('jsp');

        if (true === this._scrollToFocus) {

            this.scrollToFocus();
        }

        // Check scroll posiotion on every scroll
        this._pane.on('jsp-scroll-x', (event: any, scrollPositionX: any, isAtLeft: boolean, isAtRight: boolean) => {
            this.checkPercentScrolledX();
        });

        this.checkPercentScrolledX();

        this.updateHeight();

    }

    public update (): void {

        if (false === this.isTouchDevice()) {
            this.createScollPane();
        } else {
            this.getItemWidth();
        }

    }

    // Little helper for more precise rounding
    public precisionRound (numberToRound: number, precision: number): number {
        const factor = Math.pow(10, precision);

        return Math.round(numberToRound * factor) / factor;
    }

    // Handle hiding and showing of the prev an next arrows
    public checkPercentScrolledX (): void {
        if (undefined !== this._scollPaneApi && null !== this._scollPaneApi) {
            if (-0 === this.precisionRound(this._scollPaneApi.getPercentScrolledX(), 4) || isNaN(this._scollPaneApi.getPercentScrolledX())) {
                this._element.find('.row-prev').hide();
            } else {
                this._element.find('.row-prev').show();
            }

            if (0.98 <= this.precisionRound(this._scollPaneApi.getPercentScrolledX(), 4) || isNaN(this._scollPaneApi.getPercentScrolledX())) {
                this._element.find('.row-next').hide();
            } else {
                this._element.find('.row-next').show();
            }
        }
    }

    private setInitalFocus (initalFocus?: JQuery) {

        // no initalFocus set or element itself is hidden
        if (undefined === initalFocus || initalFocus.hasClass('hide') || initalFocus.hasClass('not-combinable')) {

            initalFocus = this._element.find(this._itemSelector).not('.hide').not('.not-combinable').first();

        }

        const selected: any = this._element.find(this._itemSelector + '.selected').not('.hide').not('.not-combinable');

        if (undefined !== selected.get(0)) {
            initalFocus = selected;
        }

        this._initalFocus = initalFocus;

    }

    public render (initalFocus?: JQuery): void {
        const $wrapper = this._element.find(this._wrapperSelector);
        const $prevButton = $('<div class="row-prev">Prev</div>');
        const $nextButton = $('<div class="row-next">Next</div>');

        // Only add buttons to the DOM if they aren't already present (see CO-4629)
        if (0 === this._element.find('.row-prev, .row-next').length) {
            $wrapper.after($prevButton, $nextButton);
        }

        if (this._itemCountInScrollPaneDesktopView >= this.getItemsCount()) {
            $prevButton.hide();
            $nextButton.hide();
        }

        this.getItemWidth();

        this.setInitalFocus(initalFocus);

    }

    public events (): void {

        $(window).on('vf::resize', () => {
            this.getItemWidth();
            if (undefined !== this._scollPaneApi) {
                this._scollPaneApi.reinitialise();
            }
        });

        if (false === this.isTouchDevice()) {

            this._element.on('click', '.row-prev', () => {
                if (undefined === this._scollPaneApi) {
                    const scrollWidth = (this._element.find(this._itemsSelector).position().left * -1) - this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width;
                    this._element.find('.scroll-pane').animate({
                        scrollLeft: scrollWidth
                    }, 500);
                } else {
                    this._scollPaneApi.scrollByX(-1 * this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width, true);
                }
            });

            this._element.on('click', '.row-next', () => {
                if (undefined === this._scollPaneApi) {
                    const scrollWidth = (this._element.find(this._itemsSelector).position().left * -1) + this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width;
                    this._element.find('.scroll-pane').animate({
                        scrollLeft: scrollWidth
                    }, 500);
                } else {
                    this._scollPaneApi.scrollByX(this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width, true);
                }
            });

        } else {

            this.scrollToMobileFocus();

            if (true === this._snap) {

                this._element.find(this._wrapperSelector).on('scroll', () => {

                    if (undefined !== this._timer) {
                        window.clearTimeout(this._timer);
                    }

                    if (true === this._snapping) {
                        return;
                    }

                    this._timer = window.setTimeout(() => {
                        this.snapToItemMobile();
                    }, 200);

                });

            }

            if ('desktop' === vf.util.layout(true)) {
                let paddingLeft = parseInt(this._element.find(this._itemSelector).css('padding-left'), 10);

                if (this._element.find(this._itemsSelector).hasClass('without-pl')) {
                    paddingLeft = 0;
                }

                if (paddingLeft === this._element.find(this._itemsSelector).position().left) {
                    this._element.find('.row-prev').hide();
                }

                if (-1 * (this._element.find(this._itemsSelector).width() - this._element.find('.scroll-pane').width()) === (this._element.find(this._itemsSelector).position().left - paddingLeft)) {
                    this._element.find('.row-next').hide();
                }

                this._element.find('.scroll-pane').on('scroll', () => {

                    if (paddingLeft === this._element.find(this._itemsSelector).position().left) {
                        this._element.find('.row-prev').hide();
                    } else {
                        this._element.find('.row-prev').show();
                    }

                    if (-1 * (this._element.find(this._itemsSelector).width() - this._element.find('.scroll-pane').width()) === (this._element.find(this._itemsSelector).position().left - paddingLeft)) {
                        this._element.find('.row-next').hide();
                    } else {
                        this._element.find('.row-next').show();
                    }

                });

                this._element.on('click', '.row-next', () => {
                    const scrollWidth = (this._element.find(this._itemsSelector).position().left * -1) + this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width;
                    this._element.find('.scroll-pane').animate({
                        scrollLeft: scrollWidth + paddingLeft
                    }, 500);
                });

                this._element.on('click', '.row-prev', () => {
                    const scrollWidth = (this._element.find(this._itemsSelector).position().left * -1) - this._element.find(this._itemSelector).not('.hide').not('.not-combinable').get(0).getBoundingClientRect().width;

                    this._element.find('.scroll-pane').animate({
                        scrollLeft: scrollWidth + paddingLeft
                    }, 500);
                });
            }
        }

    }

    public bind (initalFocus?: JQuery): void {
        this.render(initalFocus);
        this.events();
    }

    public hideButtons (): void {
        this._element.find('.row-prev').hide();
        this._element.find('.row-next').hide();
    }

    /**
     * Hide the buttons instead of removing them from the DOM because of CO-4629
     */
    public unbind (): void {
        this.hideButtons();
    }

}

/**
 * @module SalesFlow/view
 */

import ContractData from 'view/element/shared/contract-data';
import Injector from 'core/injector';
import Customer from 'shopbackend/customer';

declare var $: any;

export default class ContractDataMultisim extends ContractData {

    protected _element: JQuery;

    constructor (injector: Injector) {
        super(injector);
    }

    public render (customer: Customer): void {
        this._element.html(this.getInjector().getTemplates().render('contract-data-recommendation-multisim', customer));
    }

}

/**
 * @module SalesFlow/evolved/router
 */
import Injector from 'core/injector';

import {RouterEvolvedSharedBase} from 'router-evolved/shared/router-evolved--shared--base';
import {Constants} from 'core/constants';
import {SkeletonService} from '../../service/render/skeleton-service';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';

export abstract class RouterEvolvedRedPlusRouter extends RouterEvolvedSharedBase {

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        super (injector);

        if (undefined === skeletonService) {
            skeletonService = new SkeletonService(injector);
        }

        this._skeletonService = skeletonService;
    }

    /**
     * In redplus the sales channel depends on the current status
     * of the customer
     * - consumer   => if hes a regular consumer customer
     * - young      => if he's currently a young customer
     * - soho       => if he's currently a soho customer
     * - family and friends => if he is f&f
     */
    protected setSalesChannel (): void {

        let salesChannel: SalesChannelName;

        const customer = this.getInjector().getFlowStateWithSalesChannel().getCustomer();
        const isYoungCustomer: boolean = customer.isYoungCustomer;
        const isSohoCustomer: boolean = customer.isSohoCustomer;
        const isFamilyAndFrindsCustomer: boolean = customer.isFamilyAndFriendsCustomer;

        if (isYoungCustomer) {
            // even if the customer is youngElegible, he still can have a Red tariff
            const kiasCode = customer.tariffKiasCode || '';
            if (0 <= kiasCode.indexOf('YOU')) {
                salesChannel = 'young';
            } else {
                salesChannel = 'consumer';
            }
        } else if (isSohoCustomer) {
            salesChannel = 'soho';
        } else if (isFamilyAndFrindsCustomer) {
            salesChannel = 'familyfriends';
        } else {
            salesChannel = 'consumer';
        }

        this._salesChannel = salesChannel;

    }

    /**
     * Load VLUX Data
     */
    protected loadVluxDataIntoReposSupervisor (): void {

        const salesChannel: SalesChannelName = this.getSalesChannel();

        /**
         * BTX for redPlusVluxData ist always NewContract. Only when you
         * buy redPlus together with a VVL or BNT, the BTX is SecondCard
         */
        const btx: BusinessTransactionContext = Constants.BTX_BNT;

        const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        /**
         * which Device-Repo is needed depends on the parameter "tariffId":
         *
         * - If the tariffId is Red+ Data, then we need the Data purchasable device repo.
         * - If the tariffId is Red+ Allnet, we need the regular purchasable device repo.
         * - If the tariffId is Red+ Kids, we need the kids purchasable device repo.
         *
         * Unfortunately all Device-Repos have to be loaded here, as the "validateIncoming"
         * has to be called after loading the repos (it does a check on valid subscription)
         */

        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.RedPlus_Allnet_Vlux_File_Extension);
        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.RedPlus_Kids_Vlux_File_Extension);
        this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.RedPlus_Data_Vlux_File_Extension);

        this.getReposSupervisor().loadAttributeRepo();
        this.getReposSupervisor().loadDependencyRepo();

    }

    protected loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getReposSupervisor().initialLoadingDone().done( () => {

            this.loadVluxDataIntoReposSupervisor();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

}

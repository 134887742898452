/**
 * @module SalesFlow/evolved/router
 */
import Injector from 'core/injector';

import { Constants } from 'core/constants';

import {RouterEvolvedBaseClassBase} from 'router-evolved/base-class/router-evolved--base-class--base';
import {SkeletonService} from '../../service/render/skeleton-service';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';
import { ModelEvolvedRepoPurchasableDevice } from 'model-evolved/repo/model-evolved--repo--purchasable-device';

export abstract class RouterEvolvedBntRouter extends RouterEvolvedBaseClassBase {

    protected _skeletonService: SkeletonService;

    constructor (injector: Injector, skeletonService?: SkeletonService) {

        super (injector);

        if (undefined === skeletonService) {
            skeletonService = new SkeletonService(injector);
        }

        this._skeletonService = skeletonService;

    }

    protected getPurchasableDeviceRepo (): ModelEvolvedRepoPurchasableDevice {

        if (Constants.OrderType_Voice === this.getInjector().getFlowState().getOrderType()) {
            return this.getReposSupervisor().getPurchasableDeviceRepo();
        } else {
            return this.getReposSupervisor().getPurchasableTabletRepo();
        }

    }

    /**
     * All three different vvl controller are needing the some data
     * Expect that X-Sell page does not need attributes.
     */
    protected loadVluxDataIntoReposSupervisor (): void {

        const salesChannel: SalesChannelName = this.getSalesChannel();
        const btx: BusinessTransactionContext = this.getInjector().getBtx();
        const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

        if (Constants.OrderType_Data === this.getInjector().getFlowState().getOrderType()) {
            /**
             * in case of DataGo load Tablets and Router
             */
            this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.OrderType_Data);
        } else if ( Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel) {
            /**
             * Family & Friends has it's own devices configured in Mobile Editor
             */
            this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface, Constants.FamilyFriendsType);
        } else {
            /**
             * all others get the general configuration from Mobile Editor
             */
            this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);

        }

        this.getReposSupervisor().loadAttributeRepo();
        this.getReposSupervisor().loadDependencyRepo();
    }

    protected loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getReposSupervisor().initialLoadingDone().done( () => {

            this.loadVluxDataIntoReposSupervisor();

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

}

import {Renderable} from 'view/renderable';

export type NotificationType = 'warning' | 'success' | 'error';
export type NotificationColor = 'orange' | 'green' | 'red';
export type JQueryFunctionName = 'before' | 'after' | 'prepend' | 'append'| 'add';

export interface NotificationDataForHBS {
    notificationType: NotificationType;
    notificationColor: NotificationColor;
    notificationContent: string;
    notificationHeadline: string | undefined;
    toggleViewSelector: string;
    additionalCSSClass: string | undefined;
}

export abstract class NotificationBox extends Renderable<NotificationBox> {

    protected _toggleViewIdentifier: string = 'notification-' + Math.floor((Math.random() * 1000) + 1) ;
    protected _toggleViewSelector: JQuery;

    protected abstract _CSSSelectorAsAnchorInDOM: string;
    protected abstract _positionToInsertNotification: JQueryFunctionName;
    protected abstract _notificationType: NotificationType;
    protected abstract _dataForNotificationHBS: NotificationDataForHBS;
    protected abstract _notificationContent: string;
    protected abstract _notificationHeadline: string | undefined;
    protected abstract _additionalCSSClassForStyling: string | undefined;

    protected setNotificationDataForHBS () {
        const notificationColor: NotificationColor = this.setNotificationColor(this._notificationType);
        this._toggleViewSelector = $('.notification-box[data-hide-and-show-id="' + this._toggleViewIdentifier + '"]');

        this._dataForNotificationHBS = {
            notificationType: this._notificationType,
            notificationColor: notificationColor,
            notificationContent: this._notificationContent,
            notificationHeadline: this._notificationHeadline,
            toggleViewSelector: this._toggleViewIdentifier,
            additionalCSSClass: this._additionalCSSClassForStyling
        };
    }

    protected abstract events (): void;

    protected setNotificationColor (notificationType: NotificationType) {

        const notificationTypeColorMapping: any = {
            success: 'green',
            warning: 'orange',
            error: 'red'
        };

        return notificationTypeColorMapping[notificationType];
    }

    protected render () {

        if (this.shouldRender()) {
            const HBSTemplate = 'notification';
            const notificationAnchor: any = $(document.querySelector(this._CSSSelectorAsAnchorInDOM));
            notificationAnchor[this._positionToInsertNotification](this.getInjector().getTemplates().render(HBSTemplate, this._dataForNotificationHBS));
        }
    }

    private shouldRender (): boolean {

        return (0 === this._toggleViewSelector.length);
    }

    public hideNotificationBox () {
        this._toggleViewSelector.remove();
    }

    public bind () {
        this.render();
        this.events();
    }
}

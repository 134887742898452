/**
 * @module SalesFlow/evolved/router
 */

import {RouterEvolvedVvlRouter} from 'router-evolved/vvl/router-evolved--vvl--router';
import {ControllerEvolvedFamilyFriendsVvlDeviceOverview} from 'controller-evolved/familyfriends-vvl/controller-evolved--familyfriends-vvl--device-overview';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';
import {SalesChannelName, BusinessTransactionContext} from 'core/ids';
/**
 * @TODO don't use injector as dependency
 */
export class RouterEvolvedFamilyfriendsVvlDeviceOverview extends RouterEvolvedVvlRouter {
    public validateIncoming (): void {

        this._subscriptionId = this.resolveSubscriptionId(this.getSubscriptionIds());
    }

    public loadReposSupervisor (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getReposSupervisor().initialLoadingDone().done( () => {

            const salesChannel: SalesChannelName = this.getSalesChannel();
            const btx: BusinessTransactionContext = this.getInjector().getBtx();
            const generalSalesObjectInterface: GeneralSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

            this.getReposSupervisor().loadDependencyRepo();
            this.getReposSupervisor().loadAttributeRepo();
            this.getReposSupervisor().loadPurchasableDeviceRepo(btx, salesChannel, generalSalesObjectInterface);

            this.getReposSupervisor().getChainedPromise().done(() => {

                /**
                 * Send raw VLUX Data to General Offer Service node module so it doesn't has to be loaded twice
                 */
                this.prepareGeneralSalesObject();

                deferred.resolve();

            });

        });

        return deferred.promise();

    }

    public createController (): JQueryPromise<RouterEvolvedVvlRouter> {
        const deferred = $.Deferred<any>();
        this.loadReposSupervisor().then(() => {

            this.validateIncoming();
            this._skeletonService.removeSkeletonLoadingClassNames();

            deferred.resolve(
                new ControllerEvolvedFamilyFriendsVvlDeviceOverview(
                    this.getInjector().getFlowStateWithSalesChannel().customer,
                    this.getSalesChannel(),
                    this.getAtomicDeviceId(),
                    this.getSubscriptionId(),
                    this.getFocusSubscriptionIds(),
                    this.getReposSupervisor(),
                    this.getInjector()
                )
            );
        }, function () {
            deferred.reject();
        });

        return deferred.promise();

    }

}

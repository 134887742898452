/**
 * @module SalesFlow/evolved/view
 */

import Stepper from 'view/element/shared/stepper';

/**
 * Check div#sales-flow-stepper (rendered by middleman or opentext) and resolve current step
 * While sales flow is dispatched based on current step, this class is not part of frontend
 *
 * Animates, too
 *
 * @TODO this vvl stepper is made for sprint review. Rebuild after and to inheritance etc
 */

export class ViewEvolvedElementVvlFafStepper extends Stepper {

    protected _stepNames: string[] = [
        'vvl_tariff_device', 'vvl_x_sell'
    ];

}

import { GeneralOfferService, RedPlusOfferService, GigakombiOfferService, RedPlusTypes, GigakombiMarker, PrivatePricingOfferService } from '@vfde-fe/general-sales-objects';
import Offer from '@vfde-fe/general-sales-objects/dist/app/offer/model/type/offer';
import OfferComponent from '@vfde-fe/general-sales-objects/dist/app/offer/model/type/offer-component';
import OfferServiceGroup from '@vfde-fe/general-sales-objects/dist/app/offer/model/type/offer-service-group';
import { VluxJsonData } from '@vfde-fe/general-sales-objects';

import * as ŃsfOffer from '../../model/type/offer';
import { SalesChannelName, BusinessTransactionContext } from '../../core/ids';
import { Constants } from '../../core/constants';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';
import { VluxJsonOffer, VluxJsonOfferComponent, VluxJsonOfferPrice, VluxJsonOfferContextAttribute, VluxJsonOfferServiceGroup, VluxJsonOfferService } from 'model/vlux-interface';

import Subscription from 'model/type/subscription';

/**
 * @module SalesFlow/core
 */

export default class GeneralSalesObjectInterface {

    private _isGigakombi: boolean = false;
    private _gigakombiMarker: GigakombiMarker;
    private _isPrivatePricing: boolean = false;

    private _isRedPlus: boolean = false;
    private _redPlusMainTariff: number = 0;

    private _generalOfferService: GeneralOfferService;
    private _redPlusOfferService: RedPlusOfferService;
    private _gigakombiOfferService: GigakombiOfferService;
    private _privatePricingOfferService: PrivatePricingOfferService;

    constructor () {

        this._generalOfferService = new GeneralOfferService();

        this._redPlusOfferService = new RedPlusOfferService();

        this._gigakombiOfferService = new GigakombiOfferService();

        this._privatePricingOfferService = new PrivatePricingOfferService();

    }

    private getBTXFromContextAttributes (contextAttributes: VluxJsonOfferContextAttribute[]): BusinessTransactionContext {

        let btx: BusinessTransactionContext = Constants.BTX_BNT;

        const contextAttribute = contextAttributes.filter ( (ca) => {
            return ('btx' === ca.type);
        });

        if (0 <= contextAttribute.length) {
            btx = <BusinessTransactionContext> contextAttribute[0].value;
        }

       return btx;

    }

    private transferToNSFOffer_Components (component: OfferComponent[], vluxJsonOffer: VluxJsonOffer): void {

        for (let i = 0; i < component.length; i++) {

            const vluxJsonOfferComponent: VluxJsonOfferComponent = {
                type: component[i].type,
                internalLabel: component[i].internalLabel,
                refId: component[i].refId.toString(),
                prices: {
                    price: []
                }
            };

            const prices = component[i].prices;
            for (let j = 0; j < prices.length; j++) {
                const vluxJsonOfferPrice: VluxJsonOfferPrice = {
                    value: prices[j].value.toString(),
                    recurrenceUnit: prices[j].recurrenceUnit,
                    recurrenceStart: prices[j].recurrenceStart.toString(),
                    recurrenceEnd: prices[j].recurrenceEnd.toString(),
                    label: prices[j].label,
                    strikePrice: prices[j].strikePrice.toString()

                };
                vluxJsonOfferComponent.prices.price.push (
                   vluxJsonOfferPrice
                );
            }

            vluxJsonOffer.components.component.push(
                vluxJsonOfferComponent
            );

        }

    }

    private transferToNSFOffer_ServiceGroups (serviceGroup: OfferServiceGroup[], selectedServiceIds: number[], vluxJsonOffer: VluxJsonOffer): void {

        for (let i = 0; i < serviceGroup.length; i++) {

            const vluxJsonOfferServiceGroup: VluxJsonOfferServiceGroup = {
                id: serviceGroup[i].id,
                internalLabel: serviceGroup[i].label,
                maxOccurs: serviceGroup[i].maxOccurs.toString(),
                minOccurs: serviceGroup[i].minOccurs.toString(),
                service: []
            };

            const services = serviceGroup[i].services;

            for (let j = 0; j < services.length; j++) {

                /**
                 * if mandatory, just add Service, if it is selected.
                 * In this context, selected doesn't just mean "optional service selected by the user".
                 * It also means mandatory services selected automatically by the system.
                 */
                let useIt = false;
                if ('optional' === serviceGroup[i].selectable ) {
                    // optional Services are always added
                    useIt = true;
                } else {
                    // mandatory Services are only added when they are not kicked out by dependency
                    useIt = 0 <= selectedServiceIds.indexOf(services[j].id);
                }

                if (true === useIt) {

                    const vluxJsonOfferService: VluxJsonOfferService = {
                        id: services[j].id.toString(),
                        prices: {
                            price: []
                        },
                        // don't use dependencies in NSF. They are executed in general sales object
                        dependencies: {
                            dependency: []
                        }
                    };

                    // Add Prices to Service-Prices
                    const prices = services[j].prices;
                    for ( let k = 0; k < prices.length; k++) {
                        const vluxJsonOfferPrice: VluxJsonOfferPrice = {
                            value: prices[k].value.toString(),
                            recurrenceUnit: prices[k].recurrenceUnit,
                            recurrenceStart: prices[k].recurrenceStart.toString(),
                            recurrenceEnd: prices[k].recurrenceEnd.toString(),
                            label: prices[k].label,
                            strikePrice: prices[k].strikePrice.toString()
                        };
                        vluxJsonOfferService.prices.price.push (
                            vluxJsonOfferPrice
                        );
                    }
                    // Add Service to Service-Group
                    vluxJsonOfferServiceGroup.service.push (
                        vluxJsonOfferService
                    );

                }

            }

            if (0 < vluxJsonOfferServiceGroup.service.length) {
                // Add ServiceGroup
                vluxJsonOffer.services.serviceGroup.push(
                    vluxJsonOfferServiceGroup
                );
            }

        }

    }

    private transferToNSFOffer_ContextAttributes (contextAttribute: any[], vluxJsonOffer: VluxJsonOffer): void {

        for (let i = 0; i < contextAttribute.length; i++) {

            const vluxJsonOfferContextAttribute: VluxJsonOfferContextAttribute = {
                value: contextAttribute[i].value,
                type: contextAttribute[i].type
            };

            vluxJsonOffer.contextAttributes.contextAttribute.push(
                vluxJsonOfferContextAttribute
            );

        }

    }

    private transferToNSFOffer (gsoOffer: Offer): ŃsfOffer.default {

        const vluxJsonOffer: VluxJsonOffer = {
            id: gsoOffer.offerId,
            sourceId: gsoOffer.sourceId.toString(),
            sourceLabel: '',
            components: {
                component: []
            },
            contextAttributes: {
                contextAttribute: []
            },
            services: {
                serviceGroup: []
            }
        };

        /**
         * Components
         */
        this.transferToNSFOffer_Components (
            gsoOffer.offerComponents.components,
            vluxJsonOffer
        );

        /**
         * ContextAttributes
         */
        this.transferToNSFOffer_ContextAttributes(
            gsoOffer.vluxData.contextAttributes.contextAttribute,
            vluxJsonOffer
        );

        /**
         * Service-Groups
         */
        const selectedServiceIds = gsoOffer.offerServices.getSelectedServiceIds();
        this.transferToNSFOffer_ServiceGroups (
            gsoOffer.offerServices.serviceGroups,
            selectedServiceIds,
            vluxJsonOffer
        );

        let btx: BusinessTransactionContext = Constants.BTX_BNT;
        if (true === this._isGigakombi) {
            btx = Constants.BTX_GIGAKOMBI;
        } else {
            btx = this.getBTXFromContextAttributes(
                vluxJsonOffer.contextAttributes.contextAttribute
            );
        }

        const ret: ŃsfOffer.default = new ŃsfOffer.default(
            btx,
            vluxJsonOffer
        );

        /**
         * Enrich Data
         */
        if ('undefined' !== typeof gsoOffer.offerCosts.subcriptionPriceMonthly) {
            ret.subcriptionPriceMonthly.value = gsoOffer.offerCosts.subcriptionPriceMonthly.value;
        }

        /**
         * Enrich Service- and discount data
         */
        const offerServices = ret.getServices();
        const offerServicesLen = offerServices.length;
        const gsoOfferServiceGroups = gsoOffer.offerServices.serviceGroups;
        const gsoOfferServiceGroupsLen = gsoOfferServiceGroups.length;

        for (let i = 0; i < offerServicesLen; i++) {

            let matchingGsoServiceGroup;

            // get matching gsoOfferServiceGroup
            for (let k = 0; k < gsoOfferServiceGroupsLen; k++) {

                if (offerServices[i].id === gsoOfferServiceGroups[k].id) {
                    matchingGsoServiceGroup = gsoOfferServiceGroups[k];
                    continue;
                }

            }

            const offerServiceLen = offerServices[i].services.length;
            const matchingGsoServiceGroupServiceLen = matchingGsoServiceGroup.services.length;

            for (let j = 0; j < offerServiceLen; j++) {

                for (let l = 0; l < matchingGsoServiceGroupServiceLen; l++) {

                    if (offerServices[i].services[j].id === matchingGsoServiceGroup.services[l].id) {

                        const matchingService = matchingGsoServiceGroup.services[l];

                        offerServices[i].services[j].setBackendId (matchingService.backendId);
                        offerServices[i].services[j].setReference (matchingService.reference);
                        offerServices[i].services[j].setDataVolume (matchingService.dataVolume);
                        offerServices[i].services[j].setLabel (matchingService.label);
                        offerServices[i].services[j].setHidden (matchingService.hidden);
                        offerServices[i].services[j].setServiceType (matchingService.serviceType);

                    }
                }

            }

            offerServices[i].setType (
                gsoOfferServiceGroups[i].type
            );

        }

        return ret;

    }

    public get generalOfferService (): GeneralOfferService {
        return this._generalOfferService;
    }

    public get redPlusOfferService (): RedPlusOfferService {
        return this._redPlusOfferService;
    }

    public get gigakombiOfferService (): GigakombiOfferService {
        return this._gigakombiOfferService;
    }

    public get privatePricingOfferService (): PrivatePricingOfferService {
        return this._privatePricingOfferService;
    }

    public set isGigakombi (isGigakombi: boolean) {
        this._isGigakombi = isGigakombi;
    }

    public set isPrivatePricing (isPrivatePricing: boolean) {
        this._isPrivatePricing = isPrivatePricing;
    }

    public set gigakombiMarker (gigakombiMarker: GigakombiMarker) {
        this._gigakombiMarker = gigakombiMarker;
    }

    public set isRedPlus (isRedPlus: boolean) {
        this._isRedPlus = isRedPlus;
    }

    public set redPlusMainTariff (redPlusMainTariff: number) {
        this._redPlusMainTariff = redPlusMainTariff;
        this._redPlusOfferService.setMainTariffId(redPlusMainTariff.toString());
    }

    /**
     * This one is used by old not-evolved controllers
     *
     * @param currentSalesChannel
     * @param currentBTX
     * @param vluxData
     * @param offerHardwareJSON send this offer data in case the transaction offers hardware-only
     * @param offerRedPlusJSON send this offer data in case the transaction offers Red+
     */
    public initOfferServices (currentSalesChannel: SalesChannelName, currentBTX: BusinessTransactionContext, vluxData: VluxJsonData) {

        /**
         * current sales channel and BTX in both notations
         */
        const currentSalesChannel_BackendNotation =  Constants.SalesChannelGeneralOfferObjectMapping[currentSalesChannel];
        const currentBTX_BackendNotation = Constants.BtxGeneralOfferObjectMapping[currentBTX];

        /**
         * set basic vlux data to general offer service
         */
        this._generalOfferService.salesChannel = currentSalesChannel_BackendNotation;
        this._generalOfferService.btx = currentBTX_BackendNotation;

        /**
         * send vlux data to general offer service
         */
        this._generalOfferService.setVluxData(vluxData);
        this._redPlusOfferService.setVluxData(vluxData);

        if (Constants.BTX_GIGAKOMBI === currentBTX || true === this._isGigakombi) {
            this._gigakombiOfferService.setVluxData(vluxData);
        }

        if (this._isPrivatePricing) {
            this._privatePricingOfferService.setVluxData(vluxData);
        }

    }

    /**
     * This one is used by evolved controllers
     */
    public initOfferServicesEvolved (currentSalesChannel: SalesChannelName, currentBTX: BusinessTransactionContext, reposSupervispor: ModelEvolvedRepoSupervisor): void {

        /**
         * current sales channel and BTX in both notations
         */
        const currentSalesChannel_BackendNotation =  Constants.SalesChannelGeneralOfferObjectMapping[currentSalesChannel];
        const currentBTX_BackendNotation = Constants.BtxGeneralOfferObjectMapping[currentBTX];

        /**
         * set basic vlux data to general offer service
         */
        this._generalOfferService.salesChannel = currentSalesChannel_BackendNotation;
        this._generalOfferService.btx = currentBTX_BackendNotation;

        /**
         * Basic Data that is always loaded
         */
        const vluxData: VluxJsonData = {
            subscriptionJSON: reposSupervispor.getSubscriptionRepo().rawData,
            discountJSON: reposSupervispor.getDiscountRepo().rawData,
            serviceJSON: reposSupervispor.getServiceRepo().rawData,
            dependenciesJSON: reposSupervispor.getDependencyRepo().rawData
        };

        /**
         * Hardware's not always loaded (e.g. no hardware in tariffchange)
         */
        if (undefined !== reposSupervispor.getDeviceRepo()) {
            vluxData.hardwareJSON = reposSupervispor.getDeviceRepo().rawData;
        }

        /**
         * AccessoryRepo 's not always loaded (e.g. no hardware in tariffchange)
         */
        if (undefined !== reposSupervispor.getAccessoryRepo()) {
            vluxData.accessoryJSON = reposSupervispor.getAccessoryRepo().rawData;
        }

        /**
         * send vlux data to general offer services
         */
        this._generalOfferService.setVluxData(vluxData);
        this._redPlusOfferService.setVluxData(vluxData);

        if (Constants.BTX_GIGAKOMBI === currentBTX || true === this._isGigakombi) {
            this._gigakombiOfferService.setVluxData(vluxData);
        }

        if (this._isPrivatePricing) {
            this._privatePricingOfferService.setVluxData(vluxData);
        }

    }

    public getRawOfferById (offerId: string, btx: BusinessTransactionContext, salesChannel: SalesChannelName): Offer | undefined {

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);
            offer = this._gigakombiOfferService.getOfferById(offerId);

        } else if (this._isRedPlus) {

            const redplusBTX: BusinessTransactionContext = 'bnt';
            this._redPlusOfferService.btx = Constants.BtxGeneralOfferObjectMapping[redplusBTX];
            this._redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];
            offer = this._redPlusOfferService.getOfferById(offerId);

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getOfferById(offerId);

        } else {

            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._generalOfferService.getOfferById(offerId);

        }

        return offer;

    }

    public getOfferById (offerId: string, btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);

            offer = this._redPlusOfferService.getOfferById(offerId);

        } else if (this._isRedPlus) {

                const redplusBTX: BusinessTransactionContext = 'bnt';
                this._redPlusOfferService.btx = Constants.BtxGeneralOfferObjectMapping[redplusBTX];
                this._redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];
                offer = this._redPlusOfferService.getOfferById(offerId);

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getOfferById(offerId);

        } else {

            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._generalOfferService.getOfferById(offerId);

        }

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    /**
     * Returns Device-Offer in NSF-Offer-Type structure by atomicDevice, Main-Subscription, btx and salesChannel
     *
     * @param atomicDeviceId
     * @param subscriptionId
     * @param btx
     * @param salesChannel
     */
    public getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (atomicDeviceId: number, subscriptionId: number, btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);
            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._gigakombiOfferService.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(atomicDeviceId, subscriptionId);

        } else if (this._isRedPlus) {

            const redplusBTX: BusinessTransactionContext = 'bnt';
            this._redPlusOfferService.btx = Constants.BtxGeneralOfferObjectMapping[redplusBTX];
            this._redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._redPlusOfferService.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(atomicDeviceId, subscriptionId);

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(atomicDeviceId, subscriptionId);

        } else {

            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._generalOfferService.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(atomicDeviceId, subscriptionId);

        }

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    /**
     * Returns SimOnly-Offer in NSF-Offer-Type structure by Main-Subscription, btx and salesChannel
     * @param subscriptionId
     * @param btx
     * @param salesChannel
     */
    public getSimOnlyOfferBySubscriptionId (subscriptionId: number, btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);
            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._gigakombiOfferService.getSimOnlyOfferBySubscriptionId(subscriptionId);

        } else if (this._isRedPlus) {

            if (Constants.RedAllnet_MainId === subscriptionId) {
                return this.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Allnet, salesChannel);
            } else if (Constants.RedData_MainId === subscriptionId) {
                return this.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Data, salesChannel);
            } else {
                return this.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Kids, salesChannel);
            }

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getSimOnlyOfferBySubscriptionId(subscriptionId);

        } else {

            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._generalOfferService.getSimOnlyOfferBySubscriptionId(subscriptionId);

        }

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    public getSimOnlyOfferByAtomicSubscriptionId (atomicSubscriptionId: number, btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);
            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._gigakombiOfferService.getSimOnlyOfferByAtomicSubscriptionId(atomicSubscriptionId);

        } else if (this._isRedPlus) {

            if (Constants.RedAllnet_SimOnlyId === atomicSubscriptionId) {
                return this.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Allnet, salesChannel);
            } else if (Constants.RedData_SimOnlyId === atomicSubscriptionId) {
                return this.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Data, salesChannel);
            } else {
                return this.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Kids, salesChannel);
            }

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getSimOnlyOfferByAtomicSubscriptionId(atomicSubscriptionId);

        } else {

            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._generalOfferService.getSimOnlyOfferByAtomicSubscriptionId(atomicSubscriptionId);

        }

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    /**
     * This method is only used as a fallback in case of VLUX Config Errors.
     * It happened in the past, that VLUX forget to confugure SIMonly for a subscription
     * @param subscriptionId
     * @param btx
     * @param salesChannel
     */
    public getFirstAvailableOfferBySubscriptionId (subscriptionId: number, btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);
            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._gigakombiOfferService.getFirstAvailableOfferForSubscription(subscriptionId);

        } else if (this._isRedPlus) {

            const redplusBTX: BusinessTransactionContext = 'bnt';
            this._redPlusOfferService.btx = Constants.BtxGeneralOfferObjectMapping[redplusBTX];
            this._redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._redPlusOfferService.getFirstAvailableOfferForSubscription(subscriptionId);

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getFirstAvailableOfferForSubscription(subscriptionId);

        } else {

            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._generalOfferService.getFirstAvailableOfferForSubscription(subscriptionId);

        }

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    public getLowestDevicePriceOfferForAtomicId (atomicDeviceId: number, subscriptions: Subscription[], btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        const subscriptionsLength = subscriptions.length;
        const subscriptionIds = [];

        // rerieve all Sub-Ids from all subscriptions
        for (let i = 0; i < subscriptionsLength; i++) {

            const subs = subscriptions[i].getSubsidizations();
            const subsLength = subs.length;

            for (let j = 0; j < subsLength; j++) {
                subscriptionIds.push (subs[j].id);
            }

        }

        let offer;

        if (this._isGigakombi) {

            this._gigakombiOfferService.setGigakombiMarker(this._gigakombiMarker);
            this._gigakombiOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._gigakombiOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._gigakombiOfferService.getLowestDevicePriceOfferForAtomicId (atomicDeviceId, subscriptionIds);

        } else if (this._isRedPlus) {

            const redplusBTX: BusinessTransactionContext = 'bnt';
            this._redPlusOfferService.btx = Constants.BtxGeneralOfferObjectMapping[redplusBTX];
            this._redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._redPlusOfferService.getLowestDevicePriceOfferForAtomicId(atomicDeviceId, subscriptionIds);

        } else if (this._isPrivatePricing) {

            this._privatePricingOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._privatePricingOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer =  this._privatePricingOfferService.getLowestDevicePriceOfferForAtomicId(atomicDeviceId, subscriptionIds);

        } else {
            this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
            this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

            offer = this._generalOfferService.getLowestDevicePriceOfferForAtomicId (atomicDeviceId, subscriptionIds);

        }

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    public getHardwareOnlyOfferByAtomicId (atomicDeviceId: number, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        this._generalOfferService.btx = Constants.BTX_BACKEND_HARDWARE;
        this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

        const offer = this._generalOfferService.getHardwareOnlyOfferByAtomicDeviceId (atomicDeviceId);
        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    public getAccessoryOffer (accessoryId: number): ŃsfOffer.default | undefined {

        const offer = this._generalOfferService.getHardwareOnlyOfferByAtomicDeviceId (accessoryId);
        if (undefined !== offer) {
            return this.transferToNSFOffer (offer);
        }

        return undefined;

    }

    public getSimCardOfferBySimCardId (simCardId: number, btx: BusinessTransactionContext, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        this._generalOfferService.btx = Constants.BtxGeneralOfferObjectMapping[btx];
        this._generalOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

        const offer = this._generalOfferService.getSimOnlyOfferBySIMcardId (simCardId);
        if (undefined !== offer) {
            return this.transferToNSFOffer (offer);
        }

        return undefined;

    }

    /**
     * Returns RedPlusOffer (hardwary-Only or Device-Offer) in NSF-Offer-Type structure
     * by offerId
     * @param offerId
     */
    public getRedPlusOfferById (offerId: string, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        this.redPlusOfferService.btx = Constants.BTX_BACKEND_BNT;
        this.redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

        const offer = this.redPlusOfferService.getOfferById(offerId);

        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

    /**
     * Returns RedPlus SimOnlyOffer in NSF-Offer-Type structure by redplus-type (allnet, data, kids)
     * @param redPlusType
     */
    public getRedPlusSimOnlyOfferByType (redPlusType: RedPlusTypes, salesChannel: SalesChannelName): ŃsfOffer.default | undefined {

        this._redPlusOfferService.btx = Constants.BTX_BACKEND_BNT;
        this._redPlusOfferService.salesChannel = Constants.SalesChannelGeneralOfferObjectMapping[salesChannel];

        const offer = this.redPlusOfferService.getSimOnlyOfferByType(redPlusType);
        if (undefined !== offer) {
            return this.transferToNSFOffer(offer);
        }

        return undefined;

    }

}

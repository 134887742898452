import {BusinessTransactionContext, SalesChannelName} from 'core/ids';

/**
 * Class for adding gigakombi functionality to other flows (e.g. BNT)
 */
export default class BntDeviceFlowService {
    /**
     * Toggle the Promo Pack Q2 2020 Multidivider
     *
     * Teaser should toggle on different Saleschannel
     *
     * @param {salesChannel} SalesChannelName
     */
    public static toggleConsumerOrYoungBanner (salesChannel: SalesChannelName, isHardwareOnly: boolean) {
        switch (true) {
            case (salesChannel === 'consumer' && !isHardwareOnly):
                $('.multi-divider-consumer').show();
                $('.multi-divider-young').hide();
                break;
            case (salesChannel === 'soho' && !isHardwareOnly):
                $('.multi-divider-consumer').show();
                $('.multi-divider-young').hide();
                break;
            case (salesChannel === 'young' && !isHardwareOnly):
                $('.multi-divider-young').show();
                $('.multi-divider-consumer').hide();
                break;
            case (salesChannel === 'familyfriends' && !isHardwareOnly):
                $('.multi-divider-consumer').show();
                $('.multi-divider-young').hide();
                break;
            default:
                $('.multi-divider-young').hide();
                $('.multi-divider-consumer').hide();
                break;
        }
    }

    public static toggleHeadlineWithContract (isHardwareOnly: boolean) {

        const headline = $('.text-only h1').parent().html();

        if (isHardwareOnly) {
            $('.text-only h1').parent().html(headline.replace('mit', 'ohne'));
        } else {
            $('.text-only h1').parent().html(headline.replace('ohne', 'mit'));
        }
    }
}

/**
 * @module SalesFlow/view
 */

import AtomicDevice from 'model/type/atomic-device';

class HousingSize {

    private _value: number;

    constructor (atomicDevice: AtomicDevice) {
        this._value = atomicDevice.attr.housingSize || 0;
    }

    get value (): number {
        return this._value;
    }

}

class ViewHousingSize {

    private _size: HousingSize;
    private _atomicDeviceId: number = undefined;
    private _isSelected: boolean = false;

    constructor (size: HousingSize) {
        this._size = size;
    }

    public setSelected (selected: boolean): void {
        this._isSelected = selected;
    }

    public setAtomicDeviceId (id: number): void {
        this._atomicDeviceId = id;
    }
    public isSelected (): boolean {
        return this._isSelected;
    }

    public isAvailable (): boolean {
        if (undefined !== this._atomicDeviceId) {
            return true;
        }

        return false;

    }

    get value (): number {
        return this._size.value;
    }

    get atomicDeviceId (): number {
        return this._atomicDeviceId;
    }

    get cssClasses (): string {

        const cssClasses: string[] = [];

        if (false === this.isAvailable()) {
            cssClasses.push('disabled');
        }

        if (true === this.isSelected()) {
            cssClasses.push('selected');
        }

        // add en emmpty element to array to create leading space after reverse and join
        if (0 !== cssClasses.length) {
            cssClasses.push('');
        }

        return cssClasses.reverse().join(' ');

    }

}

export default class ViewHousingSizes {

    private _sizes: ViewHousingSize[] = [];

    constructor (atomicDevice: AtomicDevice) {

        const sizes: HousingSize[] = this.setSizes(atomicDevice);
        this._sizes = this.setViewSizes(atomicDevice, sizes);

    }

    private uniqueSizes (sizes: HousingSize[]): HousingSize[] {
        const uniqueSizes: HousingSize[] = [];

        const unique: any = {};

        for (const size of sizes) {
            if (true === unique[size.value]) {
                continue;
            }

            uniqueSizes.push(size);
            unique[size.value] = true;

        }

        return uniqueSizes;
    }

    private sortSizes (size: HousingSize[]): void {
        size.sort((a: HousingSize, b: HousingSize) => {
            const nameA = a.value;
            const nameB = b.value;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

    }

    /**
     * Map sizes with atomicDevices with the very same size
     */
    private setViewSizes (atomicDevice: AtomicDevice, sizes: HousingSize[]): ViewHousingSize[] {

        const viewSizes: ViewHousingSize[] = sizes.map((color) => {
            return new ViewHousingSize(color);
        });

        const atomicDevicesWithSameSize = atomicDevice.device.getAtomicDevicesByAttr('housingColor', atomicDevice.attr.housingColor);

        for (const atomicDeviceWithSameSize of atomicDevicesWithSameSize) {
            for (const viewSize of viewSizes) {

                if (viewSize.value !== atomicDeviceWithSameSize.attr.housingSize) {
                    continue;
                }

                if (undefined !== viewSize.atomicDeviceId) {
                    continue;
                }

                viewSize.setAtomicDeviceId(atomicDeviceWithSameSize.id);

            }
        }

        for (const viewSize of viewSizes) {
            if (viewSize.value !== atomicDevice.attr.housingSize) {
                continue;
            }

            viewSize.setSelected(true);
            break;

        }

        return viewSizes;

    }

    /**
     * Get sizes from all atomicDevices
     */
    private setSizes (atomicDevice: AtomicDevice): HousingSize[] {
        let sizes: HousingSize[] = [];

        sizes = atomicDevice.getDevice().getAtomicDevices().map((atomicDevice) => {
            return new HousingSize(
                atomicDevice
            );
        });

        sizes = sizes.filter((size) => {
            if (0 >= size.value) {
                return false;
            }

            return true;
        });

        sizes = this.uniqueSizes(sizes);

        this.sortSizes(sizes);

        return sizes;

    }

    get all (): ViewHousingSize[] {
        return this._sizes;
    }

    get selected (): ViewHousingSize {

        for (const size of this._sizes) {
            if (size.isSelected()) {
                return size;
            }
        }

        return undefined;
    }

}

/**
 * @module SalesFlow/evolved/view
 */

import {ViewEvolvedCtasBntDataGoBase} from 'view-evolved/ctas/bnt-datago/view-evolved--ctas--bnt-datago-flow-ctas';

import Injector from 'core/injector';
import Offer from 'view/view/shared/offer/offer';

import { Constants } from 'core/constants';
import { TariffGroupName } from 'core/ids';

/**
 * Two buttons in pricebox and the very same in costoverview
 * Left: Link to vvl (As always in bnt)
 * Right: Link to next page or when simonly open overlay or when simonly red xl go to connect more page
 *
 * @export
 * @class DeviceOverviewBntCtas
 * @extends {FlowCtas}
 */
export class ViewEvolvedCtasBntDataGoDeviceOverviewCtas extends ViewEvolvedCtasBntDataGoBase {

    constructor (injector: Injector) {
        super(injector);

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        let rightButtonUrl: string = '';

        /*
         * when offering a device or hardware only
         * the next step should be device detail
         */
        if (offer.isDevice() || offer.isHardwareOnly()) {

            rightButtonUrl = offer.atomicDevice.getDevice().getDetailLink(
                this._injector.getOptions().get('device_detail_prefix')
            );

        }
        /*
         * So we are dealing with a simonly offer
         */
        else if (true === offer.isSimOnly()) {
            /*
             * Workaround for unlimited
             * If simonly, use x-sell page instead of red plus overlay
             */
            if (Constants.RedXL_Id === offer.subscriptionId) {
                rightButtonUrl = this._injector.getOptions().get('connectmore_url');
            } else {

                rightButtonUrl = '#proceedToBasket';

            }

        }

        this._right.setHref(
            rightButtonUrl
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
        );

    }

    protected events (): void {
        super.events();

        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            if ('#proceedToBasket' === this._right.href) {

                evt.preventDefault();

                this.createBasketAndProceed();

                return false;
            }

        });
    }

}

/**
 * @module SalesFlow/router
 */

import Router from 'router/shared/router';
import AttributeRepo from 'model/repo/attribute-repo';
import SimCardRepo from 'model/repo/simcard-repo';
import PurchasableDeviceRepo from 'model/repo/purchasable-device-repo';
import DependencyRepo from 'model/repo/dependency-repo';

import {OfferController} from 'controller/offer-controller';
import MultisimProductDetailController from 'controller/multisim/multisim-product-detail-controller';
import { VluxJsonData } from '@vfde-fe/general-sales-objects';
import ServiceRepo from 'model/repo/service-repo';

export default class MultisimProductDetailRouter extends Router {

    protected _attributeRepo: AttributeRepo;
    private _serviceRepo: ServiceRepo;
    private _discountRepo: ServiceRepo;
    protected _simCardRepo: SimCardRepo;
    private _purchasableDeviceRepo: PurchasableDeviceRepo;
    private _dependencyRepo: DependencyRepo;

    public getRepos (): JQueryPromise<any> {

        const deferred = $.Deferred<any>();

        this.getInjector().getRepos().setSubscriptionGroup(
            this.getInjector().getFlow().getSubscriptionGroup()
        );

        $.when(
            this.getInjector().getRepos().getServiceRepo(),
            this.getInjector().getRepos().getDiscountRepo(),
            this.getInjector().getRepos().getSimCardRepo(),
            this.getInjector().getRepos().getAttributeRepo(),
            this.getInjector().getRepos().getPurchasableDeviceRepo(this.getInjector(), 'watchesglobal'),
            this.getInjector().getRepos().getDependencyRepo()
        ).done((
            serviceRepo: ServiceRepo,
            discountRepo: ServiceRepo,
            simCardRepo: SimCardRepo,
            attributeRepo: AttributeRepo,
            purchasableDeviceRepo: PurchasableDeviceRepo,
            dependencyRepo: DependencyRepo
        ) => {
            this._serviceRepo = serviceRepo;
            this._discountRepo = discountRepo;
            this._simCardRepo = simCardRepo;
            this._attributeRepo = attributeRepo;
            this._purchasableDeviceRepo = purchasableDeviceRepo;
            this._dependencyRepo = dependencyRepo;

            const vluxData: VluxJsonData = {
                discountJSON: this._discountRepo.rawData,
                serviceJSON: this._serviceRepo.rawData,
                dependenciesJSON: this._dependencyRepo.rawData,
                hardwareJSON: this._simCardRepo.rawData
            };

            /**
             * on this page we don't need Red+ paramerers
             */
            this.prepareGeneralSalesObject(vluxData);

            deferred.resolve();

        }).fail(() => {

            deferred.reject();

        });

        return deferred.promise();

    }

    public validateIncoming (): void {

    }

    public createController (): JQueryPromise<OfferController> {

        const deferred = $.Deferred<any>();

        this.getRepos().then(() => {
            deferred.resolve(
                new MultisimProductDetailController(
                    this.getInjector(),
                    this._simCardRepo,
                    this._attributeRepo,
                    this._purchasableDeviceRepo
                )
            );
        });

        return deferred.promise();
    }

}

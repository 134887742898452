/**
 * @module SalesFlow/evolved/view
 */

import Injector from 'core/injector';

import {SubscriptionList} from 'view/element/shared/subscription-list';

import Offer from 'view/view/shared/offer/offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

import SlideMeIfYouCan from 'view/element/shared/slide-if-you-can';
import SubscriptionDetailOverlay from 'view/element/bnt/subscription-detail-overlay';

import {SalesChannelName, SubscriptionGroupName} from 'core/ids';
import {ViewEvolvedElementVvlVfPassOverlay} from 'view-evolved/element/vvl/view-evolved--element-vvl--vf-pass-overlay';
import {ViewEvolvedElementInlifeSubscriptionDetail} from 'view-evolved/element/inlife/view-evolved--element-inlife--subscription-detail';
import {Constants} from 'core/constants';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

declare var $: JQueryStatic;
declare var vf: any;

export class EvolvedInlifeSubscriptionSelection extends SubscriptionList<SimOnlyOffer[]> {

    private _element: JQuery;

    public _slider: SlideMeIfYouCan;

    private _subscriptionTilesSelector: string = '.tariff-module-tile[data-subscription-id]';

    // @TODO this should not stored here
    private _activeSubscriptionId: number;

    // @TODO this should not stored here
    private _offers: SimOnlyOffer[];

    private _subscriptionDetail: ViewEvolvedElementInlifeSubscriptionDetail;

    private _subscriptionDetailOverlay: SubscriptionDetailOverlay;

    private _vfPassOverlay: ViewEvolvedElementVvlVfPassOverlay;

    protected _scrollToFocus: boolean;

    private salesChannel: SalesChannelName;

    protected _gigakombiDeviceDetailService: GigakombiDeviceDetailService;

    constructor (subscriptionId: number, injector: Injector) {

        super(injector);

        this._activeSubscriptionId = subscriptionId;

        this._element = $('#nsf-subscription-list-slide');

        this._slider = new SlideMeIfYouCan(
            this._element,
            'tariff',
            injector
        );

        this._subscriptionDetailOverlay = new SubscriptionDetailOverlay(injector);

        this._subscriptionDetail = new ViewEvolvedElementInlifeSubscriptionDetail(this._element, injector);

        this._vfPassOverlay = new ViewEvolvedElementVvlVfPassOverlay(injector);

        this.salesChannel = this.getInjector().getOptions().get('default_sales_channel');

        this._gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());

    }

    public getEvolvedSubscriptionIds (salesChannel: SalesChannelName, subscriptionGroupName: SubscriptionGroupName): number[] {

        const subscriptionIds: number[] = [];

        this._element.find('[data-subscription-id]').each((index, tariffTile) => {

            const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);
            subscriptionIds.push(subscriptionId);

        });

        return subscriptionIds;
    }

    public getSubscriptionIds (): number[] {

        const subscriptionIds: number[] = [
            this.getInjector().getFlowState().getSubscriptionId()
        ];

        return subscriptionIds;

    }

    public setSubscriptionIds (allowedTariffIds: number[]): number[] {

        const subscriptionIds: number[] = [];

        this._element.find(this._subscriptionTilesSelector).each((index, tariffTile) => {

            const subscriptionId: number = parseInt($(tariffTile).data('subscription-id'), 10);

            if (allowedTariffIds.indexOf($(tariffTile).data('subscription-id')) > -1) {
                subscriptionIds.push(subscriptionId);
            } else {
                $(tariffTile).addClass('hide');
            }

        });

        return subscriptionIds;
    }

    public setActiveSubscriptionId (subscriptionId: number) {
        this._activeSubscriptionId = subscriptionId;
    }

    public setTariffName (name: string) {
        $('#nsf-tariff-name').html(name);
    }

    public update (offers: Offer[], scrollToFocus = true): void {

        this._offers = offers;
        this._element.find(this._subscriptionTilesSelector).parent().addClass('noOffer');
        const customer = this.getInjector().getFlow().customer;
        for (const offer of offers) {

            const tile: JQuery = this._element.find('[data-subscription-id="' + offer.subscriptionId + '"]');

            tile.parent().removeClass('noOffer');

            tile.find('.priceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyDiscountPrice, 'partials'));

            if (offer.monthlyRegularPrice !== offer.monthlyDiscountPrice) {

                tile.find('.strikePriceVlux').html(this.getInjector().getTemplates().render('price-element', offer.monthlyRegularPrice, 'partials'));

            }
            else {

                tile.find('.strikePriceVlux').parents('.additional.standard-price').html('pro Monat').addClass('no-strike');

            }

            tile.find('.asterisk-text').addClass('with-sub');
            tile.find('.asterisk').html('').addClass('without');

            if (offer.offerTariffCosts.discountPrices.length) {

                if (undefined !== offer.offerTariffCosts) {
                    if (0 < offer.offerTariffCosts.discountPrices.length) {

                        let undiscountedPrice: string = '' + (Math.round(offer.offerTariffCosts.priceArray[offer.offerTariffCosts.shortestDiscount] * 100) / 100).toFixed(2);
                        undiscountedPrice = undiscountedPrice.replace('.', ',');

                        tile.find('.asterisk-text').html(
                            'Ab dem ' + (offer.offerTariffCosts.discountPrices[0].runtime) +
                            '. Monat ' + this.getInjector().getTemplates().render('price-element',
                             undiscountedPrice, 'partials') + '<br> 24 Monate Mindestlaufzeit inklusive SpeedGo <sup>2</sup>');

                        // Init footnote.js to handle race conditions and initialize sub scrolling
                        vf.footnotes.init();
                    }
                }
            } else {
                if (offer.subscriptionId === Constants.RedXS_Id || offer.subscriptionId === Constants.RedS_Id
                    || offer.subscriptionId === Constants.RedM_Id || offer.subscriptionId === Constants.RedL_Id) {
                        tile.find('.asterisk-text').html('24 Monate Mindestlaufzeit inklusive SpeedGo <sup>2</sup>');
                        vf.footnotes.init();
                }
            }

            tile.find('.price-info.onetime').parent().remove();

            if (undefined === offer.subscription.pib) {
                tile.find('.productinformationVlux').remove();
            }
            else {
                tile.find('.productinformationVlux').attr('href', offer.subscription.pib);
            }

            if (offer.hasPrivatePricingDiscount) {
                tile.find('.private-pricing-badge').show();
            } else {
                tile.find('.private-pricing-badge').hide();
            }

            if (customer.hasGigakombiCase) {
                this._gigakombiDeviceDetailService.updateDataVolumeOnTile(tile, offer);
            }

        }

        if (customer.hasGigakombiCase) {
            this._gigakombiDeviceDetailService.updateTariffTileWithGigaKombiPromoBadge(offers);
        }

        $('.noOffer').hide();

        this._slider.updateHeight();

        if (false === scrollToFocus) {
            this._slider.setScrollToFocus(false);
        }

        this._slider.update();

        if (true === scrollToFocus) {
            this._slider.scrollToFocus();
        }

    }

    public render (offers: Offer[]): void {

        this._element.find(this._subscriptionTilesSelector).removeClass('selected');
        this._element.find(this._subscriptionTilesSelector).show();

        this.update(offers);

        this._element.find(this._subscriptionTilesSelector).each((index, tariffTile) => {
            const discountText = $(tariffTile).data('discount-text');
            if (undefined !== discountText && undefined === $(tariffTile).find('.price-detail .discount').html()) {
                $(tariffTile).find('.price-info.monthly').parent('.price-detail').prepend(
                    this.getInjector().getTemplates().render('subscription_discount_text', discountText)
                );
            }

            if ('true' === $(tariffTile).attr('data-old-portfolio')) {
                $(tariffTile).addClass('hide');
            }

        });

        /**
         * just initialize if active (displayed)
         */
        if (0 < $('.mod-tariff-row-module .jspScrollable').length && this._element.find('.tg-head').hasClass('.active')) {

            this._slider.update();

        }

        /**
         * This condition takes care of showing the subscription the customer had selected on page reload
         */
        if (undefined !== this._activeSubscriptionId) {
            this._element.find('[data-subscription-id="' + this._activeSubscriptionId + '"]').addClass('selected').removeClass('hide');

        }

    }

    private bindSlider (): void {

        let focusSubscriptionId: number;

        if (undefined !== this._activeSubscriptionId) {

            focusSubscriptionId = this._activeSubscriptionId;

        }

        this._slider.bind(
            $('.tariff-module-tile[data-subscription-id="' + focusSubscriptionId + '"]')
        );

    }

    public events (): void {

        this._element.on('click', '.selectionRadio', (evt: JQueryEventObject) => {

            const tile = $(evt.currentTarget).parents('.tariff-module-tile');

            if (true === tile.hasClass('selected')) {
                return;
            }

            this._element.find(this._subscriptionTilesSelector).removeClass('selected');

            tile.addClass('selected');

            this._slider.showSelectedElement();

            this.getInjector().getEvent().trigger('subscriptionId@changed', {
                subscriptionId: tile.data('subscription-id')
            });

        });

        this._element.on('click', '.inlife-accordion-toggle', (evt) => {
            setTimeout(() => {
                this._slider.updateHeight();
            }, 850);
        });

        $('#nsf-cost-overview-wrap').on('click', '.tariffLink', (evt) => {
            this._slider.scrollToSelectedElement();
        });

        this._injector.getEvent().listen('currentTariff@selected', (eventObject: JQueryEventObject, data: any) => {

            this._slider.updateWidth();
            this._slider.scrollToSelectedElement();

        });

    }

    public bind (offers: Offer[]): void {

        this._offers = offers;

        this.render(offers);

        this._subscriptionDetail.bind(offers);

        this.events();

        this._vfPassOverlay.bind();

        this.bindSlider();

    }

}

/**
 * @module SalesFlow/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import { Constants } from 'core/constants';

import Injector from 'core/injector';
import {Renderable} from '../../renderable';
import Offer from '../../view/shared/offer/offer';
import ViewOverlay from '../../view/shared/overlay';
import PromoBadgeApi from 'core/promo-badge-api';
import {BusinessTransactionContext} from 'core/ids';

export default class CostOverview extends Renderable<Offer> {

    private _element: JQuery;
    private _elementHtml: string;
    private _offer: Offer;
    private _isVisible: boolean = false;
    private _promoBadge: { [key: string]: any } = undefined;
    private _promoBadgeId: number = 0;
    private _btx: BusinessTransactionContext = this.getInjector().getBtx();
    protected salesChannel: string;

    constructor (injector: Injector) {

        super(injector);

        this._element = $('#nsf-cost-overview-wrap');
        // Needed for family friends
        this.salesChannel = injector.getFlowState().getSalesChannel();

    }

    public getElement (): JQuery {

        return this._element;

    }

    protected initShippingLinkEvent () {

        this._elementHtml = $('#nsf-versandkosten-overlay-content').html();
        $('#nsf-versandkosten-overlay-content').remove();

        this._element.on('click', '#shipping', (evt) => {

            const viewOverlay = new ViewOverlay('', '', this._elementHtml);

            this.getInjector().getOverlay().open(viewOverlay);

        });

    }

    private checkPromoBadgeText (tariffId: number, serviceId: number) {

        let ret = [];

        try {
            if ('object' === typeof this._promoBadge[serviceId][tariffId]) {

                ret = this._promoBadge[serviceId][tariffId];

            }
            else if ('object' === typeof this._promoBadge[serviceId].all) {

                ret = this._promoBadge[serviceId].all;

            }
        } catch (e) {
        }

        if ('' !== ret) {

            // If we are in SOHO VVL we need the Sie headline and description from promoBadge.json
            if (Constants.BTX_VVL === this._btx && Constants.SALESCHANNEL_SOHO === this.salesChannel) {
                ret = ret.Sie;
            }
            else {
                ret = ret.Du;
            }

        }

        return ret;

    }

    private setHotlineNumber () {

        const hotlineNumber = this._element.find('.nsf-tel').attr('href');

        if (undefined !== hotlineNumber) {

            this._element.find('.nsf-tel').attr('href', $('#nsf-pricebox .nsf-tel').attr('href').replace(/ /g, ''));
            this._element.find('.nsf-tel').find('.part-two').html($('#nsf-pricebox .nsf-tel').find('.part-two').html());

        }

    }

    private checkPrivatePricing (offer: Offer): void {

        // check for VVL, if customer has PP
        if (offer.hasPrivatePricingDiscount) {
            $('#nsf-pricebox').find('.private-pricing-badge').show();
            $('.tariff-module-tile-vvl').find('.incompatible-with-pp').hide();
        } else {
            $('#nsf-pricebox').find('.private-pricing-badge').hide();
            $('.tariff-module-tile-vvl').find('.incompatible-with-pp').show();
        }
    }

    private setServiceId (): number {

        const gigakombiServicesFound = this._offer.getGigakombiDiscountIDs();
        let  serviceId = 0;

        if (1 === gigakombiServicesFound.length) {
            serviceId = gigakombiServicesFound[0];
            // special case for TVandBR5
            if (618 === serviceId) {
                serviceId = 612;
            }
        } else if (2 === gigakombiServicesFound.length) {
            serviceId = 177612;
        }

        return serviceId;

    }

    private isSie (): boolean {

        return ((this._btx == Constants.BTX_VVL || this._btx == Constants.BTX_INLIFE) && this.salesChannel == Constants.SALESCHANNEL_SOHO) ? true : false;

    }

    public update (offer: Offer, keepOffer: boolean = false): void {

        if (true === keepOffer) {
            offer = this._offer;
        }

        if (undefined === offer || (Constants.Black_Id === offer.subscriptionId && offer.isSimOnly()) || this.hideForSubGroupIn()) {

            this.hide();

            return;

        }
        else {

            this.show();

        }

        const visibleServiceIds: number[] = this._offer.includedServiceAndDiscountIds;

        let hasSpecialPromoBadge = false;
        let promoText = undefined;
        if (true === this._offer.isGigakombi) {

            const serviceId = this.setServiceId();

            if (undefined !== serviceId) {
                if (!hasSpecialPromoBadge) {
                    // The promoText is found in promo-badge.json and is fetched using the serviceId
                    promoText = this.checkPromoBadgeText(offer.subscriptionId, serviceId);
                    hasSpecialPromoBadge = 'object' === typeof promoText;

                    if (hasSpecialPromoBadge) {

                        this._promoBadgeId = serviceId;

                    }
                }
            }
        }

        for (let i = 0; i < visibleServiceIds.length; i++) {

            if (!hasSpecialPromoBadge) {

                promoText = this.checkPromoBadgeText(offer.subscriptionId, visibleServiceIds[i]);
                hasSpecialPromoBadge = 'object' === typeof promoText;

                if (hasSpecialPromoBadge) {

                    this._promoBadgeId = visibleServiceIds[i];

                }
            }
        }

        let viewport: string = 'desktop';

        if ('mobile' === vf.util.layout(true)) {

            viewport = 'mobile';

        }

        // Skip advantage section if there is no advantages OR if the only advantage is the same as promo badge and not Gigakombi offer
        let renderAdvantages: boolean = true;
        if (offer.includedServicesAndDiscounts.length === 0 || (offer.includedServicesAndDiscounts.length === 1
            && offer.includedServicesAndDiscounts[0].id === this._promoBadgeId && !offer.isGigakombi)) {
            renderAdvantages = false;
        }

        const currentPage = this.getInjector().getRouting().getCurrentPage();

        const costOverViewDataContent = {
            currentPage: currentPage,
            offer: offer,
            isDevice: offer.isDevice(), /* don't ask me why, but this is the only way, it can be passed and read in hbs */
            isSimOnly: offer.isSimOnly(),
            isHardwareOnly: offer.isHardwareOnly(),
            isSimCard: offer.isSimCard(),
            isInlife: offer.isInlife(),
            promoBadge: promoText,
            promoBadgeId: this._promoBadgeId,
            isGigakombi: this._offer.isGigakombi,
            renderAdvantages: renderAdvantages,
            btx: this._btx,
            isSie: this.isSie(),
            salesChannel: this.salesChannel /* for friends n family */
        };

        this._element.find('#nsf-cost-overview').html(
            this.getInjector().getTemplates().render('cost_overview_' + viewport, costOverViewDataContent)
        );

        this.checkPrivatePricing(offer);

        if (offer.monthlyRegularPrice !== offer.monthlyDiscountPrice) {

            let strike_price: number = 0;
            if (undefined !== offer.offerTariffCosts) {
                if (Constants.BTX_BNT === this._btx && false === offer.isGigakombi) {
                    if ( true === offer.isSimOnly()) {
                        strike_price = offer.offerTariffCosts.priceArray[offer.offerTariffCosts.shortestDiscount];
                    }
                    else if ( true === offer.isDevice()) {
                        if (Constants.SALESCHANNEL_YOUNG === this.getInjector().getFlow().getSubscriptionGroup()) {
                            strike_price = offer.offerTariffCosts.regularSimOnlyPrice;
                        }
                        else {
                            strike_price = offer.offerTariffCosts.regularSimOnlyPrice - (offer.offerTariffCosts.regularSimOnlyPrice / 10);
                        }
                    }

                }
                if (Constants.BTX_BNT === this._btx && true === offer.isGigakombi) {
                    if (Constants.RedXL_Id === offer.subscriptionId) {
                        strike_price = offer.offerTariffCosts.regularPrice;
                    }
                    else {
                        strike_price = offer.offerTariffCosts.priceArray[offer.offerTariffCosts.shortestDiscount];
                    }
                }
                else if (Constants.BTX_VVL === this._btx || Constants.BTX_INLIFE === this._btx) {
                    strike_price = offer.offerTariffCosts.regularSimOnlyPrice;
                }
                else {
                    strike_price = offer.offerTariffCosts.regularPrice;
                }
            }
            this._element.find('.strikePriceVlux').html(this.getInjector().getTemplates().render('price-element', strike_price, 'partials'));
        }
        else {

            this._element.find('.strikePriceVlux').html('');
        }

        this.getInjector().getEvent().trigger('costoverview@updated');

    }

    public render (offer: Offer): void {

        if (undefined === offer) {

            this.hide();

        }

        this._offer = offer;

        const currentPage = this.getInjector().getRouting().getCurrentPage();

        const costOverViewData = {
            currentPage: currentPage,
            offer: offer,
            btx: this._btx,
            isSie: this.isSie(),
            checkedRedXLWarningHint: this._injector.getStorage().getItem('checkedRedXLWarningHint')
        };

        // @TODO Don't use html, use preprend tp preserve divide
        this._element.html(this.getInjector().getTemplates().render('cost_overview', costOverViewData));

        if (undefined === this._promoBadge) {

            $.when(PromoBadgeApi.getPromoBadgeData()).done((success: JQueryPromiseCallback<any>) => {

                this._promoBadge = success;

                this.update(offer);
                this.setHotlineNumber();

            }).fail((error: JQueryPromiseCallback<any>) => {

                this.update(offer);
                this.setHotlineNumber();

            });
        }
        else {

            this.update(offer);
            this.setHotlineNumber();

        }

        this.setHotlineNumber();

    }

    public events (offer: Offer): void {

        this._element.on('click', '.tariffLink', (evt) => {

            const hash = $(evt.currentTarget).attr('href');
            const target = $(hash);

            if (target.length) {

                const offset = $('header').height();

                $('html,body').animate({

                    scrollTop: target.offset().top - offset

                }, 500, function () {

                    if ('#nsf-fold-up-and-down-tariff' === hash) {

                        if (false === target.find('.tg-head').hasClass('tg-active')) {

                            target.find('.tg-head').trigger('click');

                        }
                    }
                });

                return false;
            }

            return false;

        });

        $(window).on('vf::resize orientationchange', (event: JQueryEventObject) => {

            this.update(this._offer);

        });

        this.getInjector().getEvent().listen('offer@changed', (eventObject: JQueryEventObject, data: any) => {

            const offer: Offer = data.offer;
            this._offer = offer;

            this.update(offer);

        });

        this._injector.getEvent().listen('vfPass@changed', (eventObject: JQueryEventObject, passId: number) => {
            this.update(undefined, true);
        });

        this.getInjector().getEvent().listen('offer@none', (eventObject: JQueryEventObject, data: any) => {

            this.hide();

        });

        this.initShippingLinkEvent();

    }

    public bind (deviceOffer: Offer): void {

        this.render(deviceOffer);
        this.events(deviceOffer);

    }

    public show (): void {

        this._element.show();

        this._isVisible = true;

    }

    public hide (): void {

        this._element.hide();

        this._isVisible = false;

    }

    /**
     *  [CO-7306] If in the BNT flow and the subscription group is 'In',
     *  but the hardwareonly is not selected the price box should be hidden, for both device overview and device detail.
     */
    public hideForSubGroupIn (): boolean {

        return (Constants.SUBSCRIPTION_GROUP_IN === this._injector.getFlowState().getSubscriptionGroup()
        && !this._injector.getFlowState().getHardwareOnly()
        && Constants.BTX_BNT === this._injector.getBtx());
    }

}

/**
 * @module SalesFlow/view
 */

import Offer from 'model/type/offer';

import Subscription from 'model/type/subscription';

class DiscountPrice {

    private _runtime: number = 0;
    /* runtime of this discount */
    private _price: number = 0;

    constructor (runtime: number, price: number) {
        this._runtime = runtime;
        this._price = price;
    }

    get price (): number {
        return this._price;
    }

    get runtime (): number {
        return this._runtime;
    }

    set price (price: number) {
        this._price = price;
    }

}

/**
 * @TODO This is a cry for refactoring ;) Not very oopish and not very understandable === not very maintanable
 */
export default class OfferTariffCosts {

    private _offer: Offer;
    private _subscription: Subscription;

    private _regularSimOnlyPrice: number = 0;
    /* Tariff Price without discounts and subsidization */
    private _regularPrice: number = 0;
    /* basic Tariffprice without discounts including  subsidization */

    private _discountSimOnlyPrice: number = 0;
    /* Tariff Price including discounts and subsidization */
    private _discountPrice: number = 0;
    /* basic Tariffprice including discounts including  subsidization */

    private _subsidizationDiscount = 0;
    private _subsidization: number = 0;
    /* ths subsidization for device */
    private _connectionFee: number = 0;

    private _shortestDiscount: number;

    private _priceArray: number[] = [];

    private _discountPrices: DiscountPrice[] = [];
    /* discounts may have different runtimes,
                                                         this array includes  prices for all runtimes */

    private _deviceInsurance: number = 0;

    constructor (offer: Offer, subscription: Subscription) {

        this._offer = offer;
        this._subscription = subscription;
        this._subsidization = this.getSubAmount();

        /**
         * the "regular" price is the price, the customer has to pay without discounts
         */
        this._regularSimOnlyPrice = this._offer.subcriptionPriceMonthly.value - this._subsidization - this._subsidizationDiscount;
        this._regularPrice = this._offer.subcriptionPriceMonthly.value - this._subsidizationDiscount;

        /**
         * the "discount" price is the price, the customer has to pay including discounts.
         * Will be calculated in "generateRuntimeArray"
         */
        this._discountSimOnlyPrice = this._offer.subcriptionPriceMonthly.value - this._subsidization - this._subsidizationDiscount;
        this._discountPrice = this._offer.subcriptionPriceMonthly.value - this._subsidizationDiscount;

        /**
         *  ConnectionFee is by default the onetime Price in Subscription
         */

        // @TODO Why is subcriptionPriceOnetime undefined? For example for offer[id=49_2534]
        if (undefined === this._offer.subcriptionPriceOnetime) {

            this._connectionFee = 0;

        }
        else {

            this._connectionFee = this._offer.subcriptionPriceOnetime.value;

        }

        /**
         *  Special treatment Deviceinsurance
         */
        if (undefined !== this._offer.getOptionalServices()) {
            for (const serviceGroup of this._offer.getOptionalServices()) {
                for (const service of serviceGroup.services) {
                    if (158 === service.id || 159 === service.id) {
                        this._deviceInsurance = service.monthlyPrice.value;
                    }
                }
            }
        }
        /**
         * generates Array with information on showing "Ab dem ... zahlen Sie"
         */
        this.generateRuntimeArray();

    }

    private getSubAmount (): number {

        let subAmount: number;

        /* getting subsidization from tariff */
        for (const sub of this._subscription.getSubsidizations()) {
            if (sub.id === this._offer.subscriptionId) {
                if (undefined !== sub.subLevel) {
                    subAmount = sub.subLevel;
                }
                else {
                    /**
                     * Simonly has no sublevel attribute => i.e. SubLevel = 0
                     */
                    subAmount = 0;
                }
            }
        }

        if (0 === subAmount || undefined === subAmount) {
            return subAmount;
        }

        // TODO this works only if subsidization has 24 month runtime
        if (undefined !== this._offer.getIncludedDiscounts()) {

            for (const discountGroup of this._offer.getIncludedDiscounts()) {

                for (const discount of discountGroup.services) {

                    if ('subsidization' === discount.reference) {

                        if ('object' === typeof discount.monthlyPrice) {

                            subAmount += discount.monthlyPrice.value;

                            this._subsidizationDiscount += -1 * discount.monthlyPrice.value;
                        }

                    }

                }
            }
        }

        return subAmount;

    }

    private generateRuntimeArray (): void {
        /**
         * Array (1-24) initialize array with regular Price
         */
        this._priceArray = [];
        for (let i = 0; 24 > i; i++) {
            this._priceArray.push(
                this._regularSimOnlyPrice + this._subsidization
            );
        }

        // what is the discount with the shortest runtime
        let shortestDiscount = 50;

        if (undefined !== this._offer.getIncludedDiscounts()) {
            for (const discountGroup of this._offer.getIncludedDiscounts()) {
                for (const discount of discountGroup.services) {
                    if ('object' === typeof discount.monthlyPrice) {

                        if ('month' === discount.monthlyPrice.recurrenceUnit && shortestDiscount > discount.monthlyPrice.recurrenceEnd) {
                            shortestDiscount = discount.monthlyPrice.recurrenceEnd;
                        }

                    }
                }
            }
        }

        if (50 !== shortestDiscount) {
            this._shortestDiscount = shortestDiscount;
        }

        /* Discounts */
        if (undefined !== this._offer.getIncludedDiscounts()) {
            for (const discountGroup of this._offer.getIncludedDiscounts()) {
                for (const discount of discountGroup.services) {

                    if ('subsidization' === discount.reference) {
                        continue;
                    }

                    if ('object' === typeof discount.monthlyPrice) {

                        this._discountSimOnlyPrice += discount.monthlyPrice.value;
                        this._discountPrice += discount.monthlyPrice.value;
                        for (let i = 0; discount.monthlyPrice.recurrenceEnd > i; i++) {
                            this._priceArray[i] += discount.monthlyPrice.value;
                        }
                    }
                }
            }
        }

        /* Services */
        if (undefined !== this._offer.getIncludedServices()) {
            for (const serviceGroup of this._offer.getIncludedServices()) {
                for (const service of serviceGroup.services) {

                    /**
                     * just the services, that have a benefit. services, the customer has to pay for
                     * will be shown in pricebox seperately
                     */

                    if ('object' === typeof service.monthlyPrice) {

                        /**
                         * not included in sum, because it's already stated in pricebox
                         */
                        if (0 > service.monthlyPrice.value) {
                            this._discountSimOnlyPrice += service.monthlyPrice.value;
                            this._discountPrice += service.monthlyPrice.value;
                        }

                        /**
                         * but included in "nach x Monaten zahlen sie...."
                         */
                        for (let i = 0; service.monthlyPrice.recurrenceEnd > i; i++) {
                            this._priceArray[i] += service.monthlyPrice.value;
                        }

                    }
                }
            }
        }

    }

    public updateRuntimeArray (price: number, recurrenceEnd: number): void {

        for (let i = 0; recurrenceEnd > i; i++) {
            this._priceArray[i] += price;
        }

    }

    /* returns an array of prices, i.e. the price you have to pay during runtime
        'ab dem 13. Monat zahlst du...'
    */
    get discountPrices (): DiscountPrice[] {

        this._discountPrices = [];
        let basePrice: number = this._priceArray[0];

        for (let i = 0; 24 > i; i++) {
            if (basePrice !== this._priceArray[i]) {
                this._discountPrices.push(new DiscountPrice((i + 1), this._priceArray[i] + this._subsidization));
                basePrice = this._priceArray[i];
            }
        }

        return this._discountPrices;
    }

    get hasDiscount (): boolean {
        return this._regularSimOnlyPrice > this._discountSimOnlyPrice;
    }

    get discountPrice (): number {
        return this._discountPrice;
    }

    get discountSimOnlyPrice (): number {
        return this._discountSimOnlyPrice;
    }

    get connectionFee (): number {
        return this._connectionFee;
    }

    get subsidization (): number {
        return this._subsidization;

    }

    get regularPrice (): number {
        return this._regularPrice;
    }

    get regularSimOnlyPrice (): number {
        return this._regularSimOnlyPrice;

    }

    get deviceInsurance (): number {
        return this._deviceInsurance;
    }

    get shortestDiscount (): number {
        return this._shortestDiscount || 12;
    }

    get priceArray (): number[] {
        return this._priceArray;
    }
}

/**
 * @module SalesFlow/controller-evolved
 */

import Injector from 'core/injector';

import { Constants } from 'core/constants';

import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';
import {ViewEvolvedCtasBntDeviceOverviewCtas} from 'view-evolved/ctas/bnt/view-evolved--ctas--bnt-device-overview-ctas';
import {ViewEvolvedCtasBntDataGoDeviceOverviewCtas} from 'view-evolved/ctas/bnt-datago/view-evolved--ctas--bnt-datago-device-overview-ctas';

import {DeviceOverviewPageState} from 'controller/shared/device-overview-controller';

import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
import {ViewEvolvedElementBntTariffGroupSwitcher} from 'view-evolved/element/bnt/view-evolved--element-bnt--tariffgroup-switcher';

import {EvolvedBntSubscriptionSelection} from 'view-evolved/element/bnt/view-evolved--element-bnt--subscription-selection';
import {ViewEvolvedElementDeviceList} from 'view-evolved/element/shared/view-evolved--element-shared--device-list';

import {SalesChannelName, SubscriptionGroupName, SubscriptionIdPerSalesChannel} from 'core/ids';
import {ControllerEvolvedBaseClassDeviceOverview} from 'controller-evolved/base-class/controller-evolved--base-class--device-overview';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';

import Offer from 'view/view/shared/offer/offer';
import HardwareOffer from 'view/view/shared/offer/hardwareonly-offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';

import {TariffGroupInterface} from 'view-evolved/element/shared/view-evolved--element-shared--tariffgroup-switcher';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';
import ViewEvolvedCtasGigakombiDeviceOverviewCtas from 'view-evolved/ctas/gigakombi/view-evolved--ctas--gigakombi-device-overview-ctas';
import ViewEvolvedElementGigakombiTariffgroupSwitcher from 'view-evolved/element/gigakombi/view-evolved--element-gigakombi--tariffgroup-switcher';
import { constants } from 'fs';

/**
 * I come from previous step:
 *      => Tariff is locked
 */
export class ControllerEvolvedBntDeviceOverview extends ControllerEvolvedBaseClassDeviceOverview {

    private _focusAtomicDeviceId: number;

    protected _subscriptionSelection: EvolvedBntSubscriptionSelection;

    constructor (
        focusSubscriptionIds: SubscriptionIdPerSalesChannel,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(
            focusSubscriptionIds,
            null,
            reposSupervisor,
            injector
        );

        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        if (undefined === atomicDeviceId && undefined !== this.getInjector().getFlowState().getRecommendedAtomicId()) {
            this._focusAtomicDeviceId = this.getInjector().getFlowState().getRecommendedAtomicId();
        }

        this._deviceList = new ViewEvolvedElementDeviceList(
            injector,
            this.getReposSupervisor().getAttributeRepo(),
            this._focusAtomicDeviceId,
            'device_tile'
        );

    }

    protected createSubscriptionList (): EvolvedBntSubscriptionSelection {

        /**
         * This function is called just to make sure, that we have a valid
         * subscriptionId here. Because it check's, if the current FlowState-SubscriptionId
         * is a valid one, and in caset's not valid, sets the default subscriptionId in flow
         */
        let subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const simOnlyOffer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);
        if (undefined === simOnlyOffer) {
            const subscription = this.getFallbackSubscription();
            subscriptionId = subscription.id;
        }

        const gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());

        return new EvolvedBntSubscriptionSelection(
            subscriptionId,
            this.getInjector(),
            this._focusSubscriptionIdArray,
            gigakombiDeviceDetailService
        );

    }

    /**
     * Returns tariff group switcher for either BNT or Gigakombi depending on the BTX
     *
     * @todo move to parent class to share the code between controllers?
     * @returns AbstractSubscriptionGroupSwitcher
     */
    // HBO: not needed anymore, I suppose
    // protected createTariffGroupSwitcher (): AbstractSubscriptionGroupSwitcher {
    //     if (Constants.BTX_GIGAKOMBI === this._btx) {
    //         return new ViewEvolvedElementGigakombiTariffgroupSwitcher(
    //             this.getInjector()
    //         );
    //     }
    //     else {
    //         return new ViewEvolvedElementBntTariffGroupSwitcher(
    //             this.getInjector()
    //         );
    //     }
    // }

    /**
     * Create the CTAs
     *
     * This method creates the CTAs for the cost overview.
     * It returns Gigakombi CTA (left button: Noch kein Feztnetz Kunde?)
     * or the default BNT CTAs.
     *
     * @returns FlowCtas
     */
    protected createCtas (): ViewEvolvedCtasSharedFlowCtas {

        const orderType = this.getInjector().getFlowState().getOrderType();

        if (Constants.BTX_GIGAKOMBI === this._btx) {
            return new ViewEvolvedCtasGigakombiDeviceOverviewCtas(this._injector);
        } else if (Constants.OrderType_Data === orderType) {
            return new ViewEvolvedCtasBntDataGoDeviceOverviewCtas(this._injector);
        } else {
            return new ViewEvolvedCtasBntDeviceOverviewCtas(this._injector);
        }

    }

    /**
     * A device is selected
     *
     * @param atomicDeviceId
     * @param avoidTracking
     */
    private handleEventAtomicDeviceIdChanged (atomicDeviceId: number, avoidTracking: boolean) {

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        this._pageState = new DeviceOverviewPageState(subscriptionId, atomicDeviceId, this.getInjector().getFlowState().getHardwareOnly());

        // A devive is selected, but not yet a subscription -> Scroll to scubsriptions
        if (undefined !== atomicDeviceId && undefined === subscriptionId) {

            $('html, body').animate({
                scrollTop: $('#subscriptions').offset().top - 20
            }, 1000);
        }

        this._subscriptionSelection.update(this.getOffersMatchingToSlider());

        // atomicDeviceId has changed and is now undefined or no subscription is selected
        // -> We can make no offer
        if (undefined === atomicDeviceId && (undefined === subscriptionId || Constants.Black_Id === subscriptionId)) {
            this._pricebox.hide();
            this.getInjector().getEvent().trigger('offer@none');

            return;
        }

        const offer: Offer = this.getActiveOffer();

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: offer
        });

        if (true !== avoidTracking) {
            if (undefined !== this.getInjector().getFlowState().getSubscriptionId()) {
                this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                    pageName: this.getPageName(),
                    pageType: 'product detail',
                    deviceOffer: offer
                });
            } else {
                this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                    pageName: this.getPageName(),
                    pageType: 'product listing',
                    deviceOffer: offer
                });
            }
        }

    }

    /**
     * Hardware-Only is selected (which means, all devices
     * are de-selected)
     *
     * @param avoidTracking
     */
    private handleEventHardwareOnlySelected (avoidTracking: boolean) {

        this.getInjector().getFlowState().setHardwareOnly(true);
        this.getInjector().getFlowState().lockSubscription();

        /**
         * reset Red+ Data
         */
        this.getInjector().getFlowState().resetRedPlusFlowData();

        const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        this._pageState = new DeviceOverviewPageState(subscriptionId, atomicDeviceId, true);

        $('#nsf-tariff-name').html('Smartphone ohne Tarif');

        this.getHardwareOnlyDeviceList(salesChannel);

        this._deviceList.update(
            this.getInjector().getOfferCollection().getDevices()
        );

        const selectedDeviceOffer = this.getActiveOffer();

        this.getInjector().getOfferCollection().setActiveOffer(selectedDeviceOffer);

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: selectedDeviceOffer
        });

        if (true !== avoidTracking) {
            this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                pageName: this.getPageName(),
                pageType: 'product detail',
                deviceOffer: selectedDeviceOffer
            });
        }

    }

    /**
     * Get the hardware only device list
     *
     * @param salesChannel
     */
    private getHardwareOnlyDeviceList (salesChannel: SalesChannelName) {
        const devices = this.getGeneralDeviceRepo().getDevices(salesChannel);

        const deviceOffers = devices.map(device => {

            /**
             * Subscription is locked, so I use the first atomic device of the virtual device
             */
            const offer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
                device.getAtomicDeviceByIndex(0).id,
                salesChannel
            );

            return new HardwareOffer(device.getAtomicDeviceById(offer.deviceId), offer);

        });

        this.getInjector().getOfferCollection().setDevices(deviceOffers);
    }

    /**
     * A subscription hast been selected
     *
     * @TODO Handle simonly
     *
     * With the new subscription we need to update all device tiles
     *
     * @param subscriptionId
     * @param avoidTracking
     */
    private handleEventSubscriptionIdChanged (subscriptionId: number, avoidTracking: boolean) {

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();

        this._pageState = new DeviceOverviewPageState(subscriptionId, atomicDeviceId);

        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);
        const devices = this.getGeneralDeviceRepo().getDevices(salesChannel, this._subscription);

        const deviceOffers = devices.map(device => {

            /**
             * Subscription is locked, so I use the first atomic device of the virtual device
             */
            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                device.getAtomicDeviceByIndex(0).id,
                this._subscription.id,
                this._btx,
                salesChannel
            );

            let optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;
            if (Constants.BTX_GIGAKOMBI === this._btx) {
                const gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());
                optionalServiceIds = gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, this._subscription);
            }

            return new DeviceOffer(device.getAtomicDeviceById(offer.deviceId), this._subscription, offer, this.getRedPlusFromFlow(), optionalServiceIds);

        });

        this.getInjector().getOfferCollection().setDevices(deviceOffers);

        this.getInjector().getFlowState().setSubscriptionId(subscriptionId);

        this._deviceList.update(this.getInjector().getOfferCollection().getDevices());

        const selectedDeviceOffer = this.getActiveOffer();

        this.getInjector().getOfferCollection().setActiveOffer(selectedDeviceOffer);

        this.setSubscriptionName(this._subscription.name);

        // @TODO the next line where there, but noz commented. No idea why
        // this.getInjector().getFlowState().setAtomicDeviceId(selectedDeviceOffer.atomicDeviceId);

        this.getInjector().getFlowState().setSubscriptionId(selectedDeviceOffer.subscriptionId);

        this.getInjector().getEvent().trigger('offer@changed', {
            offer: selectedDeviceOffer
        });

        /**
         * If a tariff and device have been selected, send 'product detail' as pageType, if not then send 'product listing' as pageType
         */
        if (true !== avoidTracking) {
            if (undefined !== this.getInjector().getFlowState().getSubscriptionId()
                || (undefined !== this.getInjector().getFlowState().getAtomicDeviceId() && true === this.getInjector().getFlow().getHardwareOnly())) {
                this.getInjector().getEvent().trigger('pageviewTracking@onload',
                    {
                        subscription: this._subscription,
                        pageName: this.getPageName(),
                        pageType: 'product detail',
                        deviceOffer: this.getActiveOffer()
                    }
                );
            } else {
                this.getInjector().getEvent().trigger('pageviewTracking@onload',
                    {
                        subscription: this._subscription,
                        pageName: this.getPageName(),
                        pageType: 'product listing',
                        deviceOffer: this.getActiveOffer()
                    }
                );
            }
        }

    }

    protected handleEventTariffGroupChanged (tariffGroupParam: TariffGroupInterface) {

        super.handleEventTariffGroupChanged(tariffGroupParam);

        if (Constants.SUBSCRIPTION_GROUP_IN === tariffGroupParam.subscriptionGroup) {
            this.getHardwareOnlyDeviceList(this.getInjector().getFlowState().getSalesChannel());
        }

        /**
         * INCM-466: In case, a tariffGroupChange has taken place after a Red+ was selected
         * on X-Sell page, we have to remove the Red+ preselection.
         * The reason is, that the offerIds are stored in session Storage and the offerIds
         * differ from tariffGroup to tariffGroup
         * @private
         */
        this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusKids.resetAllElements();
        this.getInjector().getFlowStateWithSalesChannel().redPlusData.resetAllElements();

        this._deviceList.update(this.getInjector().getOfferCollection().getDevices());

        const offer: Offer = this.getInjector().getOfferCollection().getActiveOffer();

        if (undefined === offer) {

            this.setSubscriptionName('');

        } else {

            this.setSubscriptionName(offer.subscriptionName);

        }

    }

    public events () {

        this.getInjector().getEvent().listen('hardwareonly@selected', (eventObject: JQueryEventObject, data: any) => {

            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventHardwareOnlySelected(avoidTracking);

        });

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            const atomicDeviceId: number = data.atomicDeviceId;
            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventAtomicDeviceIdChanged(atomicDeviceId, avoidTracking);

        });

        this.getInjector().getEvent().listen('subscriptionId@changed', (eventObject: JQueryEventObject, data: any) => {

            const subscriptionId: number = data.subscriptionId;

            const currentSubscriptionId = this.getInjector().getFlowState().getSubscriptionId();

            if (currentSubscriptionId === subscriptionId) {
                return;
            }

            const avoidTracking = (true === data.avoidTracking) ? true : false;

            this.handleEventSubscriptionIdChanged(subscriptionId, avoidTracking);

        });

        this.getInjector().getEvent().listen('offerByVFPass@changed', (eventObject: JQueryEventObject, data: any) => {

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this.getInjector().getOfferCollection().getActiveOffer()
            });

        });

        this.getInjector().getEvent().listen('TariffGroupName@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const subscriptionId = this.getInjector().getFlowState().getSubscriptionId();
            const atomicDeviceId = this.getInjector().getFlowState().getAtomicDeviceId();
            const isHardwareOnly = this.getInjector().getFlowStateWithSalesChannel().getHardwareOnly();
            const salesChannel: SalesChannelName = tariffGroupParam.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupParam.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            // fixing the behaviour, by a portfolio change
            this._pageState = new DeviceOverviewPageState(
                subscriptionId,
                atomicDeviceId,
                isHardwareOnly
            );

            this.handleEventTariffGroupNameChanged(tariffGroupParam);

        });

        this.getInjector().getEvent().listen('TariffGroup@changed', (eventObject: JQueryEventObject, tariffGroupParam: TariffGroupInterface) => {

            const salesChannel: SalesChannelName = tariffGroupParam.salesChannel;
            const subscriptionGroup: SubscriptionGroupName = tariffGroupParam.subscriptionGroup;

            if (false === this.getInjector().getFlowState().isValidSalesChannel(salesChannel) ||
                false === this.getInjector().getFlowState().isValidSubscriptionGroup(subscriptionGroup)) {

                return undefined;

            }

            this.handleEventTariffGroupChanged(tariffGroupParam);

        });

    }

}

/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;
declare var vf: any;

import { Constants } from 'core/constants';
import {BusinessTransactionContext, SubscriptionIdPerSalesChannel, AtomicDeviceId} from 'core/ids';
import Injector from 'core/injector';

import Offer from 'view/view/shared/offer/offer';

import FoldUpAndDown from 'view/element/shared/fold-up-and-down';
import ViewEvolvedElementBntClassVfPassOverlay from 'view-evolved/element/bnt/view-evolved--element-bnt--vf-pass-overlay';
import ViewEvolvedElementSharedVFPassArea from 'view-evolved/element/shared/view-evolved--element-shared--vf-pass-area';
import VfPassArea from 'view-evolved/element/shared/view-evolved--element-shared--vf-pass-area';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

import GeneralBasketServiceInterfaceBNT from 'shopbackend/general-basket-service/general-basket-service-interface-bnt';

// declare var $: JQueryStatic;
// declare var vf: any;

import {EvolvedBaseClassSubscriptionSelection} from 'view-evolved/element/base-class/view-evolved--element-base-class--subscription-selection';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import AtomicDevice from 'model/type/atomic-device';

import BasketHandler from 'shopbackend/basket-handler';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

export class EvolvedBntSubscriptionSelection extends EvolvedBaseClassSubscriptionSelection {

    private _foldUpAndDown: FoldUpAndDown;

    private _hardwareOnlySelector: string = '#nsf-hardware-only-toggle';

    private _vfPassArea: VfPassArea;

    private _generalSalesObjectInterface: GeneralSalesObjectInterface;

    constructor (subscriptionId: number, injector: Injector, focusSubscriptionIds: SubscriptionIdPerSalesChannel, gigakombiDeviceDetailService: GigakombiDeviceDetailService) {

        super(subscriptionId, injector, focusSubscriptionIds, gigakombiDeviceDetailService);

        this._vfPassOverlay = new ViewEvolvedElementBntClassVfPassOverlay(injector);

        this._vfPassArea = new ViewEvolvedElementSharedVFPassArea(injector);

        this._foldUpAndDown = new FoldUpAndDown($('#nsf-fold-up-and-down-tariff'), this._injector);

        this._generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();

    }

    protected updateStrikePrice (offer: Offer, tile: JQuery): void {

        if (offer.monthlyRegularPrice !== offer.monthlyDiscountPrice) {

            let strike_price: number = 0;

            if (undefined !== offer.offerTariffCosts) {
                strike_price = offer.offerTariffCosts.priceArray[offer.offerTariffCosts.shortestDiscount];

                if (Constants.BTX_GIGAKOMBI === this.getInjector().getBtx()) {
                    // Returns strike price based on rules (returns current strike price if no special case met)
                    strike_price = this._gigakombiDeviceDetailService.getStrikePrice(offer, strike_price);
                }

                if (Constants.BTX_BNT === this.getInjector().getBtx() && Constants.SALESCHANNEL_FAMILY_FRIENDS === this.getInjector().getFlow().getSalesChannel()) {
                    strike_price = offer.offerTariffCosts.regularPrice;
                }

            }

            if (undefined !== strike_price) {
                tile.find('.strikePriceVlux').html(this.getInjector().getTemplates().render('price-element', strike_price, 'partials'));
            }
            else {
                tile.find('.strikePriceVlux').parents('.additional.standard-price').html('pro Monat').addClass('no-strike');
            }
        }
        else {
            tile.find('.strikePriceVlux').parents('.additional.standard-price').html('pro Monat').addClass('no-strike');
        }

    }

    protected updateAsteriskText (offer: Offer, tile: JQuery): void {
        if (offer.offerTariffCosts.discountPrices.length) {
            const tariffCostsObject = offer.offerTariffCosts;
            let annotate: string = '';
            if (undefined !== tariffCostsObject) {
                if (0 < tariffCostsObject.discountPrices.length) {
                    const shortestDiscount = tariffCostsObject.shortestDiscount;
                    let undiscountedPrice: string = (Math.round(tariffCostsObject.priceArray[shortestDiscount] * 100) / 100).toFixed(2);

                    undiscountedPrice = undiscountedPrice.replace('.', ',');
                    annotate = 'Ab dem ' + (shortestDiscount + 1) + '. Monat ' + undiscountedPrice + ' &euro;<br>24 Monate Mindestlaufzeit';
                }
            }

            tile.find('.asterisk-text').html(annotate);

        }
    }

    private handleBuySimOnly = (evt: JQueryEventObject): boolean => {

        let subscriptionId: number = 0;

        const btn = evt.currentTarget as HTMLLinkElement;

        if (0 < $(btn).parents('.tariff-module-tile').length) {
            subscriptionId = $(btn).parents('.tariff-module-tile').data('subscription-id');
        } else {
            subscriptionId = $('#nsf-subscription-detail').data('subscription-id');
        }

        if (Constants.RedXL_Id === subscriptionId) {
            // Workaround for unlimited
            // If simonly, use x-sell page instead of red plus overlay

            this.getInjector().getFlow().setAtomicDeviceId(undefined, false);
            this.getInjector().getFlow().setSubscriptionId(subscriptionId, true);

            return true;
        }

        evt.preventDefault();

        this.getInjector().getLoadingIndicator().show();
        this.getInjector().getFlow().setSubscriptionId(subscriptionId);
        this.getInjector().getFlow().lockSubscription();

        const basketHandler = new BasketHandler(this.getInjector());
        const generalBasketServiceInterface = <GeneralBasketServiceInterfaceBNT> this.getInjector().getGeneralBasketServiceInterface(Constants.BTX_BNT);

        const repoSupervisor: ModelEvolvedRepoSupervisor = this.getInjector().getReposSupervisor();
        const subscription = repoSupervisor.getSubscriptionRepo().getSubscription(subscriptionId);

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const btx = this.getInjector().getBtx();

        const offer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, btx, salesChannel);
        const viewSimOnlyOffer = new SimOnlyOffer(subscription, offer, [], this.getInjector().getFlow().optionalServiceIds.elements);

        generalBasketServiceInterface.executeOffer(
            viewSimOnlyOffer,
            salesChannel,
            this._generalSalesObjectInterface).then((success) => {
            basketHandler.defaultSuccessHandling(success);
        }, (error) => {
            basketHandler.defaultErrorHandling(error);
        });

    }

    protected bindEventsToCTA (offer: Offer, tile: JQuery): void {
        const btBuySimonly = tile.find('.btBuySimonly');

        /**
         * CO-20535-adjustment-of-nsf-stepper
         * URL for "Smartphones" differs while we're in transition between simplicity and kiss
         */
        let deviceOverviewUrl;
        const deviceOverviewUrlKISS = this.getInjector().getOptions().get('device_overview_url');
        const deviceOverviewUrlNSF = this.getInjector().getOptions().get('alle_smartphones_url_nsf');
        const deviceOverviewUrlFUF = this.getInjector().getOptions().get('alle_smartphones_fuf_url_nsf');

        const salesChannel = this.getInjector().getFlowStateWithSalesChannel().getSalesChannel();
        const btx = this.getInjector().getBtx();

        if (Constants.BTX_GIGAKOMBI === this.getInjector().getBtx()) {
            deviceOverviewUrl = deviceOverviewUrlNSF + '?giga=kombi';
        } else if (Constants.SALESCHANNEL_CONSUMER === salesChannel || Constants.SALESCHANNEL_YOUNG === salesChannel) {
            deviceOverviewUrl = deviceOverviewUrlKISS;
        } else if (Constants.SALESCHANNEL_FAMILY_FRIENDS === salesChannel) {
            deviceOverviewUrl = deviceOverviewUrlFUF;
        } else {
            deviceOverviewUrl = deviceOverviewUrlNSF;
        }

        tile.find('a.btGotoDeviceOverview').attr('href', deviceOverviewUrl);

        /**
         * This basic click event is overwritten by redplus overlay
         */
        const basketUrl = this.getInjector().getOptions().get('basket_url');
        if (Constants.RedXL_Id === offer.subscriptionId) {
            btBuySimonly.attr('href',
                this.getInjector().getOptions().get('connectmore_url') + (Constants.BTX_GIGAKOMBI === this.getInjector().getBtx() ?  '?giga=kombi' : '')
            );
        }
        else {
            btBuySimonly.attr('href', basketUrl);
        }
        /**
         * first remove all all clicks
         */
        btBuySimonly.off('click');
        btBuySimonly.on('click', this.handleBuySimOnly);

    }

    protected renderPassArea (offer: Offer, tile: JQuery): void {
        this._vfPassArea.renderPassArea(tile, offer.offer);
    }

    public update (offers: Offer[]): void {

        super.update(offers);

        // Assumption: If first offer is SimOnly, all offers are
    /**
     *  [CO-7306] If in the BNT flow and the subscription group is 'In',
     *   the hardwareonly is hidden if no device is selected.
     */
        if ((undefined !== offers[0] && true === offers[0].isSimOnly()) || (Constants.BTX_BNT === this.getInjector().getBtx() &&  Constants.SUBSCRIPTION_GROUP_IN === this.getInjector().getFlowState().getSubscriptionGroup() && undefined === this.getInjector().getFlowState().getAtomicDeviceId())) {
            $(this._hardwareOnlySelector).hide();
        } else {
            $(this._hardwareOnlySelector).show();
        }

    }

    public render (offers: Offer[]): void {

        super.render (offers);

        if (Constants.BTX_GIGAKOMBI !== this.getInjector().getBtx() && Constants.SALESCHANNEL_FAMILY_FRIENDS !== this.getInjector().getFlowState().getSalesChannel()) {
            $(this._hardwareOnlySelector).html(
                this.getInjector().getTemplates().render('hardware_only', {})
            );

            const orderType = this.getInjector().getFlowState().getOrderType();

            if (Constants.OrderType_Data === orderType) {
                this._element.find('.selectionHwOnly').addClass('selectionTabletOnly');
            }

            if (true === this.getInjector().getFlowState().getHardwareOnly()) {
                this._element.find('.selectionHwOnly').addClass('selected');
            }
        }
    }

    public events (): void {

        super.events();

        if (true === this.getInjector().getFlow().isDevicesFirstFlow()) {
            this._foldUpAndDown.removeAccordionStyling();
            $('#nsf-subscription-group-switcher').addClass('no-bg');
        }
        // bind here while the bind method is unused in this class
        this._foldUpAndDown.bind();

        this.getInjector().getEvent().listen('foldUpAndDown@down', (eventObject: JQueryEventObject, data: any) => {

            if (this._foldUpAndDown.getId() !== $(data.element).attr('id')) {
                return;
            }

            this.bindSlider();
            this._slider.update();
            this._slider.updateHeight();
            this._slider.scrollToFocus();

            if (true === $(data.element).hasClass('initialised')) {
                return;
            }

            $(data.element).addClass('initialised');

        });

        if (true === this.getInjector().getFlow().isDevicesFirstFlow()) {
            $('#nsf-fold-up-and-down-tariff .tg-head').trigger('click');
        }

        this._subscriptionTiles.on('click', '.btGotoDeviceOverview', (evt: JQueryEventObject) => {

            const subscriptionId = $(evt.currentTarget).parents('.tariff-module-tile').data('subscription-id');

            const redPlusAllnetChecked = $(evt.currentTarget).parents('.tariff-module-tile').find('.addcard-box').hasClass('selected');

            this.getInjector().getFlowState().setSubscriptionId(subscriptionId);
            this.getInjector().getFlow().lockSubscription();

            this.getInjector().getFlow().redPlusAllnet.resetAllElements();
            if (redPlusAllnetChecked) {
                const offer = this._generalSalesObjectInterface.getRedPlusSimOnlyOfferByType(Constants.RedPlus_Allnet, this.getInjector().getFlowState().getSalesChannel());
                this.getInjector().getFlow().redPlusAllnet.addElement(offer.offerId);
            }

        });

        this._element.on('click', '.selectionHwOnly .inner', (evt: JQueryEventObject) => {

            this._element.find(this._subscriptionTilesSelector).removeClass('selected');

            this._element.find('.selectionHwOnly').addClass('selected');

            this.getInjector().getEvent().trigger('hardwareonly@selected');

        });

        this._injector.getEvent().listen('vfPass@changed', (eventObject: JQueryEventObject, passId: number) => {

            this._element.find(this._subscriptionTilesSelector).find('.pass-box.selected').removeClass('selected');
            this._element.find(this._subscriptionTilesSelector).find('.pass-box[data-pass-id="' + passId + '"]').not('.disabled').addClass('selected');

            for (const offer of this._offers) {
                const tile: JQuery = this._element.find('[data-subscription-id="' + offer.subscriptionId + '"]');
                this._vfPassArea.renderPassArea(tile, offer.offer);
            }

            this._slider.updateHeight();

            // Trigger subscriptionId@changed event to generate a new pageview
            if (undefined !== this.getInjector().getFlow().getSubscriptionId()) {
                this._injector.getEvent().trigger('offerByVFPass@changed', {
                    subscriptionId: this.getInjector().getFlow().getSubscriptionId()
                });
            }
        });

        this._element.on('click', '.mod-tariff-detail-akko .foot', (evt) => {
            setTimeout(() => {
                this._slider.updateHeight();
            }, 500);
        });

        $('#nsf-cost-overview-wrap').on('click', '.tariffLink', (evt) => {
            this._slider.scrollToSelectedElement();
        });

    }

}

/**
 * @module SalesFlow/controller
 */

declare var $: JQueryStatic;

import GeneralBasketServiceInterfaceBNT from 'shopbackend/general-basket-service/general-basket-service-interface-bnt';

import { Constants } from 'core/constants';

import {Controller} from 'controller/controller';

import Injector from 'core/injector';
/**
 * @TODO: Basket Anbindung
 */
import BasketHandler from 'shopbackend/basket-handler';

import SubscriptionRepo from 'model/repo/subscription-repo';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';

export default class BntSubscriptionDetailSeoController extends Controller {

    private _element: JQuery;

    private _simOnlyOffer: SimOnlyOffer;

    private _subscriptionRepo: SubscriptionRepo;

    constructor (
        subscriptionRepo: SubscriptionRepo,
        injector: Injector
    ) {
        super(injector);

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('BntSubscriptionDetailSeoController....');
        }

        this._subscriptionRepo = subscriptionRepo;

    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload', {
            pageName: 'tarife details',
            pageType: 'product detail',
            deviceOffer: {}
        });
    }

    protected render (data?: any): void {

        this._element = $('#nsf-subscription-detail');

        /* Always show the price on the SEO page, currently the component is used in multiple places and in some
         * it should not show. No way to select only to show in some places, so show for this page.
        */
        this._element.show();

        const subscriptionId: number = this._element.data('subscription-id');

        const subscription = this._subscriptionRepo.getSubscription(subscriptionId);

        /**
         * get current offer including dependencies and mandatory services/discount
         */
        const generalOfferServiceInterface = this.getInjector().getGeneralSalesObjectInterface ();
        const salesChannel = this.getInjector().getFlowState().getSalesChannel();
        const generalOfferServiceOffer = generalOfferServiceInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, 'bnt', salesChannel);

        this._simOnlyOffer = new SimOnlyOffer(subscription, generalOfferServiceOffer);

        this._element.find('.priceVlux').html(this.getInjector().getTemplates().render('price-element', this._simOnlyOffer.monthlyDiscountPrice, 'partials'));
        if (this._simOnlyOffer.monthlyDiscountPrice !== this._simOnlyOffer.monthlyRegularPrice) {
            this._element.find('.strikePriceVlux').html(this.getInjector().getTemplates().render('price-element', this._simOnlyOffer.monthlyRegularPrice, 'partials'));
        } else {
            this._element.find('.strikePriceVlux').parents('.additional.standard-price').html('pro Monat');
        }

        if (this._simOnlyOffer.offerTariffCosts.discountPrices.length) {
            let annotate: string = '';
            const tariffCostsObject = this._simOnlyOffer.offerTariffCosts;
            if (undefined !== tariffCostsObject) {
                const shortestDiscount = tariffCostsObject.shortestDiscount;
                let undiscountedPrice: string = (Math.round(tariffCostsObject.priceArray[shortestDiscount] * 100) / 100).toFixed(2);

                undiscountedPrice = undiscountedPrice.replace('.', ',');
                annotate = 'Ab dem ' + (shortestDiscount + 1) + '. Monat ' + undiscountedPrice + ' &euro;<br>24 Monate Mindestlaufzeit';
            }
            this._element.find('.asterisk-text').html(annotate);
        }

        let URL: string = this.getInjector().getOptions().get('device_overview_url');
        URL += (0 <= URL.indexOf('?') ? '&' : '?') + 'tariffId=' + subscriptionId;

        // add subscriptionGroup as get parameter
        const subscriptionGroupId: number = subscription.subscriptionGroupId;

        if (subscriptionGroupId === Constants.RedPlus_Group_Id || subscriptionGroupId === Constants.Black_Group_Id) {
            URL += '&subscriptionGroup=red';
        }
        else if (subscriptionGroupId === Constants.Easy_Group_Id) {
            URL += '&subscriptionGroup=easy';
        }
        else if (subscriptionGroupId === Constants.Young_Group_Id) {
            URL += '&subscriptionGroup=young';
        }

        this._element.find('.btn-overview').attr('href', URL);

        this._element.animate({opacity: 1}, 300);

    }

    protected events (): void {

         this._element.find('.btBuySimonly').on('click', (evt: JQueryEventObject) => {

            evt.preventDefault();

            const basketHandler = new BasketHandler(this.getInjector());

            const generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();
            const generalBasketServiceInterface = <GeneralBasketServiceInterfaceBNT> this.getInjector().getGeneralBasketServiceInterface(Constants.BTX_BNT);

            const salesChannel = this.getInjector().getFlowState().getSalesChannel();

            generalBasketServiceInterface.executeOffer(
                this._simOnlyOffer,
                salesChannel,
                generalSalesObjectInterface).then((success) => {
                basketHandler.defaultSuccessHandling(success);
            }, (error) => {
                basketHandler.defaultErrorHandling(error);
            });

        });

    }

    public bind (): void {

        this.render();
        this.events();
        this.tracking();

    }

}

import { SalesChannelName } from 'core/ids';

import GeneralBasketServiceInterface from './general-basket-service-interface';

import * as ŃsfOffer from 'view/view/shared/offer/offer';

import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

import DeviceOffer from 'view/view/shared/offer/device-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import Customer from 'shopbackend/customer';
import { ProlongationType } from '@vfde-fe/general-sales-objects';

/**
 * @module SalesFlow/shopbackend
 */

export default class GeneralBasketServiceInterfaceVVL extends GeneralBasketServiceInterface {

    private _prolongationType: ProlongationType;
    private _cellcode: string;
    private _newSIMCardNeeded: boolean = false;

    constructor (salesChannel: SalesChannelName, forApp: boolean, forAppConnection: boolean) {

        super (salesChannel, forApp, forAppConnection);

    }

    /**
     * Main offer is the NSF View Offer Type (SimOnlyOffer, DeviceOffer, HardwareOnlyOffer) that
     * includes every information we need. Red+ Items, Accessory Items, optional services ....
     * @param mainOffer
     * @param salesChannel
     * @param generalSalesObjectInterface
     */
    public executeOffer (mainOffer: ŃsfOffer.default, salesChannel: SalesChannelName, customer: Customer, generalSalesObjectInterface: GeneralSalesObjectInterface): JQueryPromise<any> {

        this.init();

        this._salesChannel = salesChannel;
        this._generalSalesObjectInterface = generalSalesObjectInterface;

        this._prolongationType = <ProlongationType> customer.prolongationType;
        this._cellcode = customer.vvlCellcode;

        // Offer Data in NSF Style
        this._NSF_Data.mainOffer = mainOffer;

        if (( mainOffer instanceof DeviceOffer || mainOffer instanceof SimOnlyOffer ) && 0 < mainOffer.redPlusOffers.length) {
            this._NSF_Data.redPlusOffer = mainOffer.redPlusOffers;
        }

        if (mainOffer instanceof DeviceOffer && 0 < mainOffer.accessories.length) {
            this._NSF_Data.accessoryOffer = mainOffer.accessories;
        }

        // First step: empty Basket
        this.clear();

        // generates Baskert Object out of NSF Offer (Including Red+)
        this.generateMainOfferFromNSFData();

        // Add Main Offer to Basket
        this._basketFactory.putContractProlongationOfferIntoBasket(
            this._GSO_Data.mainOffer,
            this._prolongationType,
            this._GSO_Data.redPlusOffer,
            this._cellcode,
            this._newSIMCardNeeded
        );

        // Add Accessory Offer(s) to Basket
        this.generateAccessorysOfferFromNSFData();
        for (let i = 0; i < this._GSO_Data.accessoryOffer.length; i++) {
            this._basketFactory.putHardwareOnlyOfferIntoBasket(
                this._GSO_Data.accessoryOffer[i]
            );
        }

        return this.sendBasketToShopBackend();

    }

}

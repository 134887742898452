/**
 * @module SalesFlow/evolved/view
 */

import {ViewEvolvedCtasBntBase} from 'view-evolved/ctas/bnt/view-evolved--ctas--bnt-flow-ctas';
import {ViewEvolvedCtasSharedFlowCta} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-cta';
import Offer from 'view/view/shared/offer/offer';
import { TariffGroupName } from 'core/ids';

/**
 * Two buttons in pricebox and the very same in costoverview
 * Left: Link to vvl (As always in bnt)
 * Right: Link to basket
 *
 * @export
 * @class ConnectMoreBntCtas
 * @extends {FlowCtas}
 */
export class ViewEvolvedCtasBntDataGoConnectMoreCtas extends ViewEvolvedCtasBntBase {

    /**
     * Overwriting right button while different text
     */
    protected getRightButton (): ViewEvolvedCtasSharedFlowCta {

        return new ViewEvolvedCtasSharedFlowCta(
            ['nfs-pricebox-confirm'],
            this.getInjector().getStrings().get('pricebox.confirm.basket')
        );

    }

    public update (offer: Offer): void {

        if (undefined === offer) {
            return;
        }

        const tariffGroup: TariffGroupName = this.getInjector().getFlowState().getTariffGroup();

        this._right.setHref(
            '#proceedToBasket'
        );

        this._element.html(
            this.getInjector().getTemplates().render('flow-ctas', [this._right], 'partials')
        );

    }

    protected events (): void {

        super.events();

        /**
         * From connect more the next step "forward" is the basket
         */
        this._element.on('click', this._right.getSelector(), (evt: JQueryEventObject) => {

            evt.preventDefault();

            this.createBasketAndProceed();

        });
    }

}

/**
 * @module SalesFlow/view
 */

import Injector from 'core/injector';

declare var $: JQueryStatic;

import WatchOffer from '../../view/shared/offer/watch-offer';
import AtomicDevice from 'model/type/atomic-device';

export default class DeviceTileMultiSim {

    private _injector: Injector;
    private _element: JQuery;
    private _deviceOffer: WatchOffer;
    private _atomicDevices: AtomicDevice[];

    // Text of the selected bundle, not the value, because the value(AtomicId) is diffeerent in every device
    private _selectedBundleText: string;

    constructor (
        element: JQuery,
        deviceOffer: WatchOffer,
        injector: Injector
    ) {

        this._element = element;

        this._deviceOffer = deviceOffer;
        this._injector = injector;
        this._atomicDevices = this._deviceOffer.atomicDevice.device.getAtomicDevices();

        // while objects of this class are created when html ist already rendered we need just events
        this.events();
    }

    protected getInjector (): Injector {
        return this._injector;
    }

    public update (deviceOffer: WatchOffer): void {
        this._element.attr('data-atomic-device-id', deviceOffer.atomicDeviceId);

        this._element.find('img').attr('src', deviceOffer.nsfImage90_120);

        this._element.find('.subscriptionDepended').html(
            this.getInjector().getTemplates().render('device_tile_price', deviceOffer, 'partials')
        );

        this._element.find('.nsf-color-picker').html(
            this.getInjector().getTemplates().render('colors', deviceOffer.watchColors, 'partials')
        );

        this._element.find('.nsf-size-picker').html(
                this.getInjector().getTemplates().render('housing-sizes', deviceOffer.housingSizes, 'partials')
            );

        this._element.find('.deliveryText').html(deviceOffer.atomicDevice.attributes.deliveryTime);

        this._element.find('.device-short-description').html((deviceOffer.atomicDevice.attributes.bracelet || '') + ' ' + (deviceOffer.atomicDevice.attributes.braceletColor || ''));

    }

    private getAtomicDeviceIdByDeviceId (deviceId: number): number {
        const watchOffers = this.getInjector().getFlow().getWatchOffers();
        for (let i = 0; i < watchOffers.length; i++) {
            if (watchOffers[i].atomicDevice.device.id === deviceId) {
                return watchOffers[i].atomicDevice.id;
            }
        }

        return undefined;
    }

    public events (): void {

        this._element.on('click', '.picker .valueBox', (evt) => {

            /**
             * If the picker is disabled or already selected we don't need to update the deviceOffer
             */
            if (true === $(evt.currentTarget).hasClass('disabled') || true === $(evt.currentTarget).hasClass('selected')) {
                return;
            }

            let atomicDeviceId = parseInt($(evt.currentTarget).data('atomicdeviceid'), 10);

            const selectedColor = $(evt.currentTarget).data('colorname');

            // If the selected color does not have a match for the selected size
            if (!atomicDeviceId) {
                // Loop through atomic devices and find first size match
                for (const atomicDevice of this._atomicDevices) {
                    if (atomicDevice.attr.housingColor === selectedColor) {
                        atomicDeviceId = atomicDevice.id;
                        break;
                    }
                }
            }

            if (true === isNaN(atomicDeviceId)) {
                return;
            }

            /*
            const deviceTile: JQuery = $(evt.currentTarget).parents('.device-module-tile');
            if (false === deviceTile.hasClass('selected')) {
                deviceTile.find('.selectionCheck').trigger('click');
            }
            */
            const atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice.device.getAtomicDeviceById(atomicDeviceId);
            const deviceTile: JQuery = $(evt.currentTarget).parents('.device-module-tile');

            this.getInjector().getEvent().trigger('multisim-device-tile@atomicIdChanged', {
                atomicDevice: atomicDevice,
                eventType: 'filter-changed'
            });

        });

        this._element.on('click', '.selectionCheck', (evt) => {

            const deviceTile: JQuery = $(evt.currentTarget).parents('.device-module-tile');

            if (true === deviceTile.hasClass('selected')) {
                this.getInjector().getFlow().setSimcardAtomicId(undefined);

                this.getInjector().getEvent().trigger('multisim-device-tile@atomicIdChanged', {
                    atomicDevice: undefined
                });

                this.getInjector().getEvent().trigger('atomicDeviceId@changed', {
                    atomicDeviceId: undefined
                });

                // Hide device requirments warning notification if deselect
                this.getInjector().getEvent().trigger('deviceRequirements@changed', {
                    manufacturer: undefined,
                    series: undefined,
                    isDeviceDeselect: true
                });

                return true;
            }

            // const atomicDeviceId = deviceTile.data('atomic-device-id');
            const deviceId = deviceTile.data('device-id');
            let atomicDeviceId = this.getAtomicDeviceIdByDeviceId(deviceId);
            if (undefined === atomicDeviceId) {
                atomicDeviceId = deviceTile.data('atomic-device-id');
            }
            if (true === isNaN(atomicDeviceId)) {
                return;
            }

           // this.getInjector().getFlow().setSimcardAtomicId(atomicDeviceId);
           this.getInjector().getFlow().setAtomicDeviceId(atomicDeviceId);

            const atomicDevice: AtomicDevice = this._deviceOffer.atomicDevice.device.getAtomicDeviceById(atomicDeviceId);

            this.getInjector().getEvent().trigger('multisim-device-tile@atomicIdChanged', {
                atomicDevice: atomicDevice,
                eventType: 'watch-selected'
            });

            // Show device requirments warning notification for selected device
            this.getInjector().getEvent().trigger('deviceRequirements@changed', {
                manufacturer: atomicDevice.device.attr.vendor,
                series: atomicDevice.device.attr.series,
                isDeviceDeselect: false
            });

            this.getInjector().getEvent().trigger('atomicDeviceId@changed', {
                atomicDeviceId: atomicDevice.id
            });

        });

        this._element.on('click', '.device-details', (evt) => {

            this.getInjector().getEvent().trigger('device-tile@openDetailOverlay', {
                deviceOffer: this._deviceOffer
            });

        });

        this.getInjector().getEvent().listen('device-tile@offerChanged:' + this._deviceOffer.atomicDevice.device.id, (eventObject: JQueryEventObject, data: any) => {

            this._deviceOffer = data.deviceOffer;

            this.update(data.deviceOffer);

        });

        this._element.on('click', '.device-details', (evt) => {
            this.getInjector().getEvent().trigger('multisim-product-detail@openDetailOverlay', {
                deviceOffer: this._deviceOffer
            });

        });
    }

}
